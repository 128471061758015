import React from 'react';
import { motion } from 'framer-motion';
import h from '../helpers';

class Privacy extends React.Component{
    constructor(){
        super();
        this.state = {
            sizeInterval: ''
        }
    }

    render(){
        return (
            <motion.div exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }} className="container min-h-100">
                <div className="buf-4"></div>
                <h5 className="text-center display-6">Privacy Policy</h5>
                <div className="buf-4"></div>
                <p>Welcome to Nanaphone.net. This site has been created to provide digital communication tools as a service. This privacy policy is our policy in regards to personally identifiable data and other information that is collected by Nanaphone from site visitors.  </p>
                <p>We reserve the right to make changes to this privacy policy at any time and for any reason. We’ll alert you about changes by updating the “Last Updated” date at the bottom of the policy. Any changes will be effective immediately upon posting the updated policy. </p>
                <div className="buf-4"></div>
                <h5>Nanaphone does not collect and share any “personal data”</h5>
                <p>No personally identifying information that Nanaphone collects will be turned over to a third party without a court order. Providing an email address upon account creation is optional, and the email address, if provided, will only be used to send a link to reset a user’s password if requested. Email addresses provided to Nanaphone via the site’s contact form will only be used to respond to inquiries made using the contact form, and will not be shared with any third party without a court order. Nanaphone does not log any user’s IP address. Cookies are used only for authentication and to store user settings, and are not used to track behavior on Nanaphone.net or any other site. </p>
                <p>Google Analytics is currently in use for the purpose of Search Engine Optimization (SEO). The data collected here will be used by site administrators to determine which changes need to be made to Nanaphone in order for the site to rank better in search engines. The information collected by Nanaphone here will not be shared with any third party without a court order. </p>
                <div className="buf-4"></div>
                <h5>Last updated 6/24/2022</h5>
            </motion.div>
        )
    }
}

export default Privacy;