import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { purge_user, set_user, set_streamer_devices, set_login_destination } from '../redux/actions';

let bootstrap;

/**
 * The Nav bar at the top of every page
 * 
 * TODO (critical): Split this into components
 */

class NavBar extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * navToggle: Bootstrap navbar toggle object
             * userLoaded: Boolean indicating whether user data has been loaded from the server
             */
            navToggle: false,
            userLoaded: false
        }
    }

    componentDidMount(){
        /**
         * Set bootstrap (no window during ssr)
         * Set interval to check user ever 20 seconds
         * Set navbar toggle into state so it can be easily manipulated
         * Change bootstrap theme if different from user settings (should never be hit)
         * Set tooltips
         * Purge user if temporary
         */
        bootstrap = window.bootstrap;
        setInterval(this.checkSession, 20000);
        this.checkSession();
        this.setState({
            ...this.state,
            navToggle: new bootstrap.Collapse(document.querySelector('#navbarText'), {
                toggle: false
            })
        });
        if (this.props.userSettings.cssTheme !== 'default'){
            let links = document.head.getElementsByTagName('link');
            for (let i = 0; i < links.length; i++){
                if (links[i].href.split('/bootstrap/').length > 1) links[i].href = links[i].href.split('/bootstrap/')[0] + `/bootstrap/${this.props.userSettings.cssTheme}.css`;
            }
        }
        [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(e => new bootstrap.Tooltip(e));
        if (this.props.temp && this.props.location.pathname.split('/')[1] !== 'create' && this.props.location.pathname.split('/')[1] !== 'active' && this.props.location.pathname.split('/')[1] !== 'stream') this.logout(false);
    }

    componentDidUpdate(prevProps){
        /**
         * Reset tooltips if user switches
         * Replace CSS theme if user changes the theme
         * Stop all media stream tracks if the window location changes (failsafe, shouldn't be necessary)
         * Purge temp user if they leave the stream page
         */
        if (prevProps.username !== this.props.username) [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(e => new bootstrap.Tooltip(e));
        if (prevProps.userSettings.cssTheme !== this.props.userSettings.cssTheme){
            let links = document.head.getElementsByTagName('link');
            for (let i = 0; i < links.length; i++){
                if (links[i].href.split('/bootstrap/').length > 1) links[i].href = links[i].href.split('/bootstrap/')[0] + `/bootstrap/${this.props.userSettings.cssTheme}.css`;
            }
        }
        if (this.props.location.pathname.split('/')[1] !== 'create' && this.props.location.pathname.split('/')[1] !== 'active' && (this.props.streamerDevices.getVideoTracks || this.props.streamerDevices.getAudioTracks)) this.props.streamerDevices.getTracks().forEach(track => track.stop());
        if (prevProps.location !== this.props.location && (this.props.temp && this.props.location.pathname.split('/')[1] !== 'create' && this.props.location.pathname.split('/')[1] !== 'active' && this.props.location.pathname.split('/')[1] !== 'stream')) this.logout(false);
    }


    /**
     * 
     * Checks the user's session on the server
     * Applies those changes into state
     */
    checkSession = () => axios.get('/auth/check-user-db').then(res =>{
        this.props.set_user(res.data);
        if (document.getElementById('user-info-server')) document.getElementById('user-info-server').remove();
        if (!this.state.userLoaded) this.setState({
            ...this.state,
            userLoaded: true
        });
    } ).catch(err => console.log(err));

    /**
     * 
     * Basic React Router 5 function
     */
    route = route => {
        this.props.history.push(route);
    }

    /**
     * 
     * @param {Boolean} redirect Indicates whether the user logging out is a non-temporary user
     * Collapses the nav if mobile screen
     * Purges user
     * Redirects to login page if not a temporary user
     */
    logout = (redirect) => {
        if (window.innerWidth < 992){
            this.collapseNav();
        }
        axios.post('/auth/logout').then(res => {
            this.props.purge_user();
            console.log('purged')
            if (redirect) this.props.history.push('/login');
        }).catch(err => {
            console.log(err);
            this.props.purge_user();
            if (redirect) this.props.history.push('/login');
        });
    }

    /**
     * 
     * @param {String} route destination route
     * TODO: Merge with this.route
     * Hit when links shared by mobile and desktop screens are hit
     * Collapses the navbar if mobile
     * Navigates to the route
     */
    mobileNav = (route) => {
        if (route === '/create') this.props.set_login_destination(route);
        if (window.innerWidth < 992){
            this.collapseNav();
        }
        this.props.history.push(route);
    }

    collapseNav = () => {
        this.state.navToggle.hide();
    }

    render(){
        console.log(this.props.username, this.props.temp)
        return (
            <div  style={{ width: '100%'}} id="div-nav-container" className="container-fluid">
                <div style={{width: '100%'}} className="row mx-auto">
                    {this.props.location.pathname.split('/')[1] === '' && (this.props.username === '' || this.props.temp) && this.state.userLoaded ? 
                    <div id="mobile-cta" onClick={() => this.mobileNav('/create')} className="show-sm border border-primary rounded-pill border-3 p-2 o-0"><h5 className="mx-2 my-0 text-center fs-6 fw-bold">TAKE CALLS</h5></div>:
                    <></>}
                    <div className="col-6">
                        <a onClick={() => this.mobileNav('/')} id="nanaphone-logo-mobile" className="mx-auto d-block mt-2">
                            <img id="svg-logo" src="/images/n.png" alt="Nanaphone Logo"/>
                        </a>
                        <span className="show-sm mb-2"></span>
                    </div>
                    <div id="nav-column" className="col-lg-12 col-6 d-flex flex-column justify-content-center">
                        <nav id="nav-container" className="navbar bg-none navbar-expand-lg navbar-dark py-2 w-100">
                            <button id="navbar-toggler-main" className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarText">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-md-4 d-flex">
                                        <a onClick={() => this.route('/')} id="nanaphone-logo-desktop" className="me-3">
                                                <img id="svg-logo" src="/images/n.png" alt="Nanaphone Logo"/>
                                            </a>
                                            <ul className="navbar-nav mb-2 mb-lg-0, ul-main-nav">
                                                {this.props.username === '' || this.props.temp ?
                                                <>
                                                    {typeof document !== 'undefined' && document.getElementById('user-info-server') && !this.props.temp ?
                                                    <>
                                                        <span className="show-sm">
                                                            <li className="nav-item nav-items-collapse">
                                                                <p className={`nav-link nav-main-items mb-0`}>
                                                                    <img className="nav-avatar" src={`/thumbnails/${JSON.parse(document.getElementById('user-info-server').textContent).avatar}`}></img> 
                                                                    {JSON.parse(document.getElementById('user-info-server').textContent).username}
                                                                </p>
                                                            </li>
                                                        </span>
                                                        <span className="show-sm">
                                                            <li onClick={() => this.mobileNav('/dashboard')} className="nav-item nav-items-collapse">
                                                                <a id="nav-dashboard" className={`nav-link text-contrast  nav-main-items ${'dashboard' === this.props.location.pathname.split('/')[1] ? 'active' : ''}`}>Dashboard</a>
                                                            </li>
                                                        </span>
                                                        
                                                        
                                                    </> : 
                                                    <>
                                                        {typeof document !== 'undefined' ?
                                                        <span className="show-sm">
                                                            <li onClick={() => this.mobileNav('/login')} className="nav-item nav-items-collapse">
                                                                <a id="nav-login" className={`nav-link text-contrast nav-main-items ${'login' === this.props.location.pathname.split('/')[1] ? 'active' : ''}`}>Login / Sign Up</a>
                                                            </li>
                                                        </span> : <></>}
                                                    </>
                                                    
                                                    }
                                                </>
                                                
                                                :
                                                <>
                                                    <span className="show-sm">
                                                        <li className="nav-item nav-items-collapse">
                                                            <p className={`nav-link nav-main-items mb-0`}>
                                                                <img className="nav-avatar" src={`/thumbnails/${this.props.avatar}`}></img> 
                                                                {this.props.username}
                                                            </p>
                                                        </li>
                                                    </span>
                                                    <span className="show-sm">
                                                        <li onClick={() => this.mobileNav('/dashboard')} className="nav-item nav-items-collapse">
                                                            <a id="nav-dashboard" className={`nav-link text-contrast  nav-main-items ${'dashboard' === this.props.location.pathname.split('/')[1] ? 'active' : ''}`}>Dashboard</a>
                                                        </li>
                                                    </span>
                                                </>
                                                }
                                                
                                                <li onClick={() => this.mobileNav('/browse')} className="nav-item nav-items-collapse">
                                                    <a id="nav-browse" className={`nav-link text-contrast  nav-main-items ${'browse' === this.props.location.pathname.split('/')[1] ? 'active' : ''}`}>Browse</a>
                                                </li>
                                                {(this.props.username !== '' || (typeof document !== 'undefined' && document.getElementById('user-info-server'))) && !this.props.temp && this.state.userLoaded ?
                                                <li onClick={() => this.mobileNav('/create')} className="nav-item nav-items-collapse">
                                                    <a id="nav-create" className={`nav-link text-contrast  nav-main-items ${'create' === this.props.location.pathname.split('/')[1] || 'active' === this.props.location.pathname.split('/')[1] ? 'active' : ''}`}>Take Calls</a>
                                                </li> : <></>}
                                                {(this.props.username !== '' || (typeof document !== 'undefined' && document.getElementById('user-info-server'))) && !this.props.temp ?
                                                <span className="show-sm">
                                                    <li onClick={() => this.logout(true)} className="nav-item nav-items-collapse">
                                                        <a id="nav-logout" className={`nav-link nav-main-items text-danger`}>Logout</a>
                                                    </li>
                                                </span> : 
                                                <>
                                                
                                                </>}
                                            </ul>
                                        </div>
                                        <div id="desktop-cta" className="col-12 col-md-4 d-flex justify-content-center o-0">
                                            {this.props.location.pathname.split('/')[1] === '' && (this.props.username === '' || this.props.temp) && this.state.userLoaded? 
                                            <div onClick={() => this.mobileNav('/create')} id="nav-cta" className="border border-primary rounded-pill border-3 d-flex flex-column justify-content-center p-2"><h5 className="mx-2 my-0 text-center fs-6 fw-bold">TAKE CALLS</h5></div>:
                                            <></>}
                                        </div>
                                        <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                                        {this.props.username === '' || this.props.temp ?
                                            <>
                                                {typeof document !== 'undefined' && document.getElementById('user-info-server') && !this.props.temp ?
                                                <div className="nav-item dropdown float-end me-4 navbar-user-desktop">
                                                    <a className={`nav-link dropdown-toggle links-generic `} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img data-bs-toggle="tooltip" data-bs-placement="left" title={JSON.parse(document.getElementById('user-info-server').textContent).username} className="nav-avatar" src={`/thumbnails/${JSON.parse(document.getElementById('user-info-server').textContent).avatar}`}></img> 
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-start" style={{width: 'max-content', minWidth: 'max-content', maxWitdh: 'max-content'}} aria-labelledby="navbarDropdownMenuLink">
                                                        <li className="me-0" ><a onClick={() => this.route('/dashboard')} className="dropdown-item" >Dashboard</a></li>
                                                        <li className="me-0" ><a onClick={() => this.logout(true)} href="#" className="dropdown-item text-danger" >Logout</a></li>
                                                    </ul>
                                                </div> : 
                                                <>
                                                    {typeof document !== 'undefined' ? 
                                                    <a onClick={() => this.route('/login')} style={{cursor: 'pointer'}} className={`text-end links-generic h6 me-3  fw-bold navbar-user-desktop`} >Login / Sign Up</a> : <></>}
                                                </>
                                                }
                                            </>
                                            :
                                            
                                            <div className="nav-item dropdown float-end me-4 navbar-user-desktop">
                                                <a className={`nav-link dropdown-toggle links-generic `} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img data-bs-toggle="tooltip" data-bs-placement="left" title={this.props.username} className="nav-avatar" src={`/thumbnails/${this.props.avatar}`}></img> 
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-start" style={{width: 'max-content', minWidth: 'max-content', maxWitdh: 'max-content'}} aria-labelledby="navbarDropdownMenuLink">
                                                    <li className="me-0" ><a onClick={() => this.route('/dashboard')} className="dropdown-item" >Dashboard</a></li>
                                                    <li className="me-0" ><a onClick={() => this.logout(true)} href="#" className="dropdown-item text-danger" >Logout</a></li>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>                    
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, { purge_user, set_user, set_streamer_devices, set_login_destination})(NavBar);