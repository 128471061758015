import React from 'react';
import { connect } from 'react-redux';
import { set_user } from '../redux/actions';
import axios from 'axios';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class TempAccountModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether a new user has been submitted and is being processed
             * avatar: String containing path to the user's avatar
             * avatarName: Text to be shown above the user's avatar. If one is selected, it will be the filename
             * avatarFile: Javascript File Object containing a file that the user has selected from their device 
             */
            working: false,
            avatar: '/thumbnails/monkey-dark.png',
            avatarName: 'Click to Select',
            avatarFile: ''
        }
    }

    /**
     * 
     * @param {String} word A member of a schema, usually a username 
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes
     */
    checkAllowedCharacters = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890_-".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        });
        if (word.split(' ').length > 1) return false;
        return true;
    }
  
    /**
     * 
     * @param {String} text
     * @returns Boolean indicating whether the string is a valid email address
     */
    checkEmail = (text) => { 
      var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      return re.test(text);
      }
  
    /**
     * 
     * @param {String} word A display name
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes/spaces
     */
    checkAllowedCharactersDisplayName = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890_- ".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        return true;
    }
  
    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a click event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     */
    selectAvatar = () => {
      document.activeElement.blur();
      let input = document.createElement('input');
      input.type = 'file';
      input.style.visibility = "hidden";
      input.style.position = "fixed";
      input.onchange = e => {
          let file = e.target.files[0];
          if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'].indexOf(file.type) !== -1){
              if (file.size < 15000001){
                  console.log(file)
                  this.setState({
                      ...this.state,
                      avatarName: e.target.files[0].name,
                      avatarFile: e.target.files[0],
                      avatar: URL.createObjectURL(e.target.files[0])
                  });
              } else {
                  alert('Your file is too big (Max: 15MB)');
              }
          } else {
              alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
          }
          input.remove();
      }
      document.body.appendChild(input);
      input.click();
  }

  /**
   * 
   * @returns Captcha key generated from the executeRecaptcha function passed from the provider
   */
  getRecaptcha = () => new Promise((resolve, reject) => {
        this.props.googleReCaptchaProps.executeRecaptcha().then(res => resolve(res)).catch(err => {
            console.log('error', err);
            return reject();
        });
    });
  
    /**
     * Fired when the user clicks Submit
     * 
     * Set state to "working"
     * Get captcha key
     * Validate inputs
     * Submit to server
     * Set state to "not working"
     * Hide modal if successful
     */
    submit = () => {
        if (!this.state.working){
            this.setState({
                ...this.state,
                working: true
            }, async () => {
                try {
                    const captchaKey = await this.getRecaptcha();
                    let submission = {
                        displayName: document.getElementById('input-displayName-temp').value,
                        captchaKey: captchaKey,
                        streamer: this.props.streamer
                    }
                    const fd = new FormData();
                    console.log(this.state.avatarFile);
                    if (this.state.avatarFile) fd.append('avatar', this.state.avatarFile, this.state.avatarName);
                    fd.append('displayName', submission.displayName);
                    fd.append('captchaKey', submission.captchaKey);
                    fd.append('streamer', submission.streamer);
                    if (submission.displayName.length < 4) throw "Your display name is too short. (Minimum 4 characters)";
                    if (submission.displayName.length > 30) throw "Your display name is too long (Maximum 30 characters)";
                    axios.post('/auth/create-temp-user', fd).then(res => {
                        console.log(res.data);
                        if (res.data.success){
                            this.props.set_user(res.data.userInfo);
                            this.props.tempAccountModal.hide();
                            this.props.history.push('/create');
                        } else {
                            this.setState({
                                ...this.state,
                                working: false
                            }, () => alert(res.data.errorMessage));
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            ...this.state,
                            working: false
                        }, () => alert('An error occurred. Please try again later'));
                    });
                } catch(err){
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(err));
                }
            });
        }
    }
  
    pressEnter = e => {
        if (e.key === 'Enter') this.submit(); // TODO: Remove this
    }


    render(){
        return (
            <div data-bs-backdrop="static" className="modal fade" id="tempAccountModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Continue as Guest</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <div className="container mt-3">
                            <div className="mx-auto mt-2" style={{width: '400px', maxWidth: '95%'}}>
                                <label className="mt-3">Display Name</label>
                                <input onKeyPress={this.pressEnter} placeholder="The name that others will see" id="input-displayName-temp" className="form-control mt-1"></input>
                                <label className="mt-3">Avatar (Optional)</label>
                                <p className="mb-1" onClick={this.selectAvatar} style={{cursor: 'pointer'}}>{this.state.avatarName}</p>
                                <div style={{cursor: 'pointer'}} onClick={this.selectAvatar} className="border border-dark p-3 d-flex justify-content-center align-items-center">
                                    <img className="d-block" style={{maxWidth: '50%', maxHeight: '50%'}} alt="blank avatar" src={this.state.avatar}></img>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                            {this.state.working ?
                            <button className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm me-2"></span>Working</button>:
                            <button onClick={this.submit} type="button" className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Submit</button>}
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_user})(withGoogleReCaptcha(TempAccountModal));