import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import axios from 'axios';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class Contact extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether a contact form message is being sent to or processed by the server
             */
            working: false
        }
    }

    /**
     * 
     * @param {String} text
     * @returns Boolean indicating whether the string is a valid email address
     */
    checkEmail = (text) => { 
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(text);
    }

    /**
     * 
     * @returns A Google reCaptcha v3 challlenge token
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.googleReCaptchaProps && this.props.googleReCaptchaProps.executeRecaptcha) this.props.googleReCaptchaProps.executeRecaptcha().then(resolve).catch(err => {
            console.log('error', err);
            return reject();
        });
        else setTimeout(async () => {
            try {
                const captchaKey = await this.getRecaptcha();
                resolve(captchaKey);
            } catch(err){
                console.log(err);
                alert('Captcha error. Please try again later.');
                reject();
            }
        }, 1000);
    });

    /**
     * Fired when the user clicks the send button
     * 
     * Validates the inputs
     * Sends to the server
     * Redirects the user to the "Thank you" page
     */
    send = () => {
        if (!this.state.working){
            try {
                let name = document.getElementById('name').value;
                let subject = document.getElementById('subject').value;
                let email = document.getElementById('email').value;
                let message = document.getElementById('message').value;
                if (!name) throw "Please enter a name";
                if (!subject) throw "Please enter a subject";
                if (!email) throw "Please enter an email";
                if (!message) throw "Please enter a message";
                if (!this.checkEmail(email)) throw "Please enter a valid email address";
                this.setState({
                    ...this.state,
                    working: true
                }, async () => {
                    const captchaKey = await this.getRecaptcha();
                    let data = {
                        name: name,
                        subject: subject,
                        email: email,
                        message: message,
                        captchaKey: captchaKey
                    }
                    axios.post('/contact', data).then(res => {
                        this.setState({
                            ...this.state,
                            working: false
                        }, () => {
                            if (res.data.success){
                                document.getElementById('name').value = '';
                                document.getElementById('subject').value = '';
                                document.getElementById('email').value = '';
                                document.getElementById('message').value = '';
                                alert('Thank you for your message. We appreciate your feedback and will try to get back as soon as possible.')
                            } else alert(res.data.error);
                        });
                    }).catch(err => {
                        this.setState({
                            ...this.state,
                            working: false
                        }, () => {
                            console.log(err);
                            alert('An error occurred. Please try again later.');
                        });
                    });
                });
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => alert(err));
            }
        }
    }

    render(){
        return (
            <motion.div exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }} className="container min-h-100">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center mt-3 display-6">Contact Us</h1>
                        <hr></hr>
                    </div>
                    <div className="col-12 mt-2 col-md-4">
                        <label>Name</label>
                        <input id="name" type="text" className="form-control mt-1"></input>
                    </div>
                    <div className="col-12 mt-2 col-md-4">
                        <label>Subject</label>
                        <input id="subject" type="text" className="form-control mt-1"></input>
                    </div>
                    <div className="col-12 mt-2 col-md-4">
                        <label>Email</label>
                        <input id="email" type="text" className="form-control mt-1"></input>
                    </div>
                    <div className="col-12 mt-2 col-md-8">
                        <label>Message</label>
                        <textarea id="message" type="text" className="form-control mt-1"></textarea>
                    </div>
                </div>
                {this.state.working ?
                <button disabled className="mt-2 btn btn-primary"><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                <button onClick={this.send} className="mt-2 btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Send</button>}
            </motion.div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {})(withGoogleReCaptcha(Contact));