import React from 'react';
import { motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class Cancel extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * cancelled: Boolean indicating whether password reset request has been successfully cancelled
             */
            cancelled: false
        }
    }

    /**
     * Make a request to the server to cancel the password reset request
     */
    componentDidMount(){
        axios.post('/auth/cancel', {
            uuid: this.props.match.params.id
        }).then(() => this.setState({
            ...this.state,
            cancelled: true
        })).catch(err => alert('An error occurred. Please try again later'));
    }

    render(){
        return (
            <motion.div className="container min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="buf-4"></div>
                {this.state.cancelled ?
                <h1 className="display-6 text-center">Password reset request has been canceled.</h1>:
                <>
                    <h1 className="text-center display-6">Cancelling</h1>
                    <div className="mt-2 d-flex justify-content-center">
                        <div className="loadingio-spinner-ellipsis-t29bpo9v9p"><div class="ldio-xtxv5ahe67">
                        <div></div><div></div><div></div><div></div><div></div>
                        </div></div>
                    </div>
                </>}
            </motion.div>
        );
    }
}

export default withRouter(Cancel);