import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { set_poll } from '../../redux/actions';

class ViewPollModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether a vote is in the process of being sent to or processed by the server
             * deleting: Boolean indicating whether the poll is in the process of being deleted
             * forfeiting: Boolean indicating whether the user is in the process of viewing the results without voting
             * votes: Array containing all of the options that the user has selected to vote for
             * closing Boolean indicating whether the poll is in the process of being closed for further voting
             */
            working: false,
            deleting: false,
            forfeiting: false,
            votes: [],
            closing: false
        }
    }

    /**
     * If the poll gets deleted, hide the modal
     */
    componentDidUpdate(){
        if (this.props.pollData && this.props.pollData.options){
            
        }
        else if (this.props.viewPollModal.hide) this.props.viewPollModal.hide();
    }

    /**
     * Fired when the user clicks Vote
     * As long as at least one vote was cast, submit the vote and update the application state with the new poll data
     */
    vote = () => {
        if ([].slice.call(document.getElementsByClassName('poll-options-check')).filter(e => e.checked).length === 0) alert('Please make at least one selection');
        else if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => axios.post(`/stream/vote/${this.props.streamer}`, {
            votes: this.state.votes.map(vote => vote.inputID.split('_')[1])
        }).then(res => {
            this.setState({
                ...this.state,
                working: false
            }, () => this.props.set_poll(res.data.pollData));
        }).catch(err => {
            console.log(err);
            this.setState({
                ...this.state,
                working: false
            }, () => alert('An error occurred. Please try again later'));
        })); 
    }

    /**
     * Fired when a user selects a vote option
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * 
     * Adds the vote to state.votes (timestamp probably not necessary), filters out otherwise
     * If there are more votes in state.votes than allowed, it will remove the oldest one
     */
    voteSelect = e => {
        console.log(e.target, e.target.id);
        if (e.target.checked){
            if (this.state.votes.length + 1 > this.props.pollData.votesAllowed){
                let sortedVotes = this.state.votes.sort((a, b) => a.timestamp - b.timestamp);
                document.getElementById(sortedVotes[0].inputID).checked = false;
                this.setState({
                    ...this.state,
                    votes: [
                        ...sortedVotes.filter((vote, i) => {
                            if (i !== 0) return vote;
                        }),
                        {
                            timestamp: new Date(),
                            inputID: e.target.id
                        }
                    ]
                });
            } else this.setState({
                ...this.state,
                votes: [
                    ...this.state.votes,
                    {
                        timestamp: new Date(),
                        inputID: e.target.id
                    }
                ]
            })
        }
        else this.setState({
            ...this.state,
            votes: this.state.votes.filter(v => v.inputID !== e.target.id)
        })
    }

    /**
     * Fired when the user clicks the Close Poll button
     * Closes the poll
     */
    closePoll = () => {
        if (!this.state.closing) this.setState({
            ...this.state,
            closing: true
        }, () => axios.get('/stream/close-poll').then(res => this.props.set_poll(res.data.pollData)).catch(err => this.setState({
            ...this.state,
            closing: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        })));
    }

    /**
     * Fired when the user clicks the View Results button
     * Retrieves the poll results from the server without voting
     */
    forfeit = () => {
        if (!this.state.forfeiting) this.setState({
            ...this.state,
            forfeiting: true
        }, () => axios.get(`/stream/forfeit/${this.props.streamer}`)).then(res => this.props.set_poll(res.data.pollData)).catch(err => this.setState({
            ...this.state,
            forfeiting: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later');
        }));
    }

    /**
     * Fired when the user clicks the Delete Poll button
     * Removes the poll from the system, then hides the modal
     */
    deletePoll = () => {
        if (!this.state.deleting) this.setState({
            ...this.state,
            deleting: true
        }, () => axios.get('/stream/delete-poll').then(res => {
            this.setState({
                ...this.state,
                deleting: false
            }, () => {
                this.props.viewPollModal.hide();
                this.props.set_poll({});
            });
        }).catch(err => alert('An error occurred. Please try again later.')));
    }

    /**
     * TODO: Make this its own component
     * 
     * @returns The poll options and submission buttons if not submitted already, the results if already submitted
     */
    renderPollOptions = () => {
        if (this.props.pollData.voters.indexOf(this.props.username) === -1 && this.props.pollData.open){
            return (
                <>
                    <p>Number of Votes Allowed: {this.props.pollData.votesAllowed}</p>
                    <div className="list-group">
                        {this.props.pollData.options.map((option, i) => (
                            <label key={option.optionID} className="list-group-item poll-options list-group-item-action">
                                <input id={`vote_${option.optionID}`} onChange={this.voteSelect} className="form-check-input me-1 poll-options-check" type="checkbox" value={option.optionID}></input>
                                {option.text}
                            </label>
                        ))}
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                        {this.state.working ?
                        <button style={{width: '40%'}} disabled className="btn btn-success col-6 mx-2" type="button"><span className="spinner-border spinner-border-sm me-2"></span>Submitting</button>:
                        <button onClick={this.vote} style={{width: '40%'}} className="btn btn-success col-6 mx-2" type="button"><i className="fas fa-check me-2"></i>Vote</button>}
                        {this.state.forfeiting ?
                        <button style={{width: '40%'}} disabled className="btn btn-secondary col-6 mx-2"><span className="spinner-border spinner-border-sm me-2"></span>Getting Results</button>:
                        <button onClick={this.forfeit} style={{width: '40%'}} className="btn btn-secondary col-6 mx-2">View Results<i className="fas fa-chevron-right ms-2"></i></button>}
                    </div>
                </>
            )
        } else {
            const totalVotes = this.props.pollData.options.reduce((prev, curr) => prev + curr.votes, 0);
            return this.props.pollData.options.map(option => {
                let progress = totalVotes ? (option.votes / totalVotes) * 100 : 0;
                return (
                    <div key={option.optionID} className="mt-2 row">
                        <div className="col-2">
                            <p className="text-center" style={{margin: 0}}>{option.text}</p>
                        </div>
                        <div className="col-10">
                            <div style={{width: '100%'}} class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={`${progress}`} aria-valuemin="0" aria-valuemax="100">{option.votes} vote{option.votes > 1 ? 's' : ''}</div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render(){
        return (
            <div className="modal fade" id="viewPollModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    
                    <div className="modal-content">
                    {this.props.pollData && this.props.pollData.options ?
                        <>
                            <div className="modal-header">
                                <h5 className="modal-title" >{this.props.pollData.title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body container-fluid">
                                {!this.props.pollData.open ? <>
                                    <h4 className="text-center">Final Results</h4>
                                    <hr></hr>
                                </> : <></>}
                                {this.renderPollOptions()}
                            </div>
                            {this.props.username === this.props.pollData.user ?
                            <div className="modal-footer justify-content-between">
                                <div>
                                {this.state.deleting ?
                                <button className="btn btn-danger" disabled><span className="spinner-border spinner-border-sm me-2"></span>Deleting</button>:
                                <button onClick={this.deletePoll} className="btn btn-danger">Delete Poll</button>}
                                {this.props.pollData.open ?
                                <>
                                {this.state.closing ?
                                <button className="btn btn-dark ms-2" disabled><span className="spinner-border spinner-border-sm me-2"></span>Closing</button>:
                                <button onClick={this.closePoll} className="btn btn-dark ms-2">Close Voting</button>}
                                </> : <></>}
                                
                                </div>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>:
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>}
                        </>
                        :
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border"></div>
                        </div>}
                    </div> 
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_poll })(ViewPollModal);