import React from 'react';
import { connect } from 'react-redux';
import { set_user } from '../../redux/actions'; 
import axios from 'axios';

class DevicesModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * savingDisplayName: Boolean indicating whether the user's display name is in the process of being changed
             * cameraEnabled: Boolean indicating whether the user has enabled use of their camera
             * cameraHeight: Number - Height in pixels of the video element that is playing back the user's camera
             * cameraWidth: Number - Width in pixels of the video element that is playing back the user's camera
             * refresh: Boolean - Does nothing
             * loaded: Boolean indicating whether the proper video dimensions have been determined for stream playback
             */
            savingDisplayName: false,
            cameraEnabled: false,
            cameraHeight: 450,
            cameraWidth: 450,
            refresh: false,
            loaded: false
        }
    }

    // This fixes a ui bug on some devices where the camera feed does not play properly
    componentDidMount(){
        document.getElementById('devicesModal').addEventListener('shown.bs.modal', () => this.setState({
            ...this.state,
            refresh: !this.state.refresh
        }));
    }

    /**
     * If there is a disparity in dimensions, fix and resize
     */
    componentDidUpdate(){
        if (document.getElementById('div-avatar-modal') && document.getElementById('div-avatar-modal').clientHeight && document.getElementById('div-avatar-modal').clientHeight !== this.state.cameraHeight) this.setState({
            ...this.state,
            cameraHeight: document.getElementById('div-avatar-modal').clientHeight,
            cameraWidth: document.getElementById('div-avatar-modal').clientWidth,
            loaded: true
        }, () => {
            this.applyVideo();
        });
    }


    /**
     * Plays the camera stream through the <video> element
     */
    applyVideo = () => {
        if (this.props.cameraEnabled){
            const webCam = document.getElementById('camera-modal');
            webCam.style.height = `${webCam.parentElement.clientHeight}px`;
            webCam.srcObject = this.props.mediaStream;
            webCam.play();
        }
    }

    /**
     * 
     * @param {String} word A display name
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes/spaces
     */
    checkAllowedCharactersDisplayName = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_- ".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        return true;
    }

    /**
     * Fired when the user clicks the Save button next to the Display Name field
     * Validates the inputs, sends the request to the server, then applies the changes to the application state
     */
    saveDisplayName = () => {
        if (!this.state.savingDisplayName) this.setState({
            ...this.state,
            savingDisplayName: true
        }, () => {
            const displayName = document.getElementById('display-name-modal').value;
            try {
                if (displayName.length < 4) throw "Your display name is too short. (Minimum 4 characters)";
                if (displayName.length > 30) throw "Your display name is too long (Maximum 30 characters)";
                if (!this.checkAllowedCharactersDisplayName(displayName)) throw "Illegal characters in display name (Allowed: a-z, -, _";
                axios.post('/dashboard/display-name', {
                    displayName: displayName
                }).then(res => {
                    this.setState({
                        ...this.state,
                        savingDisplayName: false
                    }, () => {
                        this.props.set_user(res.data);
                    });
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        savingDisplayName: false
                    }, () => alert('An error occurred. Please try again later.'));
                });
            } catch(err){
                this.setState({
                    ...this.state,
                    savingDisplayName: false
                }, () => alert(err));
            }
        });
    }

    render(){
        return (
            <div class="modal fade" id="devicesModal" tabindex="-1" aria-labelledby="devicesModal" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div style={{height: '75vh'}} class="modal-content d-flex flex-column">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Devices and Appearance</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body fg-1 d-flex flex-column">
                            <div className="container-fluid fg-1 d-flex flex-column">
                                <div className="row">
                                    <div className="col-12 col-md-5">
                                        <label>Display Name</label>
                                        <div className="d-flex">
                                            <input defaultValue={this.props.displayName} placeholder="Enter display name" type="text" className="form-control" id="display-name-modal"></input>
                                            {this.state.savingDisplayName ?
                                            <button style={{minWidth: 'max-content'}} disabled className="btn btn-success ms-2"><span className="spinner-border spinner-border-sm me-2"></span>Saving</button> :
                                            <button onClick={this.saveDisplayName} className="btn btn-success ms-2">Save</button>}
                                        </div>
                                        <hr></hr>
                                        <div className="d-flex">
                                            {this.props.microphoneEnabled ? <i style={{cursor: 'pointer'}} onClick={this.props.toggleMicrophone} className="bi bi-mic-fill d-block fs-2"></i> : <i onClick={this.props.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-2"></i>}
                                            {this.props.cameraEnabled ? <i style={{cursor: 'pointer'}} onClick={this.props.toggleCamera} className="bi bi-camera-video-fill d-block fs-2 mx-2"></i> : <i onClick={this.props.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-2 ms-2"></i>}
                                            {this.props.videoDevices.length > 1 && this.props.cameraEnabled ?
                                            <div onClick={this.props.rotateCamera} id="camera-rotate"></div> : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-column fg-1">
                                    <div className="col-12 fg-1 d-flex flex-column">
                                        {this.props.cameraEnabled && this.state.loaded ?
                                        <div className="d-flex flex-column fg-1" dangerouslySetInnerHTML={{
                                            __html: `
                                                <video id="camera-modal" class="cameras d-block mx-auto" style="margin: 0; padding: 0; max-height:100%; max-width:100%;" autoplay muted playsinline></video>
                                            `
                                        }}></div>
                                        :
                                        <div id="div-avatar-modal" className="h-100 div-avatar" style={{margin: 0, padding: 0, background: `url('${`/avatars/${this.props.avatar}`}')`}}></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_user})(DevicesModal);