import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, useLocation, Switch, useHistory, Redirect } from 'react-router-dom';
import Browse from './pages/Browse';
import PreGame from './pages/createFiles/PreGame';
import StreamView from './pages/createFiles/StreamView';
import Home from './pages/Home'
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import Dashboard from './pages/Dashboard';
import Stream from './pages/Stream';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import CheckEmail from './pages/CheckEmail';
import PasswordReset from './pages/PasswordReset';
import Cancel from './pages/Cancel';
import Resets from './pages/Resets';
import Success from './pages/Success';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


function App(){
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        // For PWA
        if ("serviceWorker" in navigator){
            navigator.serviceWorker.register("serviceWorker.js").then(registration => {
              console.log("Service worker register");
              console.log(registration);
            }).catch(err => {
              console.log(err);
              console.log("Service worker registration failed");
            });
          } 

    }, []);

    return (
        <>
            <NavBar history={history} location={location}/>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/">
                        <div className="fg-1">
                            <Home history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/browse">
                        <div className="fg-1">
                            <Browse history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/create">
                        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                            <PreGame history={history} location={location}/>
                        </GoogleReCaptchaProvider>
                    </Route>
                    <Route exact path="/active">
                        <StreamView history={history} location={location}/>
                    </Route>
                    <Route exact path="/login">
                        <div className="fg-1">
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                                <Login history={history} location={location}/>
                            </GoogleReCaptchaProvider>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/dashboard">
                        <div className="fg-1">
                            <Dashboard history={history} location={location}/>
                        </div>
                    </Route>
                    <Route exact path="/create-account">
                        <div className="fg-1">
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                                <CreateAccount history={history} location={location}/>
                            </GoogleReCaptchaProvider>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/stream/:user">
                        <Stream history={history} location={location}/>
                    </Route>
                    <Route exact path="/about">
                        <div className="fg-1">
                            <About history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/contact">
                        <div className="fg-1">
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                                <Contact history={history} location={location}/>
                            </GoogleReCaptchaProvider>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/privacy">
                        <div className="fg-1">
                            <Privacy history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/reset-password">
                        <div className="fg-1">
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                                <PasswordReset history={history} location={location}/>
                            </GoogleReCaptchaProvider>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/resets/:id">
                        <div className="fg-1">
                            <Resets history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/cancel/:id">
                        <div className="fg-1">
                            <Cancel history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/check-email">
                        <div className="fg-1">
                            <CheckEmail history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route exact path="/success">
                        <div className="fg-1">
                            <Success history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route path="/404">
                        <div className="fg-1">
                            <NotFound history={history} location={location}/>
                        </div>
                        <Footer />
                    </Route>
                    <Route path="/:user">
                        <Stream history={history} location={location} />
                    </Route>
                    <Route path="*">
                        <Redirect to="/404"/>
                    </Route>
                </Switch>
            </AnimatePresence> 
        </>
    )
}

export default App;