import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { set_poll } from '../../redux/actions';
import h from '../../helpers';
import { v4 as uuid } from 'uuid';

class StartPollModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the poll is in the process of being submitted to and processed on the server
             * options: Array containing list of all of the poll options
             */
            working: false,
            options: []
        }
    }

    /**
     * TODO (maybe): Make this into its own component
     * @returns A list of poll options
     * 
     */
    renderVoteOptions = () => this.state.options.map((option, i) => {
        return (
            <div key={String(option.optionID)} className="row mt-2">
                <div className="col-10 col-md-11">
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon2">Option {i + 3 }</span>
                        <input defaultValue={option.text} onChange={this.optionChange} name={option.optionID} type="text" className="form-control" placeholder="Enter Vote Option"></input>
                    </div>
                </div>
                <div className="col-2 col-md-1 d-flex justify-content-center">
                    <button onClick={() => this.deleteOption(option.optionID)} className="btn btn-danger"><i className="fas fa-trash-alt"></i></button>
                </div>
            </div>
        );
    });

    /**
     * 
     * @param {JavaScript Object} e - Standard JavaScript event object
     * 
     * Handles changes made to poll option text inputs
     * Applies those changes to state
     */
    optionChange = e => {
        this.setState({
            ...this.state,
            options: this.state.options.map(option => {
                if (option.optionID === Number(e.target.name)) return {
                    ...option,
                    text: e.target.value
                }
                else return option;
            })
        });
    }

    /**
     * Fired when the user clicks the "Add Vote Option" button
     * Adds a new poll option
     * 
     */
    addOption = () => this.setState({
        ...this.state,
        options: [
            ...this.state.options,
            {
                text: '',
                optionID: uuid()
            }
        ]
    });

    /**
     * 
     * @param {String} e - Option ID
     * 
     * Fired when the user clicks the trash icon next to one of the poll options
     * Removes that option from the list
     */
    deleteOption = e => this.setState({
        ...this.state,
        options: this.state.options.filter(o => o.optionID !== e)
    });

    /**
     * Fired when the user clicks the Submit button
     * Validates the inputs, sends to server
     * If successful, set the poll data into application state, then hide the poll modal
     */
    submit = () => {
        if (!this.state.working){
            this.setState({
                ...this.state,
                working: true
            }, () => {
                try {
                    const options = [
                        {
                            text: document.getElementById('option-1').value,
                            optionID: uuid()
                        },
                        {
                            text: document.getElementById('option-2').value,
                            optionID: uuid()
                        },
                        ...this.state.options
                    ];
                    for (let i = 0; i < options.length; i++){
                        if (!options[i].text) throw 'No blank poll options allowed.';
                    }
                    const title = document.getElementById('poll-title').value;
                    const votes = document.getElementById('max-votes').value;
                    if (!h.isNumeric(String(votes))) throw 'Votes allowed must be a number';
                    if (!title) throw 'Please enter a question';
                    axios.post('/stream/poll', {
                        options: options,
                        title: title,
                        votesAllowed: votes
                    }).then(res => this.setState({
                        ...this.state,
                        working: false
                    }, () => {
                        this.props.startPollModal.hide();
                        this.props.set_poll(res.data.pollData);
                        this.props.viewPoll();
                    })).catch(err => this.setState({
                        ...this.state,
                        working: false
                    }, () => {
                        console.log(err);
                        alert('An error occurred. Please try again.');
                    }))
                } catch(error){
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(error));
                }
            });
        } 
    }


    render(){
        return (
            <div className="modal fade" id="startPollModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Create Poll</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body container">
                        <div className="row">
                            <div className="col-12 col-md-10">
                                <label>Question</label>
                                <input className="form-control mt-1" placeholder="Is Patrick S. Tomlinson Fat?" id="poll-title"></input>
                            </div>
                            <div className={`col-4 col-md-2 ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 768 ? 'mt-2' : ''}`}>
                                <label className="text-nowrap">Votes Allowed</label>
                                <input id="max-votes" defaultValue="1" className="form-control mt-1" type="number" min="1" max={this.state.options.length + 2}></input>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon2">Option 1</span>
                                    <input id="option-1" type="text" className="form-control" placeholder="Enter Vote Option"></input>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon2">Option 2</span>
                                    <input id="option-2" type="text" className="form-control" placeholder="Enter Vote Option"></input>
                                </div>
                            </div>
                        </div>
                        {this.renderVoteOptions()}
                        <div className="d-grid gap-2 mt-2">
                            <button onClick={this.addOption} className="btn btn-primary" type="button"><span className="fas fa-plus me-2"></span>Add Vote Option</button>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {this.state.working ?
                        <button type="button" className="btn btn-success"><span className="spinner-border spinner-border-sm me-2"></span>Creating</button>:
                        <button onClick={this.submit} type="button" className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Create</button>}
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_poll })(StartPollModal);