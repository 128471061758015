import React from 'react';
import { motion } from 'framer-motion';
import h from '../helpers';

class About extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <motion.div className="min-h-100 container" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <h2 className="mt-4 display-4 text-center">Anyone can do it!</h2>
                <p className="text-center mt-4 lead">Whether you're a streamer wanting to do a call-in show, a host looking to do a virtual town hall, or simply want to quickly connect with friends, family, or followers, it's easy with Nanaphone. Accept and drop calls seamlessly, talk to multiple people at once, and set up without creating an account.</p> 
                <p className="text-center mt-4 lead">Just share your link and watch the calls come in.</p> 
            </motion.div>
        );
    }
}


export default About;