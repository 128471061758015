import React from 'react';
import { motion } from 'framer-motion';
import h from '../helpers';

class Success extends React.Component{
    constructor(){
        super();
        this.state = {
            sizeInterval: ''
        }
    }


    render(){
        return (
            <motion.div className="container min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="buf-4"></div>
                <h1 className="display-6 text-center">Password successfully reset</h1>
            </motion.div>
        )
    }
}

export default Success;