import React from 'react';

class Admin extends React.Component{
    constructor(){
        super();
    }
    // WIP
    render(){
        return (
            <div className="container h-100 d-flex flex-column">
                <h1 className="text-center display-4">Admin Options</h1>
                

                
            </div>
        );
    }
}

export default Admin;