import React from 'react';
import { connect } from 'react-redux';
import { toggle_video_preview, set_emoji_picker } from '../../redux/actions';
import VideoPreview from './VideoPreview';

let bootstrap;
let $;
let twemoji;

class CallerChat_Extra extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * charCount: Number - Characters entered into the text chat
             * loaded: Boolean indicating whether the component is loaded (just need to set various variables that break on ssr)
             * videoPreviewModal: false | Bootstrap modal object containing the caller's video feed
             * chatHeight: String - The height of the chat in pixels
             */
            charCount: 0,
            loaded: false,
            videoPreviewModal: false,
            chatHeight: '20px'
        }
    }

    /**
     * Declare window variables
     * Focus on the chat input
     * Set loaded and chat height
     * Scroll to the bottom of the chat
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        $ = window.$;
        twemoji = window.twemoji;
        document.getElementById(`textarea-caller-chat-${this.props.chatIndex}`).focus();
        this.setState({
            ...this.state,
            loaded: true,
            chatHeight: `${document.getElementById(`border-caller-chat-${this.props.chatIndex}`).clientHeight}px`
        }, () =>  {
            let chatContainer = document.getElementById(`container-caller-chat-${this.props.emojiPickerIndex}`);
            chatContainer.scrollTop = chatContainer.scrollHeight;
        });
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * 
     * If user selects the emoji icon, show the emoji picker and position it properly
     */
    componentDidUpdate(prevProps){
        if (this.props.emojiPickerShown === this.props.emojiPickerIndex && prevProps.emojiPickerShown !== this.props.emojiPickerShown && document.getElementById('emojis')){
            $('#emojis').disMojiPicker();
            twemoji.parse(document.body);
            $('#emojis').picker(emoji => this.props.pickEmoji(emoji));
            let emojiDiv = document.getElementById('emojis');
            emojiDiv.style.bottom = `${emojiDiv.parentElement.clientHeight * 1.25}px`;
        }
    }


    /**
     * This is the body of the chat with the messages
     * TODO: Make this its own component
     * 
     * @returns a list of all the chat messages
     */
    renderChatMessages = () => {
        if (this.state.loaded) return this.props.caller.messages.map((details, d) => {
            if (details.user === details.viewer){
                return (
                    <div key={'chat' + String(this.props.emojiPickerIndex + d)} className="border-top border-bottom border-light w-100">
                        <div className="d-flex align-items-start my-1 w-100">
                            <img className="chat-avatars mx-2" src={`/thumbnails/${details.avatar}`} alt={`${details.displayName} avatar`}></img>
                            <p style={{margin: 0}} className="text-break emoji-chats">{details.message}</p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div key={'chat' + String(this.props.emojiPickerIndex + d)} className="border-top border-bottom border-light w-100">
                        <div className="d-flex align-items-start my-1 w-100 justify-content-end">
                            <p style={{margin: 0}} className="text-break emoji-chats">{details.message}</p>
                            <img className="chat-avatars mx-2" src={`/thumbnails/${details.avatar}`} alt={`${details.displayName} avatar`}></img>
                        </div>
                    </div>
                );
            }
        });
        else return <></>
    }

    /**
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * 
     * Hit when the user selects the emoji icon
     * Sets the selected icon into the application state (so that other emoji pickers know to close)
     * If already open, set index to -1 (all emoji pickers closed)
     */
    openEmojiPicker = e => {
        e.stopPropagation();
        if (this.props.emojiPickerIndex !== this.props.emojiPickerShown) this.props.set_emoji_picker(this.props.emojiPickerIndex);
        else this.props.set_emoji_picker(-1);
    }

    /**
     * 
     * @param {Event Object} e  - Standard JavaScript event object
     * 
     * This runs concurrently with the emoji picker function, and stops other default events from triggering
     */
    clickEmojiContainer = e => {
        e.stopPropagation();
    }

    /**
     * Fired when the user taps the "View Camera" button to view the camera of the chatter
     * Sets the video preview Bootstrap modal into state, then shows it
     */
    videoPreview = () => {
        this.props.toggle_video_preview();
        this.setState({
            ...this.state,
            videoPreviewModal: new bootstrap.Modal(document.getElementById(`videoPreviewModal-${this.props.emojiPickerIndex}`))
        }, () => this.state.videoPreviewModal.show());
    } 

    render(){
        return (
            <div className={`h-50 ${this.props.padding}`}>
                <VideoPreview index={this.props.emojiPickerIndex} callerSelected={this.props.caller} videoPreviewModal={this.state.videoPreviewModal} />
                <div className="card d-flex flex-column h-100">
                    <div className="card-header d-flex justify-content-between align-items-center py-1 px-4">
                        {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 1440 ?
                        <h5 style={{margin: 0}}>{this.props.caller.name}{this.props.caller.mediaStream.getVideoTracks && this.props.caller.mediaStream.getVideoTracks().length ? <i className="fas fa-camera mx-2 text-success"></i> : <></>}{this.props.caller.mediaStream.getAudioTracks && this.props.caller.mediaStream.getAudioTracks().length ? <i className="fas fa-microphone ms-2 text-success"></i> : <></>}</h5> :
                        <h6 style={{margin: 0}}>{this.props.caller.name}{this.props.caller.mediaStream.getVideoTracks && this.props.caller.mediaStream.getVideoTracks().length ? <i className="fas fa-camera mx-1 text-success"></i> : <></>}{this.props.caller.mediaStream.getAudioTracks && this.props.caller.mediaStream.getAudioTracks().length ? <i className="fas fa-microphone ms-1 text-success"></i> : <></>}</h6>}
                        <div className="d-flex">
                            
                            {this.props.caller.callAccepted ?
                            <button onClick={() => this.props.dropCall(this.props.caller)} className={`btn btn-danger ms-2 ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}><i className="fas fa-phone-slash me-2"></i>Drop Call</button>:
                            <>
                                {this.props.caller.mediaStream.getVideoTracks && this.props.caller.mediaStream.getVideoTracks().length ?
                                <button onClick={this.videoPreview} className={`btn btn-warning ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}>{`${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? '' : 'View '}`}Camera</button> : <></>}
                                {(this.props.caller.mediaStream.getVideoTracks && this.props.caller.mediaStream.getVideoTracks().length) || (this.props.caller.mediaStream.getAudioTracks && this.props.caller.mediaStream.getAudioTracks().length) ? <button onClick={() => this.props.acceptCall(this.props.caller)} className={`btn btn-primary ms-2 ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}>Accept{`${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? '' : ' Call'}`}</button> : 
                                <div className="loadingio-spinner-ellipsis-t29bpo9v9p"><div class="ldio-xtxv5ahe67">
                                <div></div><div></div><div></div><div></div><div></div>
                                </div></div>}
                            </>
                            }
                        </div>
                    </div>
                    <div style={{padding: '0.5rem !important'}} className="card-body p-1 d-flex flex-column fg-1 h-100">
                        <div id={`border-caller-chat-${this.props.chatIndex}`} className="mb-1 border border-light px-2 fg-1">
                            <div onTouchMove={this.props.scrollHandler} onWheel={this.props.scrollHandler} className="chat-containers chat-containers-extra" style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id={`container-caller-chat-${this.props.emojiPickerIndex}`}>
                                {this.renderChatMessages()}
                            </div>
                        </div>
                        <div className="container-fluid mb-1" style={{padding: 0}}>
                            <div className="row mx-0 flex-nowrap">
                                <div className={`col-1 ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1440 ? 'p-0' : ''}`}>
                                    <i onClick={this.openEmojiPicker} style={{cursor: 'pointer'}} className="mt-1 fas fa-smile fa-2x"></i>
                                    {this.props.emojiPickerShown === this.props.emojiPickerIndex ?
                                    <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                </div>
                                <div className="col-8">
                                    {typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1440 ?
                                    <input name={`private_${this.props.chatIndex}`} onKeyPress={this.props.keyPress} onChange={this.props.chatChangeHandler} id={`textarea-caller-chat-${this.props.chatIndex}`} placeholder="Message Body" className="form-control"></input> :
                                    <textarea name={`private_${this.props.chatIndex}`} onKeyPress={this.props.keyPress} onChange={this.props.chatChangeHandler} id={`textarea-caller-chat-${this.props.chatIndex}`} placeholder="Message Body" className="form-control"></textarea>}
                                </div>
                                <div style={{padding: 0}} className="col-3">
                                    {this.state.chatTimeout ? 
                                        <button disabled className={`btn btn-success ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}>Wait ({this.state.chatTimeout}s)</button>:
                                        <>
                                            {this.props.charCounts[`private_${this.props.chatIndex}`] === 0 || this.props.charCounts[`private_${this.props.chatIndex}`] > 600 ?
                                            <button disabled className={`btn btn-success d-flex flex-nowrap ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}><i className={`fas fa-paper-plane ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'me-1 mt-1 fa-xs' : 'me-2'} d-flex flex-nowrap`}></i>{typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? `${this.props.charCounts[`private_${this.props.chatIndex}`]}/600` : 'Send'}</button>:                                                            
                                            <button onClick={() => this.props.sendPrivateChat(`private_${this.props.chatIndex}`)} className={`btn btn-success d-flex flex-nowrap ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'btn-sm' : ''}`}><i className={`fas fa-paper-plane ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? 'me-1 mt-1 fa-xs' : 'me-2'}`}></i>{typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1400 ? `${this.props.charCounts[`private_${this.props.chatIndex}`]}/600` : 'Send'}</button>}
                                        
                                        </>
                                    }
                                    {typeof window !== 'undefined' && window.screen && window.screen.availWidth >= 1400 ?
                                    <p className={`mt-1 mb-0 ${this.props.charCounts[`private_${this.props.chatIndex}`] > 600 ? 'text-danger' : ''}`}>{this.props.charCounts[`private_${this.props.chatIndex}`]}/600</p> :
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {toggle_video_preview, set_emoji_picker})(CallerChat_Extra);