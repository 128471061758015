import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Footer at the bottom of every page
 */

class Footer extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <div id="div-footer" className="d-flex justify-content-center">
                <Link style={{textDecoration: 'none'}} to="/about">About</Link>
                <p className="mx-2">|</p>
                <Link style={{textDecoration: 'none'}} to="/contact">Contact</Link>
                <p className="mx-2">|</p>
                <Link style={{textDecoration: 'none'}} to="/privacy">Privacy</Link>
            </div>
        );
    }
}


export default Footer;