import React from 'react';
import { motion } from 'framer-motion';
import h from '../helpers';

class NotFound extends React.Component{
    constructor(){
        super();
        this.state = {
            sizeInterval: ''
        }
    }

    render(){
        return (
            <motion.div className="h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <img className="d-block h-100 mx-auto" src="/images/404.png" alt="Anthony Cumia had sex with Sue Lightning"></img>
            </motion.div>
        ); 
    }
}

export default NotFound;