import {
    SET_HISTORY,
    SET_USER,
    PURGE_USER,
    SET_STREAM,
    SET_CALLER_STREAM,
    TOGGLE_VIDEO_PREVIEW,
    SET_STREAMER_DEVICES,
    SET_POLL,
    SET_EMOJI_PICKER,
    ROTATE_CAMERA,
    TOGGLE_CALLER_TONE,
    SET_LOGIN_DESTINATION
} from './actions';

const initialState = {
    /**
     * username: String - The username of the user
     * displayName: String - The display name of the user
     * userSettings: Object - The user's settings (currently only css theme)
     * role: String: "admin" | "user" - The user's role
     * avatar: String: Path to the user's avatar picture
     * activeStream: Object containing details about the user's stream
     * callRequests: Array containing list of people who have requested to call
     * streamSelected: String - Username of streamer that the user has selected to join
     * callerStream: false | MediaStream Object - Stream of caller who has requested to join the call but is being screened
     * videoPreviewShown: Boolean indicating whether the user is viewing a caller's video camera
     * streamerDevices: Array containing a list of all of the user's media devices
     * pollData: Object containing poll data that the user has created, if applicable
     * emojiPickerShown: Number - Index of the emoji picker that is selected. -1 if none selected
     * resetDevices: Boolean indicating that devices have been changed and streams/components need to be reset
     * cameraIndex: Number - Index of the camera that the user has selected
     * videoDevices: Array - All of the video devices (cameras) available to the streamer
     * newCallerTone: Boolean indicating whether the user has opted to play a tone when a new caller has requested to call
     * temp: Boolean indicating whether the user is logged in as a temporary user
     * email: String, the user's email address
     * loginDestination: String, the location that the user will be redirected to after loggin in
     */
    username: '',
    displayName: '',
    userSettings: {
        cssTheme: 'default'
    },
    role: 'user',
    avatar: 'monkey-dark.png',
    activeStream: {
        timestamp: new Date(),
        title: '',
        url: '',
        private: false,
        thumbnail: 'monkey-dark.png'
    },
    callRequests: [],
    streamSelected: '',
    callerStream: '',
    videoPreviewShown: false,
    streamerDevices: [],
    pollData: {},
    emojiPickerShown: -1,
    resetDevices: false,
    cameraIndex: 0,
    videoDevices: [],
    newCallerTone: true,
    temp: false,
    email: '',
    loginDestination: '/'
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_LOGIN_DESTINATION:
            return {
                ...state,
                loginDestination: action.data
            }
        case TOGGLE_CALLER_TONE:
            return {
                ...state,
                newCallerTone: action.data
            }
        case SET_EMOJI_PICKER:
            return {
                ...state,
                emojiPickerShown: action.data
            }
        case ROTATE_CAMERA:
            return {
                ...state,
                cameraIndex: state.cameraIndex + 1 < state.videoDevices.length ? state.cameraIndex + 1 : 0
            }
        case SET_STREAMER_DEVICES:
            return {
                ...state,
                streamerDevices: action.data.stream,
                videoDevices: action.data.devices,
                resetDevices: !state.resetDevices
            }
        case TOGGLE_VIDEO_PREVIEW:
            return {
                ...state,
                videoPreviewShown: !state.videoPreviewShown
            }
        case SET_CALLER_STREAM:
            return {
                ...state,
                callerStream: action.data
            }
        case SET_HISTORY:
            return {
                ...state,
                history: action.data
            }
        case SET_STREAM:
            return {
                ...state,
                streamSelected: action.data
            }
        case SET_USER: 
            let ctaMobile = document.getElementById('mobile-cta');
            let ctaDesktop = document.getElementById('desktop-cta');
            if (action.data.username === ''){
                if (ctaMobile) ctaMobile.classList.remove('o-0');
                if (ctaDesktop) ctaDesktop.classList.remove('o-0');
            }
            return {
                ...state,
                username: action.data.username,
                displayName: action.data.displayName,
                userSettings: action.data.userSettings,
                role: action.data.role,
                avatar: action.data.avatar,
                activeStream: action.data.activeStream,
                temp: action.data.temp,
                email: action.data.email
            }
        case SET_POLL:
            return {
                ...state,
                pollData: action.data
            }
        case PURGE_USER:
            let ctaMobile_purge = document.getElementById('mobile-cta');
            let ctaDesktop_purge = document.getElementById('desktop-cta');
            if (ctaMobile_purge) ctaMobile_purge.classList.remove('o-0');
            if (ctaDesktop_purge) ctaDesktop_purge.classList.remove('o-0');
            return {
                ...state,
                username: '',
                displayName: '',
                userSettings: {
                    cssTheme: 'default'
                },
                temp: false,
                email: ''
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;