import React from 'react';
import axios from 'axios';
import h from '../../helpers';

class Messages extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * loaded: Boolean indicating whether the contact form messages have been loaded from the server
             * messages: Array containing all of the contact form messages
             * messageSelected: Object containing the currently selected message, if any
             * working: Boolean indicating whether a reply to a contact form message is in the process of being sent to or processed by the server
             */
            loaded: false,
            messages: [],
            messageSelected: {},
            working: false
        }   
    }

    /**
     * Fetch the contact form messages, set into state
     */
    componentDidMount(){
        axios.get('/contact/messages').then(res => this.setState({
            ...this.state,
            loaded: true,
            messages: res.data.messages
        })).catch(err => {
            console.log('Fetch messages errorr', err);
            this.setState({
                ...this.state,
                loaded: true
            }, () => alert('An error occurred while fetching the site messages'));
        });
    }

    /**
     * 
     * @param {Object} message - Contact form message
     * Sets selected message into state
     */
    selectMessage = message => this.setState({
        ...this.state,
        messageSelected: message
    });

    /**
     * 
     * @param {String} string - Any string
     * @returns The first 100 characters of that string
     */
    getShortString = string => {
        if (String(string).length > 100) return string.substring(0, 100) + '...';
        else return string;
    }

    /**
     * 
     * @returns the list of contact form messages
     */
    renderMessages = () => this.state.messages.map(message => (
        <li key={message._id} data-bs-dismiss="offcanvas" onClick={() => this.selectMessage(message)} style={{cursor: 'pointer'}} className="list-group-item list-group-item-action">
            <div className="d-flex justify-content-between">
                <h6>{message.subject}</h6>
                <p className="mb-0 text-end">{h.makeDateHR(new Date(message.timestamp))}</p>
            </div>
            <p className="mb-0">{this.getShortString(message.message)}</p>
        </li>
    ));

    /**
     * Fired when the user clicks the Send button
     * Validates the inputs, then sends the message to the server, where it will be emailed
     */
    reply = () => {
        try {
            let response = document.getElementById('response').value;
            if (!response) throw 'Please enter a message';
            this.setState({
                ...this.state,
                working: true
            }, () => axios.post('/contact/response', {
                response: response,
                email: this.state.messageSelected.email,
                subject: this.state.messageSelected.subject,
                message: this.state.messageSelected.message,
                _id: this.state.messageSelected._id
            }).then(res => {
                this.setState({
                    ...this.state,
                    messageSelected: {},
                    working: false
                }, () => alert('Message sent'));
            }));
        } catch(err){
            this.setState({
                ...this.state,
                working: false
            }, () => alert(err));
        }
    }

    render(){
        return (
            <div className="container-fluid h-100">
                {this.state.loaded ?
                <div className="row h-100">
                    <span className="show-sm">
                        <button class="btn btn-primary mb-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">Select Message</button>
                        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Messages</h5>
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <div className="card h-100">
                                    <ul style={{overflowY: 'auto'}} className="list-group h-100">
                                        {this.renderMessages()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </span>
                    {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ?
                        <div className="col-4 h-100">
                            <div className="card h-100">
                                <ul style={{overflowY: 'auto'}} className="list-group h-100">
                                    {this.renderMessages()}
                                </ul>
                            </div>
                        </div> : <></>}
                    
                    {this.state.messageSelected._id ?
                    <div className="col-12 col-md-8 h-100">
                        <div className="card h-100">
                            <div className="card-body h-100">
                                <div className="h-50">
                                    <h5>{h.makeDateHR(new Date(this.state.messageSelected.timestamp))}</h5>
                                    <h5>{this.state.messageSelected.username === '' ? 'Site Guest' : this.state.messageSelected.username}</h5>
                                    <h5>{this.state.messageSelected.email}</h5>
                                    <hr></hr>
                                    <h5>Subject: {this.state.messageSelected.subject}</h5>
                                    <h5>Message:</h5>
                                    <p>{this.state.messageSelected.message}</p>
                                </div>
                                <div className="h-50">
                                    <hr></hr>
                                    <h5>Type Your Response:</h5>
                                    <textarea id="response" className="form-control"></textarea>
                                    {this.state.working ?
                                    <button disabled className="btn btn-primary mt-2"><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                    <button onClick={this.reply} className="btn btn-primary mt-2"><i className="fas fa-paper-plane me-2"></i>Send</button>}
                                </div>
                            </div>
                        </div>
                    </div> : <></>}
                </div> : 
                <div className="mt-5 d-flex justify-content-between">
                    <div className="spinner-border"></div>
                </div>}
            </div>
        )
    }
}

export default Messages;