let h = {};


h.isNumeric = (str) => {
    /**
     * Accepts a string and returns true if that string is a number; false otherwise
     */
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str)) 
  }

  h.makeDateHR = (date) => {
    /**
     * Accepts a Javascript date and returns a human readable date in the format "MM/DD/YYYY"
     */
    let months = date.getMonth() + 1;
    let days = date.getDate();
    let years = date.getFullYear();
    return months + '/' + days + '/' + years
}

h.sizeFlexContainers = () => {
  /**
   * Hacky way to fix old UI bug. Probably not used, leaving in for now just in case
   */
  [].slice.call(document.getElementsByClassName('fg-1')).forEach(e => {
    let height = 0;
    e.childNodes.forEach(c => {
      let style = getComputedStyle(c);
      height += c.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
    });
    if (e.clientHeight < height) e.classList.remove('fg-1');
  });
}

export default h;