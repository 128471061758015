import React from 'react';
import { connect } from 'react-redux';
import VideoPreview from './VideoPreview';
import { toggle_video_preview, set_emoji_picker } from '../../redux/actions';

let bootstrap;
let $;
let twemoji;

class CallerChat_mobile extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * charCount: Number - Characters entered into the text chat
             * loaded: Boolean indicating whether the component is loaded (just need to set various variables that break on ssr)
             * videoPreviewModal: false | Bootstrap modal object containing the caller's video feed
             * chatHeight: String - The height of the chat in pixels
             */
            charCount: 0,
            loaded: false,
            videoPreviewModal: '',
            chatHeight: '0px'
        }
    }

    /**
     * Declare window variables
     * Focus on the chat input
     * Set loaded and chat height
     * Scroll to the bottom of the chat
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        $ = window.$;
        twemoji = window.twemoji;
        document.querySelector('#textarea-caller-chat').focus();
        this.setState({
            ...this.state,
            loaded: true,
            chatHeight: `${document.querySelector('#border-caller-chat').clientHeight}px`
        }, () => {
            let chatContainer = document.getElementById(`container-caller-chat`);
            chatContainer.scrollTop = chatContainer.scrollHeight;
        });
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * 
     * If user selects the emoji icon, show the emoji picker and position it properly
     */
    componentDidUpdate(prevProps){
        if (this.props.emojiPickerShown === 2 && prevProps.emojiPickerShown !== this.props.emojiPickerShown && document.getElementById('emojis')){
            $('#emojis').disMojiPicker();
            twemoji.parse(document.body);
            $('#emojis').picker(emoji => this.props.pickEmoji(emoji));
            let emojiDiv = document.getElementById('emojis');
            emojiDiv.style.bottom = `${emojiDiv.parentElement.clientHeight * 1.25}px`;
        }
    }

    /**
     * Fired when the user taps the "View Camera" button to view the camera of the chatter
     * Sets the video preview Bootstrap modal into state, then shows it
     */
    videoPreview = () => {
        this.props.toggle_video_preview();
        this.setState({
            ...this.state,
            videoPreviewModal: new bootstrap.Modal(document.getElementById(`videoPreviewModal-${this.props.emojiPickerIndex}`))
        }, () => this.state.videoPreviewModal.show());
    } 

    /**
     * This is the body of the chat with the messages
     * TODO: Make this its own component
     * 
     * @returns a list of all the chat messages
     */
    renderChatMessages = () => {
        if (this.state.loaded) return this.props.callerSelected.messages.map(details => {
            if (details.user === details.viewer){
                return (
                    <div className="border-top border-bottom border-light w-100">
                        <div className="d-flex align-items-start my-1 w-100">
                            <img className="chat-avatars mx-2" src={`/thumbnails/${details.avatar}`} alt={`${details.displayName} avatar`}></img>
                            <p style={{margin: 0}} className="text-break emoji-chats">{details.message}</p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="border-top border-bottom border-light w-100">
                        <div className="d-flex align-items-start my-1 w-100 justify-content-end">
                            <p style={{margin: 0}} className="text-break emoji-chats">{details.message}</p>
                            <img className="chat-avatars mx-2" src={`/thumbnails/${details.avatar}`} alt={`${details.displayName} avatar`}></img>
                        </div>
                    </div>
                );
            }
        });
        else return <></>
    }

    
    /**
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * 
     * Hit when the user selects the emoji icon
     * Sets the selected icon into the application state (so that other emoji pickers know to close)
     * If already open, set index to -1 (all emoji pickers closed)
     */
    openEmojiPicker = e => {
        e.stopPropagation();
        const index = Number(e.target.id.split('-')[1]);
        if (index !== this.props.emojiPickerShown) this.props.set_emoji_picker(index);
        else this.props.set_emoji_picker(-1);
    }

    /**
     * 
     * @param {Event Object} e  - Standard JavaScript event object
     * 
     * This runs concurrently with the emoji picker function, and stops other default events from triggering
     */
    clickEmojiContainer = e => {
        e.stopPropagation();
    }

    render(){
        return (
            <>
                <VideoPreview index={this.props.emojiPickerIndex} callerSelected={this.props.callerSelected} videoPreviewModal={this.state.videoPreviewModal} />
                
                <div className="container-fluid fg-1 p-0">
                    <div className="row h-100 m-0">
                        <div className="col-3 p-0 mt-2 h-100 d-flex flex-column justify-content-between">
                            <div>
                                {/* {this.props.mobile ? <></> : 
                                <img data-bs-toggle="tooltip" data-bs-placement="top" title={`${this.props.callerSelected.name} (${this.props.callerSelected.viewer})`} className="nav-avatar d-block mx-auto mb-2" src={`/thumbnails/${this.props.callerSelected.avatar}`} alt="thumbnail avatar"></img>} */}
                                <h5 className="d-flex justify-content-evenly">{this.props.callerSelected.mediaStream.getVideoTracks && this.props.callerSelected.mediaStream.getVideoTracks().length ? <i className="fas fa-camera text-success"></i> : <></>}{this.props.callerSelected.mediaStream.getAudioTracks && this.props.callerSelected.mediaStream.getAudioTracks().length ? <i className="fas fa-microphone text-success"></i> : <></>}</h5>
                                {this.props.callerSelected.callAccepted ?
                                <button onClick={() => this.props.dropCall(this.props.callerSelected)} className="btn btn-danger mt-2 btn-sm d-block mx-auto">Drop</button>:
                                <>
                                    {(this.props.callerSelected.mediaStream.getVideoTracks && this.props.callerSelected.mediaStream.getVideoTracks().length) || (this.props.callerSelected.mediaStream.getAudioTracks && this.props.callerSelected.mediaStream.getAudioTracks().length) ? <button onClick={() => this.props.acceptCall(this.props.callerSelected)} className="btn btn-primary btn-sm d-block mx-auto mt-2">Accept</button> : 
                                    <div className="loadingio-spinner-ellipsis-t29bpo9v9p d-block mx-auto"><div className="ldio-xtxv5ahe67">
                                    <div></div><div></div><div></div><div></div><div></div>
                                    </div></div>}
                                    {this.props.callerSelected.mediaStream.getVideoTracks && this.props.callerSelected.mediaStream.getVideoTracks().length ?
                                    <button onClick={this.videoPreview} className="btn btn-warning btn-sm d-block mx-auto mt-2">Camera</button> : <></>}
                                </>
                                }
                            </div>
                            
                            {this.props.mobile ? <></>:
                            <div className="d-flex justify-content-end pb-2 pe-2">
                                <i style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} id="emoji-2" className="fas fa-smile fa-2x"></i>
                                {this.props.emojiPickerShown === 2 ?
                                <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}    
                            </div>}
                        </div>
                        <div className="col-9 p-0 h-100 d-flex flex-column">
                            <div id="border-caller-chat" className="mb-2 border border-light px-2 fg-1">
                                <div className="chat-containers" style={{overflowY: 'auto', width: '100%', height: this.state.chatHeight, scrollBehavior: 'smooth'}} onTouchMove={this.props.scrollHandler} onWheel={this.props.scrollHandler} id="container-caller-chat">
                                    {this.renderChatMessages()}
                                </div>
                            </div>
                            <div className="container-fluid align-self-end" style={{padding: 0}}>
                                <div className="row m-0">
                                    {this.props.mobile ? 
                                    <>
                                        <div className="col-2">
                                            <i style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} id="emoji-2" className="mt-1 fas fa-smile fa-2x"></i>
                                            {this.props.emojiPickerShown === 2 ?
                                            <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                        </div>
                                        <div className="col-10">
                                            <input type="text" name="private_1" onKeyPress={this.props.keyPress} onChange={this.props.chatChangeHandler} id="textarea-caller-chat" placeholder="Message Body" className="form-control"></input>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-12 p-0">
                                            <textarea type="text" name="private_1" onKeyPress={this.props.keyPress} onChange={this.props.chatChangeHandler} id="textarea-caller-chat" placeholder="Message Body" className="form-control"></textarea>
                                        </div>
                                    </>
                                     }
                                    <div style={{padding: 0}} className="col-12">
                                        <div class="d-grid gap-2 mt-2">
                                            {this.state.chatTimeout ? 
                                                <button disabled className="btn btn-success btn-sm">Wait ({this.state.chatTimeout}s)</button>:
                                                <>
                                                    {this.props.charCounts.private_1 === 0 || this.props.charCounts.private_1 > 600 ?
                                                    <button disabled className="btn btn-success btn-sm"><i className="fas fa-paper-plane me-2"></i>Send ({this.props.charCounts.private_1}/600)</button>:                                                            
                                                    <button onClick={() => this.props.sendPrivateChat('private_1')} className="btn btn-success btn-sm"><i className="fas fa-paper-plane me-2"></i>Send ({this.props.charCounts.private_1}/600)</button>}
                                                
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {toggle_video_preview, set_emoji_picker})(CallerChat_mobile);