import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';

class Home extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    componentDidMount(){
        let ctaMobile = document.getElementById('mobile-cta');
        let ctaDesktop = document.getElementById('desktop-cta');
        if (!this.props.username){
            if (ctaMobile) ctaMobile.classList.remove('o-0');
            if (ctaDesktop) ctaDesktop.classList.remove('o-0');
        }
    }

    render(){
        return (
            <motion.div className="container min-h-100 d-flex flex-column" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="buf-4"></div>
                <div className="h-25 d-flex flex-column justify-content-center">
                    <h1 className="display-3 text-center m-0 text-impact">Take Calls on Your Live Streams</h1>
                </div>
                <div className="buf-4"></div>
                <div>
                    <h5 className="text-center text-raleway m-0">Take calls for free from anywhere in the world.</h5>
                </div>
                <div style={{height: '2rem'}}></div>
                <div>
                    <h5 className="text-center text-raleway m-0">No email, phone number, or personal details required.</h5>
                </div>
                <div id="img-home" className="d-block fg-1 mx-auto"></div>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {})(Home);