import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import axios from 'axios';
import { set_user } from '../redux/actions';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class CreateAccount extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether a new account is being sent to or processed by the server
             * avatar: String - Path to the user's avatar
             * avatarName: String - Text next to the avatar. Will be the filename if a new avatar is selected
             * avatarFile: false | Javascript File object containing the avatar that the user selected
             * 
             */
            working: false,
            avatar: '/thumbnails/monkey-dark.png',
            avatarName: 'Click to Select',
            avatarFile: false
        }
    }

    /**
     * 
     * @param {String} word A member of a schema, usually a username 
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes
     */
    checkAllowedCharacters = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890_-".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        if (word.split(' ').length > 1) return false;
        return true;
    }

    /**
     * 
     * @param {String} word A display name
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes/spaces
     */
    checkAllowedCharactersDisplayName = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890_- ".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        return true;
    }

    /**
     * 
     * @param {String} text
     * @returns Boolean indicating whether the string is a valid email address
     */
    checkEmail = (text) => { 
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(text);
    }

    /**
     * 
     * @returns A Google reCaptcha v3 challlenge token
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.googleReCaptchaProps && this.props.googleReCaptchaProps.executeRecaptcha) this.props.googleReCaptchaProps.executeRecaptcha().then(resolve).catch(err => {
            console.log('error', err);
            return reject();
        });
        else setTimeout(async () => {
            try {
                const captchaKey = await this.getRecaptcha();
                resolve(captchaKey);
            } catch(err){
                console.log(err);
                alert('Captcha error. Please try again later.');
                reject();
            }
        }, 1000);
    });

    /**
     * Fired when the user clicks the Submit button
     * 
     * Validates the inputs
     * Sends the new user object to the server
     * Sets new user data into application state
     * Routes to dashboard
     */
    submit = () => { 
        if (!this.state.working){
            this.setState({
                ...this.state,
                working: true
            }, async () => {
                try {
                    const captchaKey = await this.getRecaptcha();
                    let submission = {
                        username: document.querySelector('#input-username').value,
                        displayName: document.querySelector('#input-displayName').value,
                        password1: document.querySelector('#input-password1').value,
                        password2: document.querySelector('#input-password2').value,
                        email: document.querySelector('#input-email').value,
                        captchaKey: captchaKey
                    }
                    const fd = new FormData();
                    fd.append('username', submission.username);
                    fd.append('displayName', submission.displayName);
                    fd.append('password1', submission.password1);
                    fd.append('password2', submission.password2);
                    fd.append('email', submission.email);
                    fd.append('captchaKey', submission.captchaKey);
                    if (this.state.avatarFile) fd.append('avatar', this.state.avatarFile, this.state.avatarName);
                    if (submission.username.length < 4) throw "Your username is too short. (Minimum 4 characters)";
                    if (submission.username.length > 30) throw "Your username is too long (Maximum 30 characters)";
                    if (!this.checkAllowedCharacters(submission.username)) throw "Illegal characters in username (Allowed: a-z, -, _)";
                    if (submission.displayName.length < 4) throw "Your display name is too short. (Minimum 4 characters)";
                    if (submission.displayName.length > 30) throw "Your display name is too long (Maximum 30 characters)";
                    if (!this.checkAllowedCharactersDisplayName(submission.displayName)) throw "Illegal characters in display name (Allowed: a-z, -, _)";
                    if (submission.password1 !== submission.password2) throw "Passwords do not match";
                    if (submission.password1.length < 4) throw "Your password is too short. (Minimum 4 characters)";
                    if (submission.password1.length > 256) throw "Your password is too long (Maximum 256 characters)";
                    if (!this.checkAllowedCharacters(submission.password1)) throw "Illegal characters in password (Allowed: a-z, -, _)";
                    if (submission.email && !this.checkEmail(submission.email)) throw 'Please enter a valid email or leave the field blank';
                    axios.post('/auth/create-account', fd).then(res => {
                        if (res.data.success){
                            this.props.set_user(res.data.userInfo);
                            this.props.history.push(this.props.loginDestination);
                        } else {
                            this.setState({
                                ...this.state,
                                working: false
                            }, () => alert(res.data.errorMessage));
                        }
                    }).catch(err => {
                        console.log(err)
                        this.setState({
                            ...this.state,
                            working: false
                        }, () => alert('An error occurred. Please try again later'));
                    });
                } catch(err){
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(err));
                }
            });
        }
    }

    /**
     * TODO: Replace with actual <form>
     * @param {Event Object} e - Javascript event object
     * 
     * Submits the form when the user presses enter
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     */
    selectAvatar = () => {
        document.activeElement.blur();
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        input.onchange = e => {
            let file = e.target.files[0];
            if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'].indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    this.setState({
                        ...this.state,
                        avatarName: e.target.files[0].name,
                        avatarFile: e.target.files[0],
                        avatar: URL.createObjectURL(e.target.files[0])
                    });
                } else {
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
            input.remove();
        }
        document.body.appendChild(input);
        input.click();
    }

    render(){
        return (
            <motion.div className="min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="container my-5">
                    <h1 className="text-center text-raleway">Create Account</h1>
                    <div className="mx-auto mt-2" style={{width: '400px', maxWidth: '95%'}}>
                        <label className="mt-3">Username</label>
                        <input onKeyPress={this.pressEnter} placeholder="You will use this to login" id="input-username" className="form-control mt-1"></input>
                        <label className="mt-3">Display Name</label>
                        <input onKeyPress={this.pressEnter} placeholder="The name that others will see" id="input-displayName" className="form-control mt-1"></input>
                        <label className="mt-3">Password</label>
                        <input onKeyPress={this.pressEnter} placeholder="Min: 4 chars Max: 256 chars" type="password" id="input-password1" className="form-control mt-1"></input>
                        <label className="mt-3">Re-enter Password</label>
                        <input onKeyPress={this.pressEnter} placeholder="Passwords must match" type="password" id="input-password2" className="form-control mt-1"></input>
                        <label className="mt-3">Email Address (Optional)</label>
                        <input onKeyPress={this.pressEnter} placeholder="Used to reset password only" id="input-email" className="form-control mt-1"></input>
                        <label className="mt-3">Avatar (Optional)</label>
                        <p className="mb-1" onClick={this.selectAvatar} style={{cursor: 'pointer'}}>{this.state.avatarName}</p>
                        <div style={{cursor: 'pointer'}} onClick={this.selectAvatar} className="border border-dark p-3 d-flex justify-content-center align-items-center">
                            <img className="d-block" style={{maxWidth: '50%', maxHeight: '50%'}} alt="blank avatar" src={this.state.avatar}></img>
                        </div>
                        <div className="d-grid gap-2 my-3">
                            {this.state.working ?
                            <button disabled className="btn btn-primary" type="button"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Submitting</button> :
                            <button onClick={this.submit} className="btn btn-primary" type="button"><i className="fas fa-paper-plane me-2"></i>Submit</button>}
                            <button onClick={() => this.props.history.push('/login')} className="btn btn-dark mt-2" type="button"><i className="fas fa-chevron-left me-2"></i>Back</button>
                        </div>
                    </div>
                </div>
                
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_user })(withGoogleReCaptcha(CreateAccount));