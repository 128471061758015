import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { set_user } from '../../redux/actions';

class Settings extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * savingName: Boolean indicating whether the user's Display Name is in the process of being updated
             * newAvatarSelected: Boolean indicating whether a new avatar has been selected
             * newAvatarUploading: Boolean indicating whether a newly selected avatar is being uploaded to the server
             * avatarFileSelected: File Object containing newly selected avatar file
             * avatarTempImage: ObjectURL of newly selected avatar file
             * savingEmail: Boolean indicating whether the user's email is in the process of being updated
             */
            savingName: false,
            newAvatarSelected: false,
            newAvatarUploading: false,
            avatarFileSelected: false,
            avatarTempImage: '',
            savingEmail: false
        }
    }

    /**
     * 
     * @param {String} text - Email address to be checked
     * @returns Boolean indicating whether the email address is valid
     */
    checkEmail = (text) => { 
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(text);
    }

    /**
     * 
     * @param {String} word A display name
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes/spaces
     */
    checkAllowedCharactersDisplayName = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_- ".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        return true;
    }

    /**
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * @returns 
     */
    changeTheme = e => axios.post('/dashboard/css', {
        cssTheme: e.target.value
    }).then(res => {
        this.props.set_user(res.data);
    }).catch(err => {
        console.log(err);
        alert('An error occurred while changing the css theme. Refresh the page and try again.');
    });

    /**
     * Fired when the user clicks the Save button next to the Display Name field
     * Validates the input, sends the new name to the server, then sets the new name into application state
     */
    updateDisplayName = () => {
        if (!this.state.savingName){
            this.setState({
                ...this.state,
                savingName: true
            }, () => {
                try {
                    const displayName = document.querySelector('#input-display-name').value
                    if (displayName.length < 4) throw "Your display name is too short. (Minimum 4 characters)";
                    if (displayName.length > 30) throw "Your display name is too long (Maximum 30 characters)";
                    if (!this.checkAllowedCharactersDisplayName(displayName)) throw "Illegal characters in display name (Allowed: a-z, -, _";
                    axios.post('/dashboard/display-name', {
                        displayName: document.querySelector('#input-display-name').value
                    }).then(res => {
                        this.setState({
                            ...this.state,
                            savingName: false
                        }, () => this.props.set_user(res.data));
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            ...this.state,
                            savingName: false
                        }, () => alert('An error occurred. Please try again later.'));
                    });
                } catch(err){
                    this.setState({
                        ...this.state,
                        savingName: false
                    }, () => alert(err));
                }
            });
        }
    }

    /**
     * Fired when the user clicks their avatar
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     */
    selectNewAvatar = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        input.onchange = e => {
            let file = e.target.files[0];
            if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'].indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    this.setState({
                        ...this.state,
                        newAvatarSelected: true,
                        avatarFileSelected: e.target.files[0],
                        avatarTempImage: URL.createObjectURL(e.target.files[0])
                    });
                } else {
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
            input.remove();
        }
        document.body.appendChild(input);
        input.click();
    }

    /**
     * Fired when the user clicks the Submit button after selecting a new avatar
     * Sends the avatar to the server, then updates the application state with the new data
     */
    submitAvatar = () => {
        if (!this.state.newAvatarUploading){
            this.setState({
                ...this.state,
                newAvatarUploading: true
            }, () => {
                const fd = new FormData();
                fd.append('image', this.state.avatarFileSelected, this.state.avatarFileSelected.name);
                axios.post('/dashboard/streamer-avatar', fd).then(res => {
                    this.setState({
                        ...this.state,
                        newAvatarSelected: false,
                        newAvatarUploading: false,
                        avatarFileSelected: '',
                        avatarTempImage: ''
                    }, () => this.props.set_user(res.data));
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        newAvatarUploading: false
                    }, () => alert('An error occurred. Please try again later.'));
                });
            });
        }
    }

    /**
     * Fired when the user clicks the Save button next to the Email field
     * Validates the input, sends the new name to the server, then sets the new email into application state
     */
    updateEmail = () => {
        if (!this.state.savingEmail){
            this.setState({
                ...this.state,
                savingEmail: true
            }, () => {
                try {
                    const email = document.querySelector('#input-email').value.trim();
                    if (email && !this.checkEmail(email)) throw "Please enter a valid email or leave the field blank";
                    axios.post('/dashboard/email', {
                        email: email
                    }).then(res => {
                        this.setState({
                            ...this.state,
                            savingEmail: false
                        }, () => this.props.set_user(res.data));
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            ...this.state,
                            savingEmail: false
                        }, () => alert('An error occurred. Please try again later.'));
                    });
                } catch(err){
                    this.setState({
                        ...this.state,
                        savingEmail: false
                    }, () => alert(err));
                }
            });
        }
    }

    render(){
        return (
            <div className="container h-100 d-flex flex-column overflow-auto-md ">
                <div className="fg-1 container-fluid">
                    <div className="row mt-5">
                        <div className="col-xxl-12 col-xl-4">
                            <div className="inputs-normal">
                                <h5 className="display-6">Avatar</h5>
                                <p className="mb-1" onClick={this.selectNewAvatar} style={{cursor: 'pointer'}}>Click to change</p>
                                <div style={{cursor: 'pointer'}} onClick={this.selectNewAvatar} className="border border-dark p-3 d-flex justify-content-center align-items-center">
                                    <img className="d-block" style={{maxWidth: '100%', maxHeight: '100%'}} alt="blank avatar" src={!this.state.avatarFileSelected ? `/avatars/${this.props.avatar}` : this.state.avatarTempImage}></img>
                                </div>
                                {!this.state.avatarFileSelected ? <></> : 
                                <div>
                                {this.state.newAvatarUploading ? 
                                <button className="btn btn-primary mt-2" disabled><span className="spinner-border spinner-border-sm me-2"></span>Submitting</button> :
                                <button onClick={this.submitAvatar} className="btn btn-primary mt-2"><i className="fas fa-paper-plane me-2"></i>Submit</button>} 
                                <hr></hr>
                                </div>}
                            </div>
                        </div>
                        <div className="col-xxl-12 col-xl-8 mt-4 mb-sm-2">
                            <h5 >Site Theme</h5>
                            <select id="select-css" onChange={this.changeTheme} defaultValue={this.props.userSettings.cssTheme} className="form-select inputs-normal">
                                <option value="default">Light</option>
                                <option value="dark">Dark</option>
                            </select>
                            <h5 className="mt-2">Display Name</h5>
                            <div className="d-flex">
                                <input id="input-display-name" defaultValue={this.props.displayName} className="form-control inputs-normal"></input>
                                {this.state.savingName ?
                                <button disabled className="btn btn-primary ms-2"><span className="spinner-border spinner-border-sm me-2"></span>Saving...</button> :
                                <button onClick={this.updateDisplayName} className="btn btn-primary ms-2">Save</button>}
                            </div>
                            <h5 className="mt-2">Email</h5>
                            <div className="d-flex">
                                <input id="input-email" defaultValue={this.props.email} placeholder="(Optional)" className="form-control inputs-normal"></input>
                                {this.state.savingEmail ?
                                <button disabled className="btn btn-primary ms-2"><span className="spinner-border spinner-border-sm me-2"></span>Saving...</button> :
                                <button onClick={this.updateEmail} className="btn btn-primary ms-2">Save</button>}
                            </div>
                            
                        </div>
                    </div>
                    
                    
                </div>
                

                
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_user})(Settings);