import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { purge_user } from '../redux/actions';
import axios from 'axios';
import Streams from './dashboardFiles/Streams';
import Settings from './dashboardFiles/Settings';
import Admin from './dashboardFiles/Admin';
import Messages from './dashboardFiles/Messages';

let bootstrap;

class Dashboard extends React.Component{
    constructor(props){
        super();
        // Dashboard items shown to users will depend on the user role
        let dashboardItems = [
            {
                name: 'Settings',
                id: 'settings',
                icon: <i className="fas fa-cog fa-lg me-2"></i>,
                component: <Settings/>
            },
            {
                name: 'Streaming',
                id: 'streaming',
                icon: <i className="fas fa-broadcast-tower me-2"></i>,
                component: <Streams/>
            }
        ];
        if (props.role === 'admin'){
            dashboardItems.push({
                name: 'Admin',
                id: 'admin',
                icon: <i className="fas fa-hammer me-2"></i>,
                component: <Admin/>
            });
            dashboardItems.push({
                name: 'Messages',
                id: 'messages',
                icon: <i className="fas fa-envelope me-2"></i>,
                component: <Messages/>
            });
        } 
        this.state = {
            /**
             * itemSelected: String - dashboardItem.id - The dashboard item or view selected
             * loggingOut: Boolean indicating whether the user is in the process of logging out
             * dashboardItems: Array containing all of the dashboard items available to the user
             * collapse: Bootstrap Collapse instance used to navigate on mobile screens
             * loaded: Boolean indicating whether initial dashboard data has been loaded
             * containerHeight: Calculated height of the dashboard containers, CSS
             */
            itemSelected: dashboardItems[0].id,
            loggingOut: false,
            dashboardItems: dashboardItems,
            collapse: false,
            loaded: false,
            containerHeight: '0px'
        }
    }

    /**
     * Set window variables
     * If user is not logged in or is a temp user, redirect to login page
     * Event listener to reset container height if window resizes
     * Set container height
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        if (!this.props.username || this.props.temp) this.props.history.push('/login');
        window.addEventListener('resize', this.setContainerSize);
        this.setContainerSize();
    }

    /**
     * Set Bootstrap Collapse if none exists
     */
    componentDidUpdate(){
        if (!this.state.collapse && this.props.username) this.setState({
            ...this.state,
            collapse: new bootstrap.Collapse(document.getElementById('dashCollapse'), {
                toggle: false
            })
        });
    }

    // Remove event listener
    componentWillUnmount(){
        window.removeEventListener('resize', this.setContainerSize);
    }

    /**
     * TODO: Remove, dumb, use CSS
     * Set container height equal to 98% of parent's calculated client height
     * 
     */
    setContainerSize = () => this.setState({
        ...this.state,
        loaded: false
    }, () => this.setState({
        ...this.state,
        loaded: true,
        containerHeight: (document.getElementById('dash-container').clientHeight * 0.98) + 'px'
    }, () => {
        let initialItemSelected = document.querySelector(`#pills-${this.state.itemSelected}`);
        if (initialItemSelected){
            initialItemSelected.classList.add('active');
            initialItemSelected.classList.add('show');
        }
    }));

    /**
     * Fired when the user clicks the Logout button
     * Logs the user out, purges the user, redirects to login screen
     */
    logout = () => {
        if (!this.state.loggingOut){
            this.setState({
                ...this.state,
                loggingOut: true
            }, () => {
                axios.post('/auth/logout').then(res => {
                    this.props.purge_user();
                    this.props.history.push('/login');
                }).catch(err => {
                    this.setState({
                        ...this.state,

                    })
                    console.log(err);
                    this.props.purge_user();
                    this.props.history.push('/login');
                });
            });
        }
    }

    /**
     * Fired when the user selects a dashboard item
     * @param {String} id - Dashboard item id
     * 
     * Sets the item into state
     * Hides the mobile collapse menu if applicable
     */
    selectItem = id => this.setState({
        ...this.state,
        itemSelected: id
    }, () => {
        if (this.state.collapse.hide) this.state.collapse.hide();
    });

    /**
     * 
     * @returns A list of dashboard items
     */
    renderOptions = () => {
        return this.state.dashboardItems.map(item =>(
            <li role="presentation" className="nav-item">
                <a onClick={() => this.selectItem(item.id)} href="#" aria-controls={`pills-${item.id}`} aria-selected={String(item.id === this.state.itemSelected)} role="tab" data-bs-target={`#pills-${item.id}`} data-bs-toggle="pill" id={`pills-${item.id}-tab`} className={`nav-link dash-navs link-dark ${item.id === this.state.itemSelected ? 'active' : ''}`}>
                {item.icon}
                {item.name}
                </a>
            </li>
        ));
    }

    /**
     * 
     * @returns The dashboard components
     */
    renderDashboard = () => this.state.dashboardItems.map(item => (
        <div className={`tab-pane fade h-100`} id={`pills-${item.id}`} role="tabpanel" aria-labelledby={`pills-${item.id}-tab`}>
            {item.component}
        </div>
    ));

    render(){
        return (
            <motion.div className="h-100 d-flex flex-column" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div style={{height: '1.5rem'}}></div>
                <div className="show-sm m-2">
                    <button id="dash-collapse-button" data-bs-toggle="collapse" href="#dashCollapse" role="button" aria-expanded="false" aria-controls="dashCollapse" className="btn btn-light">Dashboard<i className="fas fa-caret-down ms-2"></i></button>
                    <div className="collapse multi-collapse" id="dashCollapse">
                        <div className="card card-body">
                            <ul role="tablist" id="pills-tab" className="nav nav-pills flex-column fg-1">
                                {this.renderOptions()}
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%'}} id="dash-container" className="fg-1">
                    {this.state.loaded ?
                    <>
                        <div className="d-flex" style={{height: this.state.containerHeight, width: '100%'}}>
                            <div className="show-lg">
                                <div className="d-flex flex-column p-3 bg-light mx-3 shadow h-100" id="dashboard-sidebar">
                                    <div className="d-flex text-center align-items-center justify-content-center link-dark text-decoration-none">
                                        <span className="fs-4 ">Dashboard</span>
                                    </div>
                                    <hr></hr>
                                    <ul role="tablist" id="pills-tab" className="nav nav-pills flex-column fg-1">
                                        {this.renderOptions()}
                                    </ul>
                                    <hr></hr>
                                    {this.state.loggingOut ?
                                    <button disabled className="btn btn-danger float-end"><span className="spinner-border spinner-border-sm me-2"></span>Logging Out</button> :
                                    <button onClick={this.logout} className="btn btn-danger float-end">Log Out</button>}
                                </div>
                            </div>
                            <div className="tab-content dashboard-containers h-100" id="pills-tabContent">
                                {this.renderDashboard()}
                            </div>
                        </div>
                    </> :
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow spinner-grow-lg"></div>
                    </div>}
                    
                    
                </div>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {purge_user})(Dashboard);