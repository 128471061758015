import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { io } from 'socket.io-client';
import DisconnectViewer from '../components/DisconnectViewer';
import ViewPollModal from './createFiles/ViewPollModal';
import { set_poll, set_emoji_picker, purge_user } from '../redux/actions';
import NewAccount from './streamFiles/NewAccount';
import DevicesModal from './streamFiles/DevicesModal';
import StreamBigModal from './streamFiles/StreamBigModal';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LinearProgress from '@mui/material/LinearProgress';

let Peer;
let bootstrap;
let $;
let twemoji;

class Stream extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * StreamInfo: Object containing information about the stream
             * callRequested: Boolean indicating whether the user has requested a call with the streamer
             * sendingPublicChat: Boolean indicating whether a public chat message is in the process of being sent
             * sendingPrivateChat: Boolean indicating whether a private chat message is in the process of being sent
             * publicEmojiPickerShown: Boolean indicating whether the Emoji Picker for the public chat is displayed
             * chatSocket: false | Socket.io socket instance
             * publicChatCharCount: Number - Characters in the public chat text field
             * publicChatMessages: Array containing all of the public chat messages
             * publicChatTimeout: Number - Seconds left on cooldown for the public chat
             * publicChatWaitInterval: false | Interval that decrements state.publicChatTimeout by 1 every second
             * streamerChatMessages: Array containing private chat messages with the streamer
             * streamerChatCharCount: Number - Characters in the streamer chat text field
             * streamerChatTimeout: Number - Seconds left on cooldown for the streamer chat
             * streamerChatWaitInterval: false | Interval that decrements state.publicChatTimeout by 1 every second
             * streamerEmojiPickerShown: Boolean indicating whether the emoji picker for the streamer chat is displayed
             * viewCountInterval: false | Interval that pings the server periodically to get an updated view count
             * viewCount: Number - Viewers in the stream
             * cameraEnabled: Boolean indicating whether the user has opted to use their camera
             * microphoneEnabled: Boolean indicating whether the user has opted to use their microphone
             * callStatus: String: "chat" | "approve" | "success" - Stages of getting into a call with the streamer
             * mediaStream: false | MediaStream object containing user's devices
             * callPanelHeight: Number - height in pixels of the call panel
             * callPanelWidth: Number: width in pixels of the call panel
             * activeCalls: Array containing all of the active calls that the user is a part of (streamer + other callers)
             * disconnectAlerts: Array containing all of the alerts notifying the user that the streamer has disconnected them or self
             * toggleMute: Unused or needs to be removed 
             * viewPollKey: Key of the poll modal, needs to be removed
             * viewPollModal: false | Bootstrap Modal object containing the poll data
             * peer: PeerJS peer object used to communicate directly with streamer and other callers
             * streamerPeerID: String - Peer ID of the streamer
             * newAccountModal: false | Bootstrap Modal containing the Create Account form
             * publicChatHeight: Number indicating the height in pixels of the public chat
             * privateChatHeight: Number indicating the height in pixels of the private chat
             * publicChatShownMobile: Boolean indicating whether the public chat is displayed on mobile screens
             * unreadChatMessages: Number - Unread chat messages for mobile screens
             * videoDevices: Array containing a list of all of the user's video devices
             * cameraIndex: Number - Index of the video device that the user has currently selected
             * devicesModal: false | Bootstrap Modal containing a larger device select interface
             * resize: To be removed
             * scrollTops: Object containing the "scrollTops" of all the chatboxes, which are booleans indicating whether they should scroll to the bottom upon new chats
             * streamBigModal: false | Bootstrap Modal containing the fullscreen stream
             * copyPopover: false | Bootstrap Popover instance that contains a link to the stream
             * copyTooltip: false | Bootstrap Tooltip instance that appears when a user hovers over the "Copy" button for the stream link
             * newAccountCall: Boolean indicating whether the user is creating a new account for the purpose of calling
             * newAccountChat: Boolean indicating whether the user is creating a new account for the purpose of chatting
             * videoStream: Boolean indicating whether the streamer has included a video stream (youtube, twitch, etc)
             */
            streamInfo: {
                title: '',
                streamer: '',
                streamUrl: '',
                activity: true
            },
            callRequested: false,
            sendingPublicChat: false,
            sendingPrivateChat: false,
            publicEmojiPickerShown: false,
            chatSocket: false,
            publicChatCharCount: 0,
            publicChatMessages: [],
            publicChatTimeout: 0,
            publicChatWaitInterval: false,
            streamerChatMessages: [],
            streamerChatCharCount: 0,
            streamerChatTimeout: 0,
            streamerChatWaitInterval: '',
            streamerEmojiPickerShown: false,
            viewCountInterval: false,
            viewCount: 0,
            cameraEnabled: false,
            microphoneEnabled: true,
            callStatus: 'chat',
            mediaStream: false,
            callPanelHeight: 300,
            callPanelWidth: 300,
            activeCalls: [],
            disconnectAlerts: [],
            toggleMute: false,
            viewPollKey: false,
            viewPollModal: false,
            peer: false,
            streamerPeerID: '',
            newAccountModal: false,
            publicChatHeight: 0,
            privateChatHeight: 0,
            publicChatShownMobile: true,
            unreadChatMessages: 0,
            videoDevices: [],
            cameraIndex: 0,
            devicesModal: false,
            resize: false,
            scrollTops: {
                public: true,
                private: true,
                public_mobile: true,
                private_mobile: true
            },
            streamBigModal: false,
            copyPopover: false,
            copyTooltip: false,
            newAccountCall: false,
            newAccountChat: false,
            videoStream: true,
            peerOpened: false,
            devicesAllowed: false
        }
    }

    /**
     * Set window variables
     * Add resize event listener
     * If the user has visited their own page, redirect to the streaming pregame page
     * Fetch stream info 
     * If user is not streaming, redirect to browse page
     * If user is not found, 404
     * Load user data
     */
    componentDidMount(){
        Peer = window.Peer;
        bootstrap = window.bootstrap;
        $ = window.$;
        twemoji = window.twemoji;
        window.addEventListener('resize', this.resize);
        if (this.props.redux.username === this.props.params.user) this.props.redux.history.push('/create');
        else axios.get(`/stream/info/${this.props.params.user}`).then(res => {
            switch(res.data.query){
                case 'not-streaming':
                    this.props.redux.history.push('/browse');
                    alert('User is not streaming');
                    break;
                case 'not-found':
                    this.props.redux.history.push('/404');
                    break;
                case 'success':
                    this.load(res.data.streamInfo);
                    break;
            }
        }).catch(err => {
            console.log(err);
            alert('There was an error getting the stream info. Please refresh the page and try again', err);
        });
    }

    /**
     * 
     * @param {Object} prevProps previous this.props object
     * @param {Object} prevState previous this.state object
     * 
     */
    componentDidUpdate(prevProps, prevState){
        if (prevState.toggleMute !== this.state.toggleMute) window.muteShit(); // Needs to be removed

        // If new emoji picker has popped up, run the disMojiPicker parsing function
        if (this.props.redux.emojiPickerShown !== -1 && prevProps.redux.emojiPickerShown !== this.props.redux.emojiPickerShown && document.getElementById('emojis')){
            $('#emojis').disMojiPicker();
            twemoji.parse(document.body);
            $('#emojis').picker(emoji => this.pickEmoji(emoji));
            let emojiDiv = document.getElementById('emojis');
            emojiDiv.style.bottom = `${emojiDiv.parentElement.clientHeight * 1.25}px`;
        } else twemoji.parse(document.body);

        // If video dimensions are out of sync with state, reset state
        let videoFrame = document.getElementById('streamer-stream');
        if (videoFrame && videoFrame.clientHeight !== this.state.callPanelHeight) this.setState({
            ...this.state,
            callPanelHeight: videoFrame.clientHeight,
            callPanelWidth: videoFrame.clientWidth
        });

        // Play streams from calls through their respective <video> or <audio> srcObject
        [].slice.call(document.getElementsByClassName('active-calls')).forEach(e => {
            const owner = e.id.split('active-')[1];
            if (owner === this.props.redux.username){
                if (e.srcObject){
                } else {
                    let stream = this.state.mediaStream.clone();
                    stream.getAudioTracks().forEach(track => stream.removeTrack(track));
                    e.srcObject = stream;
                }
            } else {
                if (e.srcObject){
                    //console.log(e.srcObject.getAudioTracks());
                } else {
                    e.srcObject = this.state.activeCalls.find(caller => caller.viewer === owner).mediaStream;
                }
            }
        });

        // If streamer removes youtube/twitch stream URL, remove the stream
        if (this.state.videoStream && !this.state.streamInfo.streamUrl) this.setState({
            ...this.state,
            videoStream: false
        });
        else if (!this.state.videoStream && this.state.streamInfo.streamUrl) this.setState({
            ...this.state,
            videoStream: true
        });
    }

    /**
     * Clear intervals
     * Disconnect chat socket
     * Stop media tracks
     * If temp user, purge account
     */
    componentWillUnmount(){
        clearInterval(this.state.publicChatWaitInterval);
        clearInterval(this.state.viewCountInterval);
        clearInterval(this.state.streamerChatWaitInterval);
        if (this.state.mediaStream.getTracks){
            this.state.mediaStream.getTracks().forEach(track => track.stop());
        } 
        if (this.state.chatSocket && this.state.chatSocket.disconnect) this.state.chatSocket.disconnect(this.props.params.user);
        if (this.state.viewPollModal && this.state.viewPollModal.hide) this.state.viewPollModal.hide();
        if (this.props.redux.temp) {
            console.log('purge')
            axios.post('/auth/logout');
            this.props.redux.purge_user();
        }
    }

    // Should not have to do any height resizing here
    resize = () => {
        this.setState({
            ...this.state,
            resize: !this.state.resize
        }, () => {
            [].slice.call(document.getElementsByClassName('chat-containers')).forEach(e => {
                e.style.scrollBehavior = 'auto';
                e.style.height = '0px';
                e.style.height = `${e.parentElement.clientHeight}px`;
                e.scrollTop = e.scrollHeight;
                setTimeout(() => {
                    e.style.height = `${e.parentElement.clientHeight}px`;
                    e.scrollTop = e.scrollHeight;
                }, 200);
                e.style.scrollBehavior = 'smooth';
            });
        });
    }


    /**
     * 
     * @param {Object} streamInfo - Details of the stream and streamer
     * 
     * Initialize PeerJS peer and socket
     * Set view count from stream info, dimensions of call panel
     */
    load = streamInfo => {
        this.setState({
            ...this.state,
            streamInfo: streamInfo ? streamInfo : this.state.streamInfo,
            peer: new Peer(undefined, {
                host: process.env.REACT_APP_PEER_HOST,
                port: process.env.REACT_APP_PEER_PORT,
                secure: true
            }),
            chatSocket: io(process.env.REACT_APP_ROOT),
            viewCount: streamInfo ? streamInfo.viewCount : this.state.viewCount,
            callPanelHeight: document.getElementById('streamer-stream') ? document.getElementById('streamer-stream').clientHeight : this.state.callPanelHeight,
            callPanelWidth: document.getElementById('streamer-stream') ? document.getElementById('streamer-stream').clientWidth : this.state.callPanelWidth
        }, () => {

            // Peer Start

            this.state.peer.on('open', id => this.setState({
                ...this.state,
                peerOpened: true
            }, () => console.log('open', id)));

            // When called, answer with own stream
            this.state.peer.on('call', call => {
                if (call.metadata && call.metadata.viewer && call.metadata.viewer !== this.props.redux.username){
                    call.answer(this.state.mediaStream);
                    call.on('stream', stream => {
                        this.handleStream(call, stream, call.metadata.viewer)
                    });
                }
                
            });

            // Handle call requests from the streamer
            this.state.chatSocket.on('call-request', peerID => {
                if (peerID !== this.state.peer._id){
                    this.setState({
                        ...this.state,
                        streamerPeerID: peerID
                    }, () => this.callStreamer(peerID));
                }
            });

            // Peer End

            // Error handling for when the socket errors out on the server. TODO: Don't nuke everything over tiny errors
            this.state.chatSocket.on('socket-error', () => {
                alert('An error occurred with the chat socket and the application needed to be closed.');
                this.props.redux.history.push('/browse');
            });

            /**
             * Public chats
             * If state.scrollTops.public, scroll to bottom after a new chat is received
             */
            this.state.chatSocket.on('publicMessage', message => {
                
                let chatContainer = document.querySelector('#container-public-chat');
                chatContainer.style.height = `${document.querySelector('#border-public-chat').clientHeight}px`;  
                this.setState({
                    ...this.state,
                    publicChatMessages: [
                        ...this.state.publicChatMessages,
                        message
                    ],
                    unreadChatMessages: this.state.publicChatShownMobile ? this.state.unreadChatMessages : this.state.unreadChatMessages + 1
                }, () => setTimeout(() => {
                    if (this.state.scrollTops.public){
                        let newContainer = document.querySelector('#container-public-chat');
                        newContainer.scrollTop = newContainer.scrollHeight; 
                    }
                }, 100));
            });

            /**
             * Someone other than the streamer has diconnected from a group call
             * Filter them out of state.activeCalls
             */
            this.state.chatSocket.on('viewerDisconnect', viewer => {
                this.setState({
                    ...this.state,
                    activeCalls: this.state.activeCalls.filter(a => a.viewer !== viewer)
                })

            });

            /**
             * Streamer has sent chat message
             * Update state. If scrollTops.private, scroll to bottom of chat
             */
            this.state.chatSocket.on('streamerMessage', details => {
                let chatContainer = document.querySelector('#container-private-chat');
                let scrollToBottom;
                if (chatContainer){
                    scrollToBottom = (chatContainer.scrollHeight - chatContainer.scrollTop) === chatContainer.clientHeight;
                    chatContainer.style.height = `${document.querySelector('#border-private-chat').clientHeight}px`;    
                }           
                this.setState({
                    ...this.state,
                    callStatus: this.state.callStatus === 'chat' ? 'approve' : this.state.callStatus,
                    streamerChatMessages: [
                        ...this.state.streamerChatMessages,
                        details
                    ],
                    unreadChatMessages: this.state.publicChatShownMobile ? this.state.unreadChatMessages + 1 : this.state.unreadChatMessages
                }, () => setTimeout(() => {
                    if (chatContainer && this.state.scrollTops.private){
                        let newContainer = document.querySelector('#container-private-chat');
                        newContainer.scrollTop = newContainer.scrollHeight * 2;
                    } 
                }, 100));
            });

            /**
             * Host has disconnected
             * Redirect to browse page
             */
            this.state.chatSocket.on('stream-end', () => {
                this.props.redux.history.push('/browse');
                console.log('end')
                alert('The host has disconnected');
            });

            // Most likely never used
            this.state.chatSocket.on('streamerSignal', signal => {
                this.state.streamerPeer.signal(signal.signal)
            });

            /**
             * Call has been accepted
             * Must make p2p requests to all parties for their streams
             * 
             */
            this.state.chatSocket.on('callAccepted', callList => this.setState({
                ...this.state,
                callStatus: 'success'
            }, () => {
                callList.filter(c => c.viewer !== this.props.redux.username).forEach(item => {
                    let call = this.state.peer.call(item.peerID, this.state.mediaStream, {
                        metadata: {
                            avatar: this.props.redux.avatar,
                            viewer: this.props.redux.username,
                            displayName: this.props.redux.displayName
                        }
                    });
                    call.on('stream', stream => {
                        this.handleStream(call, stream, item.viewer);
                    }); 
                })
            }));

            /**
             * Call has been dropped
             * Change view back to streamer's regular video
             * Notify user of disconnect
             */
            this.state.chatSocket.on('callDropped', () => this.setState({
                ...this.state,
                callStatus: 'approve'
            }, () => this.setState({
                ...this.state,
                callPanelHeight: document.getElementById('streamer-stream') ? document.getElementById('streamer-stream').clientHeight : this.state.callPanelHeight,
                disconnectAlerts: [
                    ...this.state.disconnectAlerts,
                    {
                        id: this.props.params.user,
                        alertNode: <DisconnectViewer streamer={this.props.params.user}/>
                    }
                ],
                activeCalls: []
            }, () => {
                new bootstrap.Toast(document.getElementById(`toast_${this.props.params.user}`)).show()
            })));

            // Connect to stream. Increment view count by 1, join stream socket room on server.
            this.state.chatSocket.emit('connectToStream', this.props.params.user);

            // Set view count interval
            this.setState({
                ...this.state,
                viewCountInterval: setInterval(() => {
                    this.state.chatSocket.emit('views', this.props.params.user);
                }, 2000)
            }, () => {

                // Should change this event from viewCount
                this.state.chatSocket.on('viewCount', (pollData, streamInfo) => this.setState({
                    ...this.state,
                    viewCount: streamInfo.viewCount,
                    streamInfo: streamInfo
                }, () => this.props.redux.set_poll(pollData)));
                this.state.chatSocket.emit('views', this.props.params.user);
                if (this.state.newAccountCall) this.requestCall();
                if (this.state.newAccountChat && document.getElementById('textarea-public-chat')) document.getElementById('textarea-public-chat').focus();
            });
        });
    }

    /**
     * Hit when a peer sends a media stream in response to a call, or calls offering a stream
     * 
     * @param {PeerJS Call Object} call 
     * @param {MediaStream Object} stream 
     * @param {String} viewer 
     * 
     * Add the stream to the callers activeCall object
     */
    handleStream = (call, stream, viewer) => {
        this.setState({
            ...this.state,
            activeCalls: this.state.activeCalls.find(c => c.peer === call.peer) ? this.state.activeCalls.map(c => {
                if (c.peer === call.peer) return {
                    ...c,
                    mediaStream: stream,
                    viewer: viewer,
                    avatar: call.metadata.avatar,
                    peer: call.peer,
                    displayName: call.metadata.displayName
                } 
                else return c
            }) : [
                ...this.state.activeCalls,
                {
                    mediaStream: stream,
                    viewer: viewer,
                    avatar: call.metadata.avatar,
                    peer: call.peer,
                    displayName: call.metadata.displayName
                } 
            ]
        });
    }

    /**
     * Hit when the streamer requests a call, this is to allow the streamer to see ones video devices if any
     * 
     * @param {String} peerID 
     * 
     * Calls the streamer with the current stream, expects nothing back
     */
    callStreamer = peerID => {
        const call = this.state.peer.call(peerID, this.state.mediaStream);
        if (call) call.on('close', () => {
            console.log('closed', peerID);
        });
        else {
            console.log('call failed', peerID, this.state.mediaStream);
            alert('An error occurred. Make sure your camera and mic are not being used by other applications, refresh the page, and try again.', peerID, this.state.mediaStream, call);
        } 
    }

    /**
     * 
     * @param {Event Object} e - Javascript event object
     * 
     * Handle text changes in the public chat
     */
    publicChatChangeHandler = e => {
        if (e.target.value.split('\n').length > 1) document.getElementById('textarea-public-chat').value = document.getElementById('textarea-public-chat').value.split('\n')[0];
        else this.setState({
            ...this.state,
            publicChatCharCount: String(e.target.value).length
        });
    } 

    /**
     * 
     * @param {Event Object} e - Javascript event object
     * 
     * Handle text changes in the private chat
     */
    privateChatChangeHandler = e => {
        if (e.target.value.split('\n').length > 1) document.getElementById('textarea-private-chat').value = document.getElementById('textarea-private-chat').value.split('\n')[0];
        else this.setState({
            ...this.state,
            streamerChatCharCount: String(e.target.value).length
        });
    } 

    /**
     * 
     * @returns the list of public chat messages
     */
    renderPublicChatMessages = () => this.state.publicChatMessages.map(message => (
        <div className="border-top border-bottom border-light w-100">
            <div className="d-flex align-items-start my-1 w-100">
                <img className="chat-avatars me-2" src={`/thumbnails/${message.avatar}`} alt={`${message.user} avatar`}></img>
                <p style={{margin: 0}} className="text-break emoji-chats"><span title={message.username} className={`fw-bold ${message.username === this.props.params.user ? 'text-success' : ''}`}>{message.user}:</span> {message.message}</p>
            </div>
        </div>
    ));

    /**
     * 
     * @returns The list of private (streamer) chat messages
     */
    renderStreamerChats = () => this.state.streamerChatMessages.map(message => {
        if (message.user === message.streamer){
            return (
                <div className="border-top border-bottom border-light w-100">
                    <div className="d-flex align-items-start my-1 w-100">
                        <img className="chat-avatars mx-2" src={`/thumbnails/${message.avatar}`} alt={`${message.displayName} avatar`}></img>
                        <p style={{margin: 0}} className="text-break emoji-chats">{message.message}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="border-top border-bottom border-light w-100">
                    <div className="d-flex align-items-start my-1 w-100 justify-content-end">
                        <p style={{margin: 0}} className="text-break emoji-chats">{message.message}</p>
                        <img className="chat-avatars mx-2" src={`/thumbnails/${message.avatar}`} alt={`${message.displayName} avatar`}></img>
                    </div>
                </div>
            );
        }
    });

    // TO DO - Make this section DRY (Or just rewrite this entire trash)

    /**
     * 
     * @param {Event Object} e - Input keypress event
     * 
     * If the user presses the enter key and has not exceeded the max public chat characters, send the chat
     */
    keyPressPublicChat = e => {
        if (e.key === 'Enter'){
            if (document.querySelector('#textarea-public-chat').value !== '' && document.querySelector('#textarea-public-chat').value !== '\n') this.sendPublicChat();
            else {
                if (e !== undefined) e.stopPropagation();
                this.sendPublicChat();
            } 
        } 
    }

    /**
     * 
     * @param {Event Object} e - Input keypress event
     * 
     * If the user presses the enter key and has not exceeded the max public chat characters, send the chat
     */
    keyPressPrivateChat = e => {
        if (e.key === 'Enter'){
            if (document.querySelector('#textarea-private-chat').value !== '' && document.querySelector('#textarea-private-chat').value !== '\n') this.sendPrivateChat();
            else {
                if (e !== undefined) e.stopPropagation();
                this.sendPrivateChat();
            } 
        } 
    }

  
    /**
     * Fired when the user selects the emoji icon near the public chat
     * 
     * Hide the private chat emoji picker if shown
     * Show the public emoji picker
     */
    toggleShowEmojiPicker_public = () => this.setState({
        ...this.state,
        publicEmojiPickerShown: !this.state.publicEmojiPickerShown,
        streamerEmojiPickerShown: false
    }, () => {
        [].slice.call(document.getElementsByClassName('emoji-picker-react')).forEach(e => {
            e.style.top = `${e.clientHeight}px`;
            e.style.opacity = 1;
        });
    });

    /**
     * Fired when the user selects the emoji icon near the private chat
     * 
     * Hide the public chat emoji picker if shown
     * Show the private emoji picker
     */
    toggleShowEmojiPicker_streamer = e => {
        e.stopPropagation();
        this.setState({
            ...this.state,
            streamerEmojiPickerShown: !this.state.streamerEmojiPickerShown,
            publicEmojiPickerShown: false
        }, () => {
            [].slice.call(document.getElementsByClassName('emoji-picker-react')).forEach(e => {
                e.style.bottom = `${(e.clientHeight / 2.5)}px`;
                e.style.opacity = 1;
            });
        });
    } 

    /**
     * Fired by the public chat cooldown interval
     * Decrements the public chat timeout by 1
     * 
     */
    publicChatCountdown = () => this.setState({
        ...this.state,
        publicChatTimeout: this.state.publicChatTimeout - 1
    }, () => {
        if (this.state.publicChatTimeout === 0) clearInterval(this.state.publicChatWaitInterval);
    });

    /**
     * Fired by the streamer chat cooldown interval
     * Decrements the streamer chat timeout by 1
     * 
     */
    streamerChatCountdown = () => this.setState({
        ...this.state,
        streamerChatTimeout: this.state.streamerChatTimeout - 1
    }, () => {
        if (this.state.streamerChatTimeout === 0) clearInterval(this.state.streamerChatWaitInterval);
    });

    /**
     * Fired when the user sends a public chat
     * 
     * Hide the emoji pickers
     * Validate the inputs
     * Send the chat
     * Put user on cooldown for 3 seconds
     */
    sendPublicChat = e => {
        if (e !== undefined) e.stopPropagation();
        this.setState({
            ...this.state,
            publicEmojiPickerShown: false,
            streamerEmojiPickerShown: false
        }, () => {
            if (!this.state.sendingPublicChat && document.querySelector('#textarea-public-chat').value !== '' && this.state.publicChatTimeout === 0 && this.state.publicChatCharCount < 601){
                this.setState({
                    ...this.state,
                    sendingPublicChat: true,
                    submitting: true
                }, () => {
                    try {
                        this.state.chatSocket.emit('publicChat', document.querySelector('#textarea-public-chat').value, this.props.redux.displayName, this.props.redux.avatar, this.props.params.user);
                        this.setState({
                            ...this.state,
                            sendingPublicChat: false,
                            publicChatTimeout: 3,
                            publicChatWaitInterval: setInterval(this.publicChatCountdown, 1000)
                        }, () => setTimeout(() => this.setState({
                            ...this.state,
                            publicChatCharCount: 0
                        }, () =>  document.querySelector('#textarea-public-chat').value = '')), 200);
                    } catch(err){
                        console.log(err);
                        this.setState({
                            ...this.state,
                            sendingPublicChat: false
                        });
                    }
                });
            }
        });
    }

    /**
     * Fired when the user sends a private chat
     * 
     * Hide the emoji pickers
     * Validate the inputs
     * Send the chat
     * Put user on cooldown for 3 seconds
     */
    sendPrivateChat = e => {
        if (e !== undefined) e.stopPropagation();
        this.setState({
            ...this.state,
            publicEmojiPickerShown: false,
            streamerEmojiPickerShown: false
        }, () => {
            if (!this.state.sendingPrivateChat && document.querySelector('#textarea-private-chat').value !== '' && this.state.streamerChatTimeout === 0 && this.state.streamerChatCharCount < 601){
                this.setState({
                    ...this.state,
                    sendingPrivateChat: true,
                    submitting: true
                }, () => {
                    try {
                        this.state.chatSocket.emit('streamer-chat-viewer', document.querySelector('#textarea-private-chat').value, this.props.params.user, this.state.peer._id);
                        this.setState({
                            ...this.state,
                            sendingPrivateChat: false,
                            streamerChatTimeout: 3,
                            streamerChatWaitInterval: setInterval(this.streamerChatCountdown, 1000)
                        }, () => setTimeout(() => this.setState({
                            ...this.state,
                            streamerChatCharCount: 0
                        }, () =>  document.querySelector('#textarea-private-chat').value = '')), 200);
                    } catch(err){
                        console.log(err);
                        this.setState({
                            ...this.state,
                            sendingPrivateChat: false
                        });
                    }
                });
            }
        });
    }

    /**
     * Fired when the user requests to call the streamer
     * 
     * Signals via socket the desire to call
     * Obtains the user's media devices
     */
    requestCall = () => {
        this.state.chatSocket.emit('request-call', this.props.params.user);
        this.setState({
            ...this.state,
            callRequested: true,
            microphoneEnabled: true,
            cameraEnabled: false
        }, () => {
            if (this.state.publicChatShownMobile) this.togglePublicChat();
            this.setMediaStream();
        });
    } 

    /**
     * Fired when the user rotates their camera
     * 
     * Changes the camera index
     * Resets media stream
     */
    rotateCamera = () => this.setState({
        ...this.state,
        cameraIndex: this.state.cameraIndex + 1 < this.state.videoDevices.length ? this.state.cameraIndex + 1 : 0
    }, () => this.setMediaStream());

    /**
     * Set media stream according to the parameters in state
     * Set <video> srcObject with new video if applicable
     * Notify other peers if in a call
     */
    setMediaStream = () => {
        if (navigator.mediaDevices){
            navigator.mediaDevices.getUserMedia({
                audio: this.state.microphoneEnabled,
                video: this.state.cameraEnabled ? (this.state.videoDevices.length > 1 ? {
                    deviceId: this.state.videoDevices[this.state.cameraIndex]
                } : true) : false
            }).then(async mediaStream => {
                let devices = await navigator.mediaDevices.enumerateDevices();
                mediaStream.user = this.props.redux.username;
                if (!mediaStream.getAudioTracks().length && !mediaStream.getVideoTracks().length) return this.setState({
                    ...this.state,
                    devicesAllowed: false
                }, () => alert('Please allow use of your media devices'));
                this.setState({
                    ...this.state,
                    mediaStream: mediaStream,
                    videoDevices: devices.filter(d => d.kind === 'videoinput').map(v => v.deviceId),
                    devicesAllowed: true
                }, () => {
                    if (this.state.cameraEnabled && this.state.callStatus !== 'success'){
                        [].slice.call(document.getElementsByClassName('cameras')).forEach(webCam => {
                            if (webCam.id === 'camera-modal') webCam.style.height = `${webCam.parentElement.clientHeight}px`;
                            webCam.srcObject = this.state.mediaStream;
                            webCam.play();
                        });
                    }
                    if (this.state.streamerPeerID !== ''){
                        this.state.peer.call(this.state.streamerPeerID, this.state.mediaStream);
                        this.state.activeCalls.forEach(call => this.state.peer.call(call.peer, this.state.mediaStream, {
                            metadata: {
                                avatar: this.props.redux.avatar,
                                viewer: this.props.redux.username,
                                displayName: this.props.redux.displayName
                            }
                        }))
                    }
                });
            }).catch(err => {
                console.log(err);
                alert('Please give permission to use your input devices', err);
            });
        } else alert("Your browser doesn't support the use of media devices");
        
    }

    /**
     * Fired when the user selects the microphone icon
     * Toggles the microphone
     * 
     */
    toggleMicrophone = () => this.setState({
        ...this.state,
        microphoneEnabled: !this.state.microphoneEnabled
    }, () => this.setMediaStream());

    /**
     * Fired when the user selects the camera icon
     * Toggles the camera
     * 
     */
    toggleCamera = () => {
        let avatar = document.getElementById('div-avatar');
        let avatarHeight = avatar ? avatar.clientHeight : 0;
        this.setState({
            ...this.state,
            cameraEnabled: !this.state.cameraEnabled
        }, () => {
            if (this.state.cameraEnabled && this.state.callStatus !== 'success') document.getElementById('camera').style.height = `${avatarHeight}px`;
            this.setMediaStream()
        });
    } 

    /**
     * 
     * @param {String} small - Status id
     * @returns Colored text depending on the call stage
     */
    getCallStatus = small => {
        switch(this.state.callStatus){
            case 'chat':
                return <></>
            case 'approve':
                if (small) return <div style={{margin: 0}} className="alert alert-warning py-1 px-2" role="alert">Waiting for Approval</div>
                else return <p style={{margin: 0}} className={`${small ? '' : 'text-end'} text-warning`}>Waiting for Approval</p>
            case 'success':
                if (small) return <div style={{margin: 0}} className="alert alert-success py-1 px-2" role="alert">You are on the air</div>
                else return <p style={{margin: 0}} className={`${small ? '' : 'text-end'} text-success`}>You are on the air</p>
            default:
                if (small) return <div style={{margin: 0}} className="alert alert-warning py-1 px-2" role="alert">Waiting for Approval</div>
                else return <p style={{margin: 0}} className={`${small ? '' : 'text-end'} text-warning`}>Waiting for Approval</p>
        }
    }


    /**
     * 
     * @returns List of all peers in a call, including video/audio feeds
     */
    renderActiveCalls = () => {
        return [
            {
                mediaStream: this.state.mediaStream,
                avatar: this.props.redux.avatar,
                viewer: this.props.redux.username,
                displayName: this.props.redux.displayName
            },
            ...this.state.activeCalls
        ].map(call => {
            if (call.mediaStream.getVideoTracks && call.mediaStream.getVideoTracks().length){
                return (
                    <div title={call.viewer} className="position-relative">
                        <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.displayName}</p>
                        <video id={`active-${call.viewer}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}} autoPlay className="d-block active-calls" ></video>
                    </div>
                ) 
            } else if (call.mediaStream.getAudioTracks && call.mediaStream.getAudioTracks().length){
                return (
                    <div title={call.viewer} className="position-relative">
                        <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.displayName}</p>
                        <div style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight}px`}}>
                            {call.viewer !== this.props.username ? <audio className="active-calls" id={`active-${call.viewer}`} autoPlay></audio>: <></>}
                            <img className="d-block" src={`/avatars/${call.avatar}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}}></img>
                        </div>
                    </div>
                ); 
            } else return (
                <div title={call.viewer} className="position-relative">
                    <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.displayName}</p>
                    <img className="d-block" src={`/avatars/${call.avatar}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}}></img>
                </div>
            ) 
        });
    }

    /**
     * Fired when the user clicks on the poll
     * Sets the Bootstrap poll modal into state, and shows the modal
     * 
     */
    viewPoll = () => this.setState({
        ...this.state,
        viewPollKey: !this.state.viewPollKey
    }, () => this.setState({
        ...this.state,
        viewPollModal: new bootstrap.Modal(document.getElementById('viewPollModal'))
    }, () => this.state.viewPollModal.show())); 

    /**
     * Triggered by various things
     * Closes all of the emoji selectors
     */
    closeAllEmojis = () =>{
        this.props.redux.set_emoji_picker(-1);
        if (this.state.copyPopover.hide) this.state.copyPopover.hide();
        if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
        if (this.state.copyPopover.hide || this.state.copyTooltip.hide) this.setState({
            ...this.state,
            copyTooltip: '',
            copyPopover: ''
        })
    } 

    /**
     * 
     * @param {Event Object} e  - Standard JavaScript event object
     * 
     * This runs concurrently with the emoji picker function, and stops other default events from triggering
     */
    clickEmojiContainer = e => {
        e.stopPropagation();
    }

    /**
     * 
     * @param {Object} emoji - Dismoji emoji object
     * 
     * Insert emoji into proper text box 
     * Increment character count in state
     * Close emoji picker
     */
    pickEmoji = (emoji) => {
        let input;
        switch(this.props.redux.emojiPickerShown){
            case 1:
                input = document.getElementById('textarea-private-chat');
                input.value = input.value + emoji;
                this.setState({
                    ...this.state,
                    streamerChatCharCount: this.state.streamerChatCharCount + 2
                }, () => {
                    this.props.redux.set_emoji_picker(-1);
                    document.getElementById('textarea-private-chat').focus();
                });
                break;
            case 2:
                input = document.getElementById('textarea-public-chat');
                input.value = input.value + emoji;
                this.setState({
                    ...this.state,
                    publicChatCharCount: this.state.publicChatCharCount + 2
                }, () => {
                    this.props.redux.set_emoji_picker(-1);
                    document.getElementById('textarea-public-chat').focus();
                });
                break;
            default:
                console.log('n/a');
        }
    }

    /**
     * 
     * @param {Event Object} e - Standard JavaScript event
     * Set the emoji selection into state
     * The actual emoji picker ui will open up from the componentDidUpdate function
     */
    openEmojiPicker = e => {
        e.stopPropagation();
        const index = Number(e.target.id.split('-')[1]);
        if (index !== this.props.redux.emojiPickerShown) this.props.redux.set_emoji_picker(index);
        else this.props.redux.set_emoji_picker(-1);
    }

    /**
     * Fired when the user or the streamer disconnects from the call
     * Reset everything call-related, and signal via socket that the disconnect has taken place
     */
    disconnect = () => {
        this.setState({
            ...this.state,
            callRequested: false,
            sendingPrivateChat: false,
            streamerChatMessages: [],
            streamerChatCharCount: 0,
            streamerChatTimeout: 0,
            streamerChatWaitInterval: '',
            streamerEmojiPickerShown: false,
            cameraEnabled: false,
            microphoneEnabled: true,
            callStatus: 'chat',
            mediaStream: '',
            activeCalls: [],
            streamerPeerID: ''
        }, () => this.state.chatSocket.emit('disconnect-chat'));
    } 

    /**
     * Fired when the user clicks one of the 2 Create Account buttons
     * 
     * @param {Boolean} calling - Whether the user is creating the account to make a call
     * 
     * Displays the Bootstrap modal containing the registration form
     */
    createAccount = calling => this.setState({
        ...this.state,
        newAccountModal: new bootstrap.Modal(document.getElementById('newAccountModal')),
        newAccountCall: calling,
        newAccountChat: !calling
    }, () => this.state.newAccountModal.show());


    /**
     * Fired when a mobile user toggles the public chat
     * Resizes the containers for the new chatbox (public/streamer)
     * Scrolls to the bottom of the chat
     */
    togglePublicChat = () => {
        if (window.screen.availWidth <= 768){
            let firstContainerHeight = this.state.publicChatShownMobile ? document.getElementById('mobile-chat-container').clientHeight : document.getElementById('mobile-call-container').clientHeight;
            let scroll = window.scrollY;
            this.setState({
                ...this.state,
                publicChatShownMobile: !this.state.publicChatShownMobile,
                unreadChatMessages: 0
            }, () => {
                this.resize();
                let secondContainer = this.state.publicChatShownMobile ? document.getElementById('mobile-chat-container') : document.getElementById('mobile-call-container');
                if (firstContainerHeight > secondContainer.clientHeight) secondContainer.style.height = `${firstContainerHeight}px`;
                window.scrollTo(0, scroll);
                setTimeout(() => {
                    window.scrollTo(0, scroll);
                }, 100);
                if (!this.state.callRequested && !this.state.publicChatShownMobile) this.requestCall();
            });
        }
    } 

    /**
     * Fired when the user taps or clicks their own device video/audio
     * Opens up a large modal with the same but larger device select interface
     */
    devicesModal = () => this.setState({
        ...this.state,
        devicesModal: new bootstrap.Modal(document.getElementById('devicesModal'))
    }, () => this.state.devicesModal.show());

    /**
     * 
     * @param {Event Object} e - Scroll event
     * 
     * Handles chatbox scroll events
     * If scrolled to the bottom, scrollTop for that container will be true (auto scroll to bottom if new chat)
     */
    scrollHandler = e => {
        switch(e.currentTarget.id){
            case 'container-public-chat':
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops.public) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public: true
                    }
                });
                else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops.public) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public: false
                    }
                });
                break;
            case 'container-private-chat':
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops.private) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        private: true
                    }
                });
                else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops.private) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        private: false
                    }
                });
                break;
        }
    }

    /**
     * Fired when the user taps or clicks the call panel
     * Opens up a full screen modal with a larger view of the call
     * 
     */
    streamModal = () => this.setState({
        ...this.state,
        streamBigModal: new bootstrap.Modal(document.getElementById('streamBigModal'))
    }, () => this.state.streamBigModal.show());

    /**
     * Hides the big stream modal
     */
    hideStreamModal = () => {
        if (this.state.streamBigModal && this.state.streamBigModal.hide) this.state.streamBigModal.hide()
    } 
    
    /**
     * 
     * Fired when the user clicks "Copy Link"
     * 
     * @param {Event Object} e - Standard Javascript event
     * 
     * Removes any previous popovers or tooltips
     * Opens new popover, sets innerHTML to a link to the stream and a button to copy the link
     * 
     */
    setPopoverContent = e =>{
        e.stopPropagation();
        if (this.state.copyPopover.hide){
            if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
            this.state.copyPopover.hide();
            [].slice.call(document.getElementsByClassName('popover')).forEach(p => p.remove());
            this.setState({
                ...this.state,
                copyPopover: '',
                copyTooltip: ''
            })
        } else {
            if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
            if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
            [].slice.call(document.getElementsByClassName('popover')).forEach(p => p.remove());
            this.setState({
                ...this.state,
                copyPopover: new bootstrap.Popover(document.getElementById('button-popover'), {
                    html: true,
                    content: `<p>https://nanaphone.net/${this.props.params.user}</p>`,
                    placement: 'right'
                })
            }, () => {
                this.state.copyPopover.show();
                setTimeout(() => {
                    [].slice.call(document.getElementsByClassName('popover-body')).forEach(e => {
                        e.innerHTML = `
                            <div class="d-flex align-items-center">
                                <p class="m-0">https://nanaphone.net/${this.props.params.user}</p>
                                <button id="button-tooltip" data-bs-toggle="tooltip" data-bs-placement="right" title="Copy" type="button" class="btn btn-sm btn-outline-primary ms-2"><i class="far fa-copy"></i></button>
                            </div>
                        `;
                        document.getElementById('button-tooltip').addEventListener('click', this.copyLink);
                    });
                    [].slice.call(document.getElementsByClassName('popover')).forEach(e => e.style.maxWidth = 'max-content');
                    this.setState({
                        ...this.state,
                        copyTooltip: new bootstrap.Tooltip(document.getElementById('button-tooltip'))
                    });
                }, 100);
            }); 
        } 
    } 

    /**
     * Fired when the user clicks the button to copy a link to the stream
     * 
     * @param {Event Object} p - Standard JavaScript event object
     * 
     * Copies the stream link to the clipboard, changes the text of the tooltip to say "Copied"
     */
    copyLink = p => {
        p.stopPropagation();
        let e = document.getElementById('button-tooltip');
        e.setAttribute('data-bs-original-title', 'Copied');
        navigator.clipboard.writeText(`https://nanaphone.net/${this.props.params.user}`);
        this.state.copyTooltip.hide();
        this.setState({
            ...this.state,
            copyTooltip: new bootstrap.Tooltip(e)
        }, () => this.state.copyTooltip.show());
    }

    render(){
        return (
            <motion.div onClick={this.closeAllEmojis} className="container-fluid fg-1 d-flex flex-column" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                
                <ViewPollModal streamer={this.props.params.user} key={this.state.viewPollKey} viewPollModal={this.state.viewPollModal} />
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
                    <NewAccount streamer={this.props.params.user} load={this.load} newAccountModal={this.state.newAccountModal}/>
                </GoogleReCaptchaProvider>
                <StreamBigModal hideStreamModal={this.hideStreamModal} myStream={this.state.mediaStream} streamBigModal={this.state.streamBigModal} streams={[
                    {
                        mediaStream: this.state.mediaStream,
                        avatar: this.props.redux.avatar,
                        viewer: this.props.redux.username
                    },
                    ...this.state.activeCalls
                ]} />
                {this.state.callRequested ?
                <DevicesModal microphoneEnabled={this.state.microphoneEnabled} toggleMicrophone={this.toggleMicrophone} cameraEnabled={this.state.cameraEnabled} toggleCamera={this.toggleCamera} rotateCamera={this.rotateCamera} mediaStream={this.state.mediaStream} videoDevices={this.state.videoDevices} avatar={this.props.redux.avatar} devicesModal={this.state.devicesModal} /> : <></>}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3 show-lg">
                            <p className="mt-2 text-nowrap" style={{marginBottom: 0}}>{this.state.viewCount} {this.state.viewCount === 1 ? 'Viewer' : 'Viewers'}</p>
                            <button id="button-popover" onClick={this.setPopoverContent} type="button" class="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right">
                            <i className="fas fa-share-alt me-2"></i>
                            Share Link
                            </button>
                        </div>
                        <div className="col-12 col-md-6"><h1 style={{margin: 0}} className="text-center mt-3 display-5">{this.state.streamInfo.title}</h1></div>
                        <div className="col-12 col-md-3">
                            {this.props.redux.pollData && this.props.redux.pollData.options ?
                            <div onClick={this.props.redux.username ? this.viewPoll : () => this.createAccount(false)} style={{marginBottom: 0}} className="alert alert-primary poll-info mt-2" role="alert">
                            <i className="fas fa-poll me-2"></i>POLL: {this.props.redux.pollData.title}
                            </div> : <></>}
                        </div>
                    </div>
                    <div className="show-sm">
                        <div className="d-flex justify-content-between">
                            <p className="mt-2 text-nowrap" style={{marginBottom: 0}}>{this.state.viewCount} {this.state.viewCount === 1 ? 'Viewer' : 'Viewers'}</p>
                            <p title={this.props.redux.username} className="mt-2 text-nowrap" style={{marginBottom: 0}}>{this.state.streamInfo.streamer}</p>
                        </div>
                    </div>
                </div>
                <hr style={{marginTop: 0, marginBottom: '0.5rem'}}></hr>
                <div style={{position: 'absolute', bottom: '105px'}} className="container" id="notification-container">{this.state.disconnectAlerts.map(a => a.alertNode)}</div>
                <div className={`row ${typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ? 'fg-1' : 'pb-3'}`}>
                        <div style={{transition: '0.25s'}} className={`col-md-7 col-12 d-flex flex-column h-98 streamer-column-mobile`}>
                            {this.state.callStatus === 'success' ?
                            <div onClick={() => new bootstrap.Modal(document.getElementById('streamBigModal')).show()} className="border border-dark d-flex w-100" style={{height: `${this.state.callPanelHeight}px`, overflowX: 'auto', overflowY: 'hidden', cursor: 'pointer'}} id="call-container">
                                {this.renderActiveCalls()}
                            </div>
                            :
                            <>
                                {this.state.videoStream ?
                                <iframe id="streamer-stream" className={`w-100 ${typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ? `${this.state.callRequested ? 'h-33' : 'h-75'}` : ''}`} src={this.state.streamInfo.streamUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> :
                                <div id="streamer-stream" className={`${this.state.callRequested ? 'h-33' : 'h-75'}`} style={{height: `${this.state.callPanelHeight}px`, backgroundImage: `url("/avatars/${this.state.streamInfo.thumbnail === 'monkey-dark.png' ?this.state.streamInfo.avatar:this.state.streamInfo.thumbnail}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}></div>}
                            </>
                            }
                            <div className="show-sm">
                                <div className="d-flex justify-content-between align-items-start mt-2">
                                    <button onClick={this.props.redux.username === '' ? () => this.createAccount(true) : this.togglePublicChat} className="btn btn-primary">{this.state.publicChatShownMobile ? 
                                    <>
                                        {this.state.callRequested ? 
                                        <>
                                            <i className="fas fa-user me-2"></i>{this.props.params.user} Chat {this.state.unreadChatMessages ? `(${this.state.unreadChatMessages})` : ''}
                                        </> :
                                        <>
                                            <i className="fas fa-phone me-2"></i>Request to Call
                                        </>}
                                    </> : 
                                    <>
                                        <i className="fas fa-comments me-2"></i>Public Chat {this.state.unreadChatMessages ? `(${this.state.unreadChatMessages})` : ''}
                                    </>}
                                    </button>
                                     
                                    <div className="show-sm">{this.state.callRequested ? this.getCallStatus(true):
                                     <div style={{margin: 0, opacity: 0, cursor: 'default'}} className="alert alert-warning py-1 px-2" role="alert">Waiting for Approval</div>}
                                     </div> 
                                </div>
                            </div>
                            

                            {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ?
                            <div className="card mt-2 fg-1">
                                <div className="card-header py-2 d-flex justify-content-between">
                                    <h5 style={{margin: 0}}>{this.state.streamInfo.streamer}</h5>
                                    {this.state.callRequested ? 
                                    <div className="d-flex show-lg">
                                        <span className="show-lg">{this.getCallStatus(false)}</span>
                                        <button onClick={this.disconnect} className="ms-2 btn btn-danger align-self-start d-block"><i className="fas fa-phone-slash me-2"></i>Disconnect</button>
                                    </div>  : <></>}
                                </div>
                                <div className={`card-body d-flex flex-column p-1 ${this.state.callRequested ? '' : 'justify-content-center h-100'}`}>
                                    {this.state.callRequested ?
                                    <>
                                        {!this.state.peerOpened ? 
                                        <>
                                            <h5 className="text-center my-4">Connecting to Peer Server</h5>
                                            <LinearProgress />
                                        </> :
                                        <>
                                            {!this.state.devicesAllowed ? 
                                            <>
                                                <h5 className="text-center my-4">Waiting for Devices</h5>
                                                <LinearProgress />
                                            </> :
                                            <>
                                                <div className="fg-1">
                                                    <div className="container-fluid h-100 px-0">
                                                        <div className="row h-100">
                                                            <div className="col-4 h-100 d-flex">
                                                                <div id="devices-stream-med">
                                                                    <div className="d-flex justify-content-between px-1">
                                                                        {this.state.microphoneEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-4"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-4"></i>}
                                                                        {this.state.cameraEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-4 ms-1"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-4 ms-1"></i>}
                                                                    </div>
                                                                    {this.state.videoDevices.length > 1 && this.state.cameraEnabled ?
                                                                    <div onClick={this.rotateCamera} id="camera-rotate"></div> : <></>}
                                                                </div>
                                                                {this.state.cameraEnabled && this.state.callStatus !== 'success' ?
                                                                <div className="fg-1 h-100 position-relative" style={{cursor: 'pointer'}} onClick={this.devicesModal} dangerouslySetInnerHTML={{
                                                                    __html: `
                                                                        <div class="position-absolute w-100 h-100 right-0 bottom-0">
                                                                            <video class="d-block cameras" style="margin: 0; padding: 0; max-height: 100%; max-width: 100%" id="camera" autoplay muted playsinline></video>
                                                                        </div>
                                                                    `
                                                                }}></div>
                                                                :
                                                                <div onClick={this.devicesModal} id="div-avatar" className="h-100 fg-1" style={{margin: 0, cursor: 'pointer', padding: 0, background: `${this.state.callStatus === 'success' ? `url('${`/images/monkey_logo.png`}')` : `url('${`/avatars/${this.props.redux.avatar}`}')`}`}}></div>
                                                                }
                                                            </div>
                                                            <div className={`col-8 h-100`}>
                                                                <div id="border-private-chat"  className={`h-100 border border-light chat-borders ${this.state.streamerChatMessages.length === 0 ? 'bg-light' : ''}`}>
                                                                    <div name="private mobile" className="chat-containers" onTouchMove={this.scrollHandler} onWheel={this.scrollHandler}  style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id="container-private-chat"> 
                                                                        {this.state.streamerChatMessages.length === 0 ? <h5 className="text-center mt-4">What would you like to discuss?</h5> :
                                                                        this.renderStreamerChats()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div style={{padding: 0}} className="container-fluid align-self-end mt-3">
                                                    <div className="row">
                                                        <div className="col-1 px-1">
                                                            <i id="emoji-1" style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} className="mt-1 d-block mx-auto fas text-end fa-smile fa-2x"></i>
                                                            {this.props.redux.emojiPickerShown === 1 ?
                                                            <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                                            <div id="devices-stream">
                                                                <div className="d-flex mt-2 justify-content-end">
                                                                    {this.state.microphoneEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-4"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-4"></i>}
                                                                    {this.state.cameraEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-4 ms-2"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-4 ms-2"></i>}
                                                                </div>
                                                                {this.state.videoDevices.length > 1 && this.state.cameraEnabled ?
                                                                <div onClick={this.rotateCamera} id="camera-rotate"></div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div className="col-9">
                                                            <textarea onKeyPress={this.keyPressPrivateChat} onChange={this.privateChatChangeHandler} id="textarea-private-chat" placeholder={`Message to ${this.state.streamInfo.streamer}`} className="form-control"></textarea>
                                                        </div>
                                                        <div style={{padding: 0}} className="col-2">
                                                            {this.state.sendingPrivateChat ?
                                                            <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                                            <>
                                                                {this.state.streamerChatTimeout ? 
                                                                    <button disabled className="btn btn-success">Wait ({this.state.streamerChatTimeout}s)</button>:
                                                                    <>
                                                                        {this.state.streamerChatCharCount === 0 || this.state.streamerChatCharCount > 600 ?
                                                                        <button disabled className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send</button>:                                                            
                                                                        <button onClick={this.sendPrivateChat} className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send</button>}
                                                                    
                                                                    </>
                                                                }
                                                            </>
                                                            }
                                                            <p className={`mt-1 mb-0 ${this.state.streamerChatCharCount > 600 ? 'text-danger' : ''}`}>{this.state.streamerChatCharCount}/600</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </>}
                                    </>:
                                    <>
                                        {!this.props.redux.username ?
                                        <button className="btn btn-primary btn-lg d-block mx-auto align-self-center" onClick={() => this.createAccount(true)}><i className="fas fa-phone me-2"></i>Request to Call</button>:
                                        <button onClick={this.requestCall} className="btn btn-primary btn-lg d-block mx-auto align-self-center"><i className="fas fa-phone me-2"></i>Request to Call</button>}
                                    </>
                                    }
                                </div>
                            </div> : <></>}
                        </div>
                        {!this.state.publicChatShownMobile && typeof window !== 'undefined' && window.screen && window.screen.availWidth <= 768 ?
                        <div id="mobile-call-container" className="col-md-5 col-12 stream-column h-98">
                            <div style={{height: '100%'}} className="card d-flex flex-column">
                                <div className="card-header py-2 d-flex justify-content-between">
                                    <h5 style={{margin: 0}}>{this.state.streamInfo.streamer}</h5>
                                    {this.state.callRequested ? 
                                    <div className="d-flex show-lg">
                                        <span className="show-lg">{this.getCallStatus()}</span>
                                        <button onClick={this.disconnect} className="ms-2 btn btn-danger align-self-start d-block"><i className="fas fa-phone-slash me-2"></i>Disconnect</button>
                                    </div>  : <></>}
                                </div>
                                <div id="mobile-private-chat-container" className={`card-body d-flex flex-column ${this.state.callRequested ? '' : 'justify-content-center h-100'}`}>
                                    {this.state.callRequested ?
                                    <>
                                        {!this.state.peerOpened ? 
                                        <>
                                            <h5 className="text-center my-4">Connecting to Peer Server</h5>
                                            <LinearProgress />
                                        </> :
                                        <>
                                            {!this.state.devicesAllowed ? 
                                            <>
                                                <h5 className="text-center my-4">Waiting for Devices</h5>
                                                <LinearProgress />
                                            </> :
                                            <>
                                                <div className={`mb-2 px-2 fg-1`}>
                                                    <div className="container-fluid h-100 px-0">
                                                        <div className="row h-25">
                                                            <div className="col-4 h-100 px-0">
                                                                {this.state.cameraEnabled && this.state.callStatus !== 'success' ?
                                                                <div onClick={this.devicesModal} dangerouslySetInnerHTML={{
                                                                    __html: `
                                                                        <video class="d-block mx-auto cameras" style="margin: 0; padding: 0;" id="camera" autoplay muted playsinline></video>
                                                                    `
                                                                }}></div>
                                                                :
                                                                <div onClick={this.devicesModal} id="div-avatar" className="h-100" style={{margin: 0, padding: 0, background: `${this.state.callStatus === 'success' ? `url('${`/images/monkey_logo.png`}')` : `url('${`/avatars/${this.props.redux.avatar}`}')`}`}}></div>
                                                                }
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="d-flex">
                                                                    {this.state.microphoneEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-2"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-2"></i>}
                                                                    {this.state.cameraEnabled ? <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-2 ms-2"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-2 ms-2"></i>}
                                                                </div>
                                                                {this.state.videoDevices.length > 1 && this.state.cameraEnabled ?
                                                                <div onClick={this.rotateCamera} id="camera-rotate"></div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div className="row h-75">
                                                            <div className={`col-12 h-100 px-0 pt-1`}>
                                                                <div id="border-private-chat"  className={`h-100 border border-light chat-borders ${this.state.streamerChatMessages && this.state.streamerChatMessages.length === 0 ? 'bg-light' : ''}`}>
                                                                    <div name="private mobile" className="chat-containers" onTouchMove={this.scrollHandler} onWheel={this.scrollHandler}  style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id="container-private-chat"> 
                                                                        {this.state.streamerChatMessages && this.state.streamerChatMessages.length === 0 ? <h5 className="text-center mt-4">What would you like to discuss?</h5> :
                                                                        this.renderStreamerChats()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div style={{padding: 0}} className="container-fluid align-self-end">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i id="emoji-1" style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} className="mt-1 ms-1 fas fa-smile fa-2x"></i>
                                                            {this.props.redux.emojiPickerShown === 1 ?
                                                            <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                                            
                                                        </div>
                                                        <div className="col-10">
                                                            <textarea onKeyPress={this.keyPressPrivateChat} onChange={this.privateChatChangeHandler} id="textarea-private-chat" placeholder={`Message to ${this.state.streamInfo.streamer}`} className="form-control"></textarea>
                                                        </div>

                                                        <div className="show-sm col-12">
                                                            <div class="d-grid gap-2 mt-2">
                                                                {this.state.sendingPrivateChat ?
                                                                <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                                                <>
                                                                    {this.state.streamerChatTimeout ? 
                                                                        <button disabled className="btn btn-success">Wait ({this.state.streamerChatTimeout}s)</button>:
                                                                        <>
                                                                            {this.state.streamerChatCharCount === 0 || this.state.streamerChatTimeout > 600 ?
                                                                            <button disabled className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.streamerChatCharCount}/600)</button>:                                                            
                                                                            <button onClick={this.sendPrivateChat} className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.streamerChatCharCount}/600)</button>}
                                                                        
                                                                        </>
                                                                    }
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </>}
                                    </>:
                                    <>
                                        {!this.props.redux.username ?
                                        <button className="btn btn-primary btn-lg d-block mx-auto align-self-center" onClick={() => this.createAccount(true)}><i className="fas fa-phone me-2"></i>Request to Call</button>:
                                        <button onClick={this.requestCall} className="btn btn-primary btn-lg d-block mx-auto align-self-center"><i className="fas fa-phone me-2"></i>Request to Call</button>}
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                         : <></>}
                         
                        {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 || this.state.publicChatShownMobile ?
                        <div id="mobile-chat-container" className="col-md-5 col-12 stream-column h-98">
                                <div style={{height: '100%'}} className="card d-flex flex-column">
                                    <div className="card-header py-2"><h5 className="my-0">Public Chat</h5></div>
                                    <div className={`card-body d-flex flex-column d-flex flex-column ${typeof window !== 'undefined' && window.screen && window.screen.availWidth <= 768 ? 'p-2' : 'py-1'}`}>
                                        <div id="border-public-chat" className="mb-2 border border-light chat-borders px-2 fg-1">
                                            <div name="public chat name" onTouchMove={this.scrollHandler} onWheel={this.scrollHandler}  className="chat-containers" style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id="container-public-chat">
                                            
                                                {this.renderPublicChatMessages()}
                                            </div>
                                        </div>
                                        {!this.props.redux.username ?
                                        <button className="btn btn-primary btn-lg d-block mx-auto align-self-center" onClick={() => this.createAccount(false)}><i className="fas fa-comments me-2"></i>Join the Conversation</button>:
                                        <div style={{padding: 0}} className="container-fluid align-self-end">
                                            <div className="row">
                                                <div className="col-2 col-md-1">
                                                    <i id="emoji-2" style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} className="mt-1 fas fa-smile fa-2x"></i>
                                                    {this.props.redux.emojiPickerShown === 2 ?
                                                    <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                                </div>
                                                <div className="col-xxl-9 col-md-8 col-10">
                                                    <textarea onKeyPress={this.keyPressPublicChat} onChange={this.publicChatChangeHandler} id="textarea-public-chat" placeholder="Public Mesage" className="form-control"></textarea>
                                                </div>
                                                <div className="show-sm col-12">
                                                    <div class="d-grid gap-2 mt-2">
                                                        {this.state.sendingPublicChat ?
                                                        <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                                        <>
                                                            {this.state.publicChatTimeout ? 
                                                                <button disabled className="btn btn-success">Wait ({this.state.publicChatTimeout}s)</button>:
                                                                <>
                                                                    {this.state.publicChatCharCount === 0 || this.state.publicChatCharCount > 600 ?
                                                                    <button disabled className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.publicChatCharCount}/600)</button>:                                                            
                                                                    <button onClick={this.sendPublicChat} className="btn btn-success"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.publicChatCharCount}/600)</button>}
                                                                
                                                                </>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{padding: 0}} className="col-xxl-2 col-md-3 show-lg">
                                                    {this.state.sendingPublicChat ?
                                                    <button className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                                    <>
                                                        {this.state.publicChatTimeout ? 
                                                            <button disabled className="btn btn-primary">Wait ({this.state.publicChatTimeout}s)</button>:
                                                            <>
                                                                {this.state.publicChatCharCount === 0 || this.state.publicChatCharCount > 600 ?
                                                                <button disabled className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Send</button>:                                                            
                                                                <button onClick={this.sendPublicChat} className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Send</button>}
                                                            
                                                            </>
                                                        }
                                                    </>
                                                    }
                                                    <p style={{marginBottom: 0}} className={`mt-1 show-lg ${this.state.publicChatCharCount > 600 ? 'text-danger' : ''}`}>{this.state.publicChatCharCount}/600</p>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                
                        </div> : <></>}
                </div>
            </motion.div>
        );
    }
}

function StreamParent(props){
    const params = useParams();
    return <Stream redux={props} params={params}/>
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_poll, set_emoji_picker, purge_user})(StreamParent);