import React from 'react';
import { connect } from 'react-redux';

class StreamBigModal extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    /**
     * @param {Object} prevProps - Previous this.props object
     * 
     * Hide the modal if you are the only one left in the call (this.props.streams.length ===1)
     * Set srcObject of related <video> tags to their respective media stream objects
     */

    componentDidUpdate(prevProps){
        if (this.props.streams && this.props.streams.length === 1 && this.props.streams.length !== prevProps.streams.length) this.props.hideStreamModal();
        [].slice.call(document.getElementsByClassName('active-calls-modal')).forEach(e => {
            const owner = e.id.split('active-modal-')[1];

            if (owner === this.props.username){
                if (e.srcObject){
                } else {
                    let stream = this.props.myStream.clone();
                    stream.getAudioTracks().forEach(track => stream.removeTrack(track));
                    e.srcObject = stream;
                }
            } else {
                if (e.srcObject){
                } else {
                    e.srcObject = this.props.streams.find(caller => caller.viewer === owner).mediaStream;
                }
            }
        });
    }

    


    render(){
        return (
            <div class="modal fade" id="streamBigModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div style={{height: '85vh'}} class="modal-content d-flex flex-column">
                        <div class="modal-header">
                            <h5 class="modal-title">Call</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body fg-1">
                            <div className="container h-100">
                                <div className="row h-100">
                                    {this.props.streams && this.props.streams.length > 1 ? this.props.streams.map(call => {
                                        if (call.mediaStream.getVideoTracks && call.mediaStream.getVideoTracks().length){
                                            return (
                                                <div className="position-relative col-6 h-33 d-flex flex-column justify-content-center">
                                                    <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.viewer}</p>
                                                    <video id={`active-modal-${call.viewer}`} style={{maxHeight: '100%', maxWidth: '100%'}} autoPlay className="d-block mx-auto active-calls-modal" ></video>
                                                </div>
                                            ) 
                                        } else if (call.mediaStream.getAudioTracks && call.mediaStream.getAudioTracks().length){
                                            return (
                                                <div className="position-relative col-6 h-33">
                                                    <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.viewer}</p>
                                                    <div className=" d-flex flex-column justify-content-center" style={{maxHeight: '100%', height: '100%', maxWidth: '100%'}}>
                                                        {call.viewer !== this.props.username ? <audio className="active-calls-modal" id={`active-modal-${call.viewer}`} autoPlay></audio>: <></>}
                                                        <img className="d-block mx-auto" src={`/avatars/${call.avatar}`} style={{maxHeight: '100%', maxWidth: '100%'}}></img>
                                                    </div>
                                                </div>
                                            ); 
                                        } else return (
                                            <div className="position-relative col-6 h-33 d-flex flex-column justify-content-center">
                                                <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.viewer}</p>
                                                <img className="d-block mx-auto" src={`/avatars/${call.avatar}`} style={{maxHeight: '100%', maxWidth: '100%'}}></img>
                                            </div>
                                        ) 
                                    }) : <></>}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(StreamBigModal);