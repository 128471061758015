import { store } from '../index';
export const SET_HISTORY = 'SET_HISTORY';
export const ROUTE = 'SET_ROUTE';
export const SET_USER = 'SET_USER';
export const PURGE_USER = 'PURGE_USER';
export const SET_STREAM = 'SET_STREAM';
export const SET_CALLER_STREAM = 'SET_CALLER_STREAM';
export const TOGGLE_VIDEO_PREVIEW = 'TOGGLE_VIDEO_PREVIEW';
export const SET_STREAMER_DEVICES = 'SET_STREAMER_DEVICES';
export const SET_POLL = 'SET_POLL';
export const SET_EMOJI_PICKER = 'SET_EMOJI_PICKER';
export const ROTATE_CAMERA = 'ROTATE_CAMERA';
export const TOGGLE_CALLER_TONE = 'TOGGLE_CALLER_TONE';
export const SET_LOGIN_DESTINATION = 'SET_LOGIN_DESTINATION';

export const set_login_destination = destination => {
    return {
        type: SET_LOGIN_DESTINATION,
        data: destination
    }
}

export const toggle_caller_tone = e => {
    e.stopPropagation();
    return {
        type: TOGGLE_CALLER_TONE,
        data: e.target.checked
    }
}

export const rotate_camera = () => {
    return {
        type: ROTATE_CAMERA
    }
}

export const set_emoji_picker = index => {
    return {
        type: SET_EMOJI_PICKER,
        data: index
    }
}

export const set_streamer_devices = (stream, devices) => {
    return {
        type: SET_STREAMER_DEVICES,
        data: {
            stream: stream,
            devices: devices
        }
    }
}

export const toggle_video_preview = () => {
    return {
        type: TOGGLE_VIDEO_PREVIEW
    }
}

export const set_caller_stream = stream => {
    return {
        type: SET_CALLER_STREAM,
        data: stream
    }
}

export const purge_user = () => {
    return {
        type: PURGE_USER
    }
}

export const set_stream = user => {
    return {
        type: SET_STREAM,
        data: user
    }
}

export const set_user = userInfo => {
    return {
        type: SET_USER,
        data: userInfo
    }
}

export const set_poll = poll => {
    return {
        type: SET_POLL,
        data: poll
    }
}
