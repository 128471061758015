import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import NewAccountModal from '../../components/NewAccountModal';
import { set_user, set_streamer_devices, rotate_camera, toggle_caller_tone } from '../../redux/actions';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

let bootstrap;

class PreGame extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * loaded: Boolean indicating whether the page is loaded. TODO: Remove
             * submitting: Boolean indicating whether the stream details are in the process of being submitted
             * microphoneEnabled: Boolean indicating whether the user has enabled their microphone
             * cameraEnabled: Boolean indicating whether the user has enabled their camera
             * cameraWidth: Number indicating the width of the camera in pixels
             * cameraHeight: Number indicating the height of the camera in pixels
             * cameraWidthMobile: Same as cameraWidth, but for the mobile camera container
             * cameraHeightMobile: Same as cameraHeight, but for the mobile camera container
             * thumbnailName: String - Text hovering over the stream thumbnail. If a new file is selected, it will be the filename
             * thumbnailFile: false | File Object containing a file that the user has selected from their computer
             * thumbnail: String - Path to the user's thumbnail
             * newAccountModal: false | Bootstrap modal object containing new account form
             */
            loaded: true,
            submitting: false,
            microphoneEnabled: true,
            cameraEnabled: false,
            cameraHeight: 450,
            cameraWidth: 450,
            cameraHeightMobile: 0,
            cameraWidthMobile: 0,
            thumbnailName: 'Click to Change',
            thumbnailFile: false,
            thumbnail: (props.activeStream) ? `/thumbnails/${props.activeStream.thumbnail}` : '/thumbnails/monkey-dark.png',
            newAccountModal: false
        }
    }

    /**
     * Set Bootstrap window object
     * Set camera containers' width and height into state
     * 
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        this.setState({
            ...this.state,
            cameraHeight: document.getElementById('streamer-avatar') ? document.getElementById('streamer-avatar').clientHeight : 450,
            cameraWidth: document.getElementById('streamer-avatar') ? document.getElementById('streamer-avatar').clientWidth : 450,
            cameraHeightMobile: document.getElementById('streamer-avatar-mobile') ? document.getElementById('streamer-avatar-mobile').clientHeight : 0,
            cameraWidthMobile: document.getElementById('streamer-avatar-mobile') ? document.getElementById('streamer-avatar-mobile').clientWidth : 0
        }, () => this.getMediaDevices());
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * 
     * If height of any video containers changes, set those changes into state (TODO: Add window resize event to do this)
     * If camera index changes, reset media devices
     */
    componentDidUpdate(prevProps){
        if (document.getElementById('streamer-avatar') && document.getElementById('streamer-avatar').clientHeight !== this.state.cameraHeight) this.setState({
            ...this.state,
            cameraHeight: document.getElementById('streamer-avatar').clientHeight,
            cameraWidth: document.getElementById('streamer-avatar').clientWidth
        });
        if (document.getElementById('streamer-avatar-mobile') && document.getElementById('streamer-avatar-mobile').clientHeight !== this.state.cameraHeightMobile) this.setState({
            ...this.state,
            cameraHeightMobile: document.getElementById('streamer-avatar-mobile').clientHeight,
            cameraWidthMobile: document.getElementById('streamer-avatar-mobile').clientWidth
        });
        if (prevProps.cameraIndex !== this.props.cameraIndex) this.getMediaDevices();
    }

    /**
     * Fired when the user selects the microphone icon
     * Toggles the microphone, then resets the media devices
     * 
     */
    toggleMicrophone = () => this.setState({
        ...this.state,
        microphoneEnabled: !this.state.microphoneEnabled
    }, () => this.getMediaDevices());

    /**
     * Fired when the user selects the camera icon
     * Toggles the camera, then resets the media devices
     */
    toggleCamera = () => this.setState({
        ...this.state,
        cameraEnabled: !this.state.cameraEnabled
    }, () => this.getMediaDevices());

    /**
     * Grabs a combination of the user's camera/microphone depending on what the user's specifications
     * Sets the resulting stream into application state
     * If camera selected, pipe the stream to a <video> tag
     */
    getMediaDevices = () => {
        if (navigator.mediaDevices){
            navigator.mediaDevices.getUserMedia({
                audio: this.state.microphoneEnabled,
                video: this.state.cameraEnabled ? (this.props.videoDevices.length > 1 ? {
                    deviceId: this.props.videoDevices[this.props.cameraIndex]
                } : true) : false
            }).then(async mediaStream => {
                let devices = await navigator.mediaDevices.enumerateDevices();
                this.props.set_streamer_devices(mediaStream, devices.filter(d => d.kind === 'videoinput').map(v => v.deviceId));
                if (this.state.cameraEnabled){
                    [].slice.call(document.getElementsByClassName('streamer-video')).forEach(webCam => {
                        webCam.srcObject = this.props.streamerDevices;
                        webCam.play();
                    });
                } 
            }).catch(err => {
                console.log(err);
                this.props.set_streamer_devices([]);
                alert('Please give permission to use your input devices');
            });
        } else {
            this.props.history.push('/');
            alert("Unable to access camera and microphone. Please try again.");
        }
    }

    /**
     * Fired when the user attempts to submit their stream whilst not logged in
     * Validates the inputs, submits to the server, then resolves if valid
     */
    createTempUser = () => new Promise(async (resolve, reject) => {
        const captchaKey = await this.getRecaptcha();
        let submission = {
            displayName: document.getElementById('input-display-name').value,
            captchaKey: captchaKey
        }
        const fd = new FormData();
        fd.append('displayName', submission.displayName);
        fd.append('captchaKey', submission.captchaKey);
        if (submission.displayName.length < 4) reject("Your display name is too short. (Minimum 4 characters)");
        if (submission.displayName.length > 30) reject("Your display name is too long (Maximum 30 characters)");
        axios.post('/auth/create-temp-user', fd).then(res => {
            if (res.data.success){
                this.props.set_user(res.data.userInfo);
                return resolve();
            } else {
                this.setState({
                    ...this.state,
                    submitting: false
                }, () => {
                    alert(res.data.errorMessage);
                    return reject();
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                ...this.state,
                submitting: false
            }, () => {
                alert('An error occurred. Please try again later');
                return reject();
            });
        });
    });

    // Probably does nothing, likely a terrible fix for a bug. TODO: Remove
    reload = () => this.setState({
        ...this.state,
        loaded: false
    }, () => this.setState({
        ...this.state,
        loaded: true
    }));

    /**
     * Fired when the user clicks the Submit button
     * If user is not logged in, create temporary user
     * Validate inputs
     * Send to server
     * Route to the Active page if all is valid
     */
    submit = () => {
        if (!this.state.submitting){
            this.setState({
                ...this.state,
                submitting: true
            }, async () => {
                try {
                    if (!this.props.username) await this.createTempUser();
                    const displayName = document.getElementById('input-display-name').value;
                    const title = document.getElementById('input-stream-title').value;
                    const url = document.getElementById('input-jewtube').value;
                    if (!displayName) throw 'Please enter a display name';
                    if (!title) throw 'Please enter a title';
                    if (title.length > 100) throw 'Please enter a shorter title (Max: 100 chars)';
                    const fd = new FormData();
                    if (this.state.thumbnailFile) fd.append('thumbnail', this.state.thumbnailFile, this.state.thumbnailName);
                    fd.append('title', title);
                    fd.append('url', url);
                    fd.append('private', !document.getElementById('privateCheck').checked);
                    fd.append('displayName', displayName);
                    axios.post('/stream/set-stream', fd).then(res => {
                        if (res.data.success){
                            this.props.set_user(res.data.userInfo);
                            this.props.history.push('/active');
                        } else {
                            this.setState({
                                ...this.state,
                                submitting: false
                            }, () => alert(res.data.error));
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            ...this.state,
                            submitting: false
                        }, () => alert('An error occurred. Please try again later.'));
                    });
                } catch(err){
                    console.log(err);
                    this.setState({
                        ...this.state,
                        submitting: false
                    }, () => alert(err));
                }
            });
        }
    }

    /**
   * 
   * @returns Captcha key generated from the executeRecaptcha function passed from the provider
   */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.googleReCaptchaProps && this.props.googleReCaptchaProps.executeRecaptcha) this.props.googleReCaptchaProps.executeRecaptcha().then(resolve).catch(err => {
            console.log('error', err);
            return reject();
        });
        else setTimeout(async () => {
            try {
                const captchaKey = await this.getRecaptcha();
                resolve(captchaKey);
            } catch(err){
                console.log(err);
                alert('Captcha error. Please try again later.');
                reject();
            }
        }, 1000);
    });

    /**
     * Fired when the user clicks the thumbnail to select a new one
     * Creates a virtual file input
     * Adds a click event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     */
    selectThumbnail = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        input.onchange = e => {
            let file = e.target.files[0];
            if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'].indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    console.log(file)
                    this.setState({
                        ...this.state,
                        thumbnailName: e.target.files[0].name,
                        thumbnailFile: e.target.files[0],
                        thumbnail: URL.createObjectURL(e.target.files[0])
                    });
                } else {
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
            input.remove();
        }
        document.body.appendChild(input);
        input.click();
    }

    /**
     * Fired when the user clicks the Create Account button
     * Sets the New Account modal into state, and shows it
     * 
     */
    createAccount = () => this.setState({
        ...this.state,
        newAccountModal: new bootstrap.Modal(document.getElementById('newAccountModal'))
    }, () => this.state.newAccountModal.show());

    render(){
        return (
            <motion.div className="container h-100 d-flex flex-column" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <NewAccountModal getRecaptcha={this.getRecaptcha} reload={this.reload} newAccountModal={this.state.newAccountModal} />
                {!this.props.username ?
                <>
                    <div className="show-lg">
                        <div className="d-flex align-items-center justify-content-between">
                            <button onClick={this.createAccount} className="btn btn-dark d-block"><i className="fas fa-user-plus me-2"></i>Create Account</button>
                            <h1 className="text-center mx-auto mt-5 display-6">Call Details</h1>
                            <button style={{opacity: 0, cursor: 'default'}} className="btn btn-dark d-block"><i className="fas fa-user-plus me-2"></i>Create Account</button>
                        </div>
                    </div>
                    <div className="show-sm">
                        <div className="d-flex align-items-center justify-content-between">
                            <button onClick={this.createAccount} className="btn btn-lg btn-dark d-block"><i className="fas fa-user-plus"></i></button>
                            <h4 className="text-center mx-auto mt-5 display-5">Call Details</h4>
                            <button style={{opacity: 0, cursor: 'default'}} className="btn btn-lg btn-dark d-block"><i className="fas fa-user-plus"></i></button>
                        </div>
                    </div>
                </>
                 :
                <h1 className="text-center mx-auto mt-5 display-5">Call Details</h1>}
                <hr></hr>
                {this.state.loaded ?
                <div className="row mt-2 fg-1">
                    <div className="col-md-4 col-12 mb-3">
                        <h5>Display Name</h5>
                        <input defaultValue={this.props.displayName} id="input-display-name" placeholder="The name others will see" type="text" className="form-control" aria-label="Display name" aria-describedby="inputGroup-sizing-lg"></input>
                        <h5 className="mt-2">Title</h5>
                        <input defaultValue={this.props.activeStream ? this.props.activeStream.title : ''} id="input-stream-title" placeholder="Enter call title" type="text" className="form-control" aria-label="Stream title" aria-describedby="inputGroup-sizing-lg"></input>
                        <h5 className="mt-2">Thumbnail (Optional)</h5>
                        <p className="mb-1" onClick={this.selectThumbnail} style={{cursor: 'pointer'}}>{this.state.thumbnailName}</p>
                        <div style={{cursor: 'pointer', height: '9rem', width: '9rem', maxWidth: '95%'}} onClick={this.selectThumbnail} className="border border-dark p-3 d-flex justify-content-center align-items-center">
                            <img className="d-block" style={{maxWidth: '100%', maxHeight: '100%'}} alt="blank avatar" src={this.state.thumbnail}></img>
                        </div>
                        <h6 className="mt-2">Stream URL (Youtube or Twitch - Optional)</h6>
                        <input defaultValue={this.props.activeStream ? this.props.activeStream.url : ''} id="input-jewtube" placeholder="Enter stream link" type="text" className="form-control" aria-label="Jewtube-link" aria-describedby="inputGroup-sizing-lg"></input>
                        {this.state.submitting ?
                        <button disabled className="btn btn-primary btn-lg mt-4 show-lg"><span className="spinner-border spinner-border-sm me-2"></span>Please wait</button>:
                        <button onClick={this.submit} className="btn btn-primary btn-lg mt-4 show-lg"><i className="fas fa-paper-plane me-2"></i>Submit</button>
                        }
                    </div>
                    <div className="col-md-8 col-12 h-100">
                        <div style={{height: '90%'}} className="card d-flex flex-column">
                            <div className="show-lg">
                                <div className="card-header d-flex justify-content-between">
                                    <h5>Mic {'&'} Video Settings</h5>
                                    <div>
                                        {this.props.streamerDevices.getAudioTracks && this.props.streamerDevices.getAudioTracks().length ? 
                                        <p className="text-success my-1 text-end">Microphone Good</p> :
                                        <p className="text-danger my-1 text-end">Microphone Not In Use</p>}
                                        {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                        <p className="text-success my-1 text-end">Camera Good</p> :
                                        <p className="text-danger my-1 text-end">Camera Not In Use</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-header show-sm">
                                <h5>Mic {'&'} Video Settings</h5>
                                <div>
                                    {this.props.streamerDevices.getAudioTracks && this.props.streamerDevices.getAudioTracks().length ? 
                                    <p className="text-success my-1">Microphone Good</p> :
                                    <p className="text-danger my-1">Microphone Not In Use</p>}
                                    {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                    <p className="text-success my-1">Camera Good</p> :
                                    <p className="text-danger my-1">Camera Not In Use</p>}
                                </div>
                            </div>
                            <div className="card-body container h-100 d-flex flex-column">
                                <div className="row fg-1">
                                    <div className="col-9 h-100 show-lg">
                                        {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                        <div dangerouslySetInnerHTML={{
                                            __html: `
                                                <video class="d-block streamer-video" style="margin: 0 auto; padding: 0; max-height:${this.state.cameraHeight}px; max-width:${this.state.cameraWidth}px;" autoplay muted playsinline></video>
                                            `
                                        }}></div> :
                                        <div id="streamer-avatar" className="mt-2 h-100 w-100" style={{backgroundImage: `url("/avatars/${this.props.avatar}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain'}}></div>}
                                    </div>
                                    <div className="col-9 h-100 show-sm">
                                        {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                        <div dangerouslySetInnerHTML={{
                                            __html: `
                                                <video autoplay muted playsinline class="streamer-video" style="position: absolute; max-height:${this.state.cameraHeightMobile}px; max-width:${this.state.cameraWidthMobile}px; "></video>
                                            `
                                        }} className="h-100 w-100">

                                        </div> :
                                        <div id="streamer-avatar-mobile" className="h-100 w-100" style={{backgroundImage: `url("/avatars/${this.props.avatar}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain'}}></div>}
                                    </div>
                                    <div className="col-3 ">
                                        <div className="d-flex justify-content-end">
                                            {this.state.microphoneEnabled ? 
                                            <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-4"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-4"></i>}
                                            {this.state.cameraEnabled ? 
                                            <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-4 ms-3"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-4 ms-3"></i>}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {this.props.videoDevices && this.props.videoDevices.length > 1 && this.state.cameraEnabled ?
                                            <div onClick={this.props.rotate_camera} id="camera-rotate"></div> : 
                                            <div style={{opacity: 0, cursor: 'default'}} id="camera-rotate"></div>}
                                        </div>
                                        
                                        <div className="d-flex justify-content-end">
                                            <div className="form-check">
                                                <input onChange={this.props.toggle_caller_tone} className="form-check-input" type="checkbox" value="" id="newCallerTone" defaultChecked={this.props.newCallerTone}></input>
                                                <label className="form-check-label" for="newCallerTone">
                                                    Call Tone
                                                </label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="form-check">
                                                <input defaultChecked className="form-check-input" type="checkbox" value="" id="privateCheck"></input>
                                                <label className="form-check-label" for="privateCheck">
                                                    Public Call
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2">
                                    {this.state.submitting ?
                                    <button disabled className="btn btn-primary mt-4 show-sm"><span className="spinner-border spinner-border-sm me-2"></span>Please wait</button>:
                                    <button onClick={this.submit} className="btn btn-primary mt-4 show-sm"><i className="fas fa-paper-plane me-2"></i>Submit</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="show-sm my-2 py-2"></div>
                    </div>
                </div> : 
                <div className="mt-5 d-flex justify-content-center">
                    <div className="spinner-grow spinner-grow-lg"></div>
                </div>}
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_user, set_streamer_devices, rotate_camera, toggle_caller_tone })(withGoogleReCaptcha(PreGame));