import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { set_caller_stream, set_streamer_devices, set_poll, set_emoji_picker } from '../../redux/actions';
import { io } from 'socket.io-client';
import SettingsModal from './SettingsModal';
import CallerChat from './CallerChat';
import DisconnectAlert from '../../components/DisconnectAlert';
import CallerChat_Extra from './CallerChat_Extra';
import StartPollModal from './StartPollModal';
import ViewPollModal from './ViewPollModal';
import CallerChat_mobile from './CallerChat_mobile';
import StreamBigModal from '../streamFiles/StreamBigModal';

let bootstrap;
let Peer;
let $;
let twemoji;

class StreamView extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * activeCalls: Array containing list of all active calls 
             * callRequests: Array containing list of all call requests
             * sendingPublicChat: Boolean indicating whether a public chat is in the process of being sent to and processed on the server
             * chatSocket: false | Socket.io socket object
             * publicChatCharCount: Number indicating the number of characters that have been typed into the public chat
             * publicChatMessages: Array containing a list of all of the public chat messages
             * streamDetailsModal: false | Bootstrap modal object containing the stream details and settings modal
             * forceCallerChatBottom: Boolean indicating whether the chat should scroll to the bottom when a new message arrives
             * publicChatTimeout: Number indicating the number of seconds remaining in timeout for the public chat
             * publicChatWaitInterval: false | Interval which decrements this.state.publicChatTimeout
             * viewCountInterval: false | Interval which pings the server for an updated view count
             * viewCount: Number - viewers in the room
             * callerPeer: false | PeerJS peer instance used to communicate directly with callers
             * callPanelHeight: Number indicating the height of the call panel in pixels
             * callPanelWidth: Number indicating the width of the call panel in pixels
             * callersSelected: Array containing a list of all of the callers whose chats are open
             * disconnectAlerts: Array containing a list of Bootstrap Toast alerts about who has recently disconnected
             * callScreenMode: Boolean indicating whether the user has selected "Call Screen Mode" - which will replace the public chat with 4 caller chats
             * startPollModal: false | Bootstrap Modal object containing the New Poll form
             * viewPollModal: false | Bootstrap Modal object containing an active poll
             * charCounts: Object containing the character counts of each of the chats
             * scrollTops: Object containing the scroll configurations of each chat. If true, chat scroll will remain at the bottom when new messages arrive
             * publicChatHeight: Number indicating the height of the public chat on desktop screens. 
             * publicChatHeightMobile: Number indicating the height in pixels of the public chat on mobile screens
             * callerChatHeight: Number indicating the height in pixels of the caller chats
             * menuSelectionMobile: "" | "chat" | "requests" | "actions" - The menu selected on mobile screens
             * callRequestsHeight: Number - Height in pixels of the call requests container
             * streamHeight: Number - Height in pixels of the main stream video container
             * streamBigModal: false | Bootstrap Modal object containing the fullscreen 
             * copyPopover: false | Bootstrap Popover instance that contains a link to the stream
             * copyTooltip: false | Bootstrap Tooltip instance that appears when a user hovers over the "Copy" button for the stream link
             * videoStream: Boolean indicating whether the user has submitted a youtube/twitch stream to be played
             */
            activeCalls: [],
            callRequests: [],
            sendingPublicChat: false,
            chatSocket: false,
            publicChatCharCount: 0,
            publicChatMessages: [],
            streamDetailsModal: false,
            forceCallerChatBottom: true,
            publicChatTimeout: 0,
            publicChatWaitInterval: false,
            viewCountInterval: false,
            viewCount: 0,
            callerPeer: false,
            callPanelHeight: 0,
            callPanelWidth: 0,
            callersSelected: [],
            disconnectAlerts: [],
            callScreenMode: false,
            startPollModal: false,
            viewPollModal: false,
            viewPollKey: false,
            charCounts: {
                public: 0,
                private_1: 0,
                private_2: 0,
                private_3: 0,
                private_4: 0,
                private_5: 0
            },
            scrollTops: {
                public: true,
                public_mobile: true,
                private_1: true,
                private_2: true,
                private_3: true,
                private_4: true,
                private_5: true
            },
            publicChatHeight: 0,
            publicChatHeightMobile: 0,
            callerChatHeight: 0,
            menuSelectionMobile: '',
            callRequestsHeight: 0,
            streamHeight: 0,
            streamBigModal: false,
            copyPopover: false,
            copyTooltip: '',
            videoStream: true,
            peerOpened: false,
            devicesAllowed: false
        }

    }

    /**
     * Set window variables
     * Add window.resize event -> this.resize
     * If mobile, set Call Screen Mode to false
     * If user is not logged in, redirect to previous screen
     * If there are no media devices, redirect to previous screen
     * Grab a copy of the stream and remove the audio devices (React <video> muted tag is broken - long time known bug)
     * Initialize socket and peer
     * 
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        Peer = window.Peer;
        $ = window.$;
        twemoji = window.twemoji;
        let video = document.getElementById('video-frame');
        video.style.height = `${video.clientHeight}px`;
        window.addEventListener('resize', this.resize);
        if (window && window.screen && window.screen.availWidth <= 768 && this.state.callScreenMode) this.toggleCallScreenMode();
        if (!this.props.username) this.props.history.push('/create');
        else {
            if (this.props.streamerDevices.length === 0){
                this.props.history.push('/create');
            } else {
                let stream = this.props.streamerDevices.clone();
                stream.getAudioTracks().forEach(track => stream.removeTrack(track));
                this.setState({
                    ...this.state,
                    chatSocket: io(process.env.REACT_APP_ROOT),
                    callPanelHeight: document.querySelector('#video-frame').clientHeight,
                    callPanelWidth: document.querySelector('#video-frame').clientWidth,
                    peer: new Peer(undefined, {
                        host: process.env.REACT_APP_PEER_HOST,
                        port: process.env.REACT_APP_PEER_PORT,
                        secure: true
                    }),
                    streamHeight: video.clientHeight
                }, () => {
        
                    // Peer Start
        
                    this.state.peer.on('open', id => this.setState({
                        ...this.state,
                        peerOpened: true
                    }, () => console.log('open', id)));
        
                    // When first called, answer without a stream. Add caller's stream into state
                    this.state.peer.on('call', call => {
                        call.answer();
                        
                        call.on('stream', stream => {
                            this.setState({
                                ...this.state,
                                callersSelected: this.state.callersSelected.map(caller => {
                                    if (caller.peerID === call.peer) return {
                                        ...caller,
                                        mediaStream: stream
                                    } 
                                    else return caller
                                }),
                                callRequests: this.state.callRequests.map(request => {
                                    if (request.peerID === call.peer) return {
                                        ...request,
                                        mediaStream: stream
                                    }
                                    else return request
                                })
                            });
                            
                        });
                    });
        
                    // When caller has been approved, call with stream (We already have theirs)
                    this.state.chatSocket.on('new-user-connection', peerID => {
                        if (peerID !== this.state.peer._id){
                            const call = this.state.peer.call(peerID, this.props.streamerDevices);
        
                            call.on('close', () => {
                                console.log('closed', peerID);
                            });
                        }
                    });
        
                    // Peer End

                    // Error handling for when the socket errors out on the server. TODO: Don't nuke everything over tiny errors
                    this.state.chatSocket.on('socket-error', () => {
                        if (this.props.username){
                            alert('An error occurred with the chat socket and the application needed to be closed.');
                            this.props.history.push('/create');
                        }
                    });
        
                    // Public chats
                    this.state.chatSocket.on('publicMessage', message => {
                        let chatContainer = document.querySelector('#container-public-chat');
                        let chatContainerMobile = document.querySelector('#container-public-chat-mobile');
                        this.setState({
                            ...this.state,
                            publicChatMessages: [
                                ...this.state.publicChatMessages,
                                message
                            ]
                        }, () => setTimeout(() => {
                            if (this.state.scrollTops.public === true && chatContainer){
                                let newChatContainer = document.querySelector('#container-public-chat');
                                newChatContainer.scrollTop = newChatContainer.scrollHeight + 5;
                            }
                            if (this.state.scrollTops.public_mobile === true && chatContainerMobile){
                                let newChatContainerMobile = document.querySelector('#container-public-chat-mobile');
                                newChatContainerMobile.scrollTop = newChatContainerMobile.scrollHeight + 5;
                            }
                        }, 100));
                        [].slice.call(document.getElementsByClassName('chat-containers')).forEach(e => e.style.height = `${e.parentElement.clientHeight}px`);
                    });


                    this.state.chatSocket.emit('startStream');

                    // Someone has requested to call
                    this.state.chatSocket.on('request', viewer => {
                        this.state.chatSocket.emit('join-request-streamer', viewer);
                    });
                    
                    // Likely unused. 
                    this.state.chatSocket.on('streamerConnectRequest', data => this.state.callerPeer.signal(data.signal));
                    this.state.chatSocket.on('renegotiate', data => this.state.callerPeer.signal(data.signal));
                    this.state.chatSocket.on('transceiverRequest', data => {
                        this.state.callerPeer.signal(data.signal);
                    });

                    /**
                     * Caller has disconnected
                     * Remove them and send out alerts
                     */
                    this.state.chatSocket.on('viewerDisconnect', viewer => {
                        const alertDisconnect = this.state.callersSelected.find(c => c.viewer === viewer)
                        this.setState({
                            ...this.state,
                            callersSelected: this.state.callersSelected.filter(c => c.viewer !== viewer),
                            callRequests: this.state.callRequests.filter(c => c.viewer !== viewer),
                            disconnectAlerts: this.state.disconnectAlerts.filter(a => a.viewer !== viewer)
                        }, () => this.setState({
                            ...this.state,
                            disconnectAlerts: alertDisconnect ? [
                                ...this.state.disconnectAlerts,
                                {
                                    viewer: viewer,
                                    alertNode: <DisconnectAlert viewer={viewer} name={alertDisconnect.name}/>
                                }
                            ] : this.state.disconnectAlerts
                        }, () => {
                            if (alertDisconnect) new bootstrap.Toast(document.getElementById(`toast_${viewer}`)).show()
                        }));
                    });

                    /**
                     * Caller has sent a private message
                     * Get index of chat box
                     * Insert message into state
                     * Scroll to the bottom of the chat if applicable
                     */
                    this.state.chatSocket.on('streamerMessage', details => {
                        let found = false;
                        let chatIndex = 0;
                        this.state.callersSelected.forEach((caller, index) => {
                            if (caller.viewer === details.viewer){
                                chatIndex = index;
                            }
                        });
                        [].slice.call(document.getElementsByClassName('chat-containers')).forEach(e => e.style.height = `${e.parentElement.clientHeight}px`);
                        this.setState({
                            ...this.state,
                            callRequests: [
                                ...this.state.callRequests.map((r, index) => {
                                if (r.viewer === details.viewer){
                                    found = true;
                                    return {
                                        ...r,
                                        text: details.message,
                                        messages: [
                                            ...r.messages,
                                            details
                                        ],
                                        unreadMessages: this.state.callersSelected.filter(c => c.viewer === r.viewer).length === 0,
                                        peerID: details.peerID
                                    }
                                } else return r;
                                }), found ? '' : {
                                    name: details.displayName,
                                    viewer: details.viewer,
                                    avatar: details.avatar,
                                    text: details.message,
                                    messages: [details],
                                    callAccepted: false,
                                    unreadMessages: true,
                                    mediaStream: '',
                                    peerID: details.peerID
                                }
                            ].filter(r => r !== '')
                            
                        }, () => {
                            if (!found && this.props.newCallerTone) document.getElementById('call-notification').play();
                            switch(chatIndex){
                                case 0:
                                    if (this.state.scrollTops.private_1) {
                                        let newChatContainer = document.getElementById('container-caller-chat');
                                        if (newChatContainer) setTimeout(() =>  newChatContainer.scrollTop = newChatContainer.scrollHeight + 5, 100);
                                    }
                                default:
                                    if (this.state.scrollTops[`private_${chatIndex + 1}`]){
                                        let newChatContainer = document.getElementById(`container-caller-chat-${chatIndex + 2}`);
                                        if (newChatContainer) setTimeout(() =>  newChatContainer.scrollTop = newChatContainer.scrollHeight + 5, 100);
                                    }
                            }
                            this.setState({
                                ...this.state,
                                callersSelected: this.state.callersSelected.map(caller => {
                                    if (caller.viewer !== details.viewer) return caller;
                                    else return {
                                        ...caller,
                                        messages: [
                                            ...caller.messages,
                                            details
                                        ],
                                        peerID: details.peerID
                                    }
                                })
                            })
                        });
                    });


                    this.setState({
                        ...this.state,
                        viewCountInterval:setInterval(() => {
                            this.state.chatSocket.emit('views', this.props.username);
                        }, 2000)
                    }, () => {
                        this.toggleCallScreenMode(); // ???
                        this.state.chatSocket.on('viewCount', (pollData, streamInfo) => this.setState({
                            ...this.state,
                            viewCount: streamInfo.viewCount
                        }, () => this.props.set_poll(pollData)))
                        this.state.chatSocket.emit('views', this.props.username)
                    });
                });
            }
        } 
        
    }

    /**
     * 
     * @param {Object} prevProps Previous this.props object
     * @param {*} prevState Previous this.state Object
     * 
     * If logged in:
     * 
     * 
     * 
     * 
     * 
     */
    componentDidUpdate(prevProps, prevState){
        
        if (this.props.username){
            // Fix borders - TODO: GET RID OF THIS and all other non-css borders, replace with flex-grow-1
            if (document.getElementById('call-requests-border') && prevState.callRequestsHeight !== document.getElementById('call-requests-border').clientHeight){
                let border = document.getElementById('call-requests-border').clientHeight;
                document.getElementById('call-requests-container').style.height = 0;
                this.setState({
                    ...this.state,
                    callRequestsHeight: border
                }, () => {
                    document.getElementById('call-requests-container').style.height = `${border}px`
                });
            }

            // Set call frame dimensions if different from video dimensions
            let videoFrame = document.querySelector('#video-frame');
            if (videoFrame && videoFrame.clientHeight !== this.state.callPanelHeight) this.setState({
                ...this.state,
                callPanelHeight: videoFrame.clientHeight,
                callPanelWidth: videoFrame.clientWidth
            });

            // If new emoji picker has popped up, run the disMojiPicker parsing function
            if (this.props.emojiPickerShown !== -1 && prevProps.emojiPickerShown !== this.props.emojiPickerShown && document.getElementById('emojis')){
                $('#emojis').disMojiPicker();
                twemoji.parse(document.body);
                $('#emojis').picker(emoji => this.pickEmoji(emoji));
                let emojiDiv = document.getElementById('emojis');
                emojiDiv.style.bottom = `${emojiDiv.parentElement.clientHeight * 1.25}px`;
            } else twemoji.parse(document.body);

            // If there are new streams with no <audio> or <video> source, add sources
            [].slice.call(document.getElementsByClassName('active-calls')).forEach(e => {
                const owner = e.id.split('active-')[1];
                if (owner === this.props.username){
                    if (e.srcObject){
                    } else {
                        let stream = this.props.streamerDevices.clone();
                        stream.getAudioTracks().forEach(track => stream.removeTrack(track));
                        e.srcObject = stream;
                    }
                } else {
                    if (e.srcObject){
                    } else {
                        e.srcObject = this.state.callersSelected.find(caller => caller.viewer === owner).mediaStream;
                    }
                }
            });

            // If devices have been changed, send out those changes to other peers
            if (prevProps.resetDevices !== this.props.resetDevices) this.state.callRequests.filter(c => c.callAccepted === true).forEach(caller => this.state.peer.call(caller.peerID, this.props.streamerDevices, {
                metadata: {
                    avatar: this.props.avatar,
                    viewer: this.props.username,
                    displayName: this.props.displayName
                }
            }));
        } 
        if (this.state.videoStream && this.props.activeStream.provider === 'default') this.setState({
            ...this.state,
            videoStream: false
        });
        else if (!this.state.videoStream && this.props.activeStream.provider !== 'default') this.setState({
            ...this.state,
            videoStream: true
        });
    }

    /**
     * Clear intervals and disconnect the chat socket
     */
    componentWillUnmount(){
        clearInterval(this.state.publicChatWaitInterval);
        clearInterval(this.state.viewCountInterval);
        if(this.state.chatSocket.disconnect) this.state.chatSocket.disconnect(this.props.username);
    }

    // Should not have to do any height resizing here
    resize = () => [].slice.call(document.getElementsByClassName('chat-containers')).forEach(e => {
        e.style.scrollBehavior = 'auto';
        e.style.height = '0px';
        setTimeout(() => {
            e.style.height = `${e.parentElement.clientHeight}px`;
            e.scrollTop = e.scrollHeight + 5;
            e.style.scrollBehavior = 'smooth';
        }, 200);
    });


    /**
     * Hit when the user accepts a call
     * 
     * @param {Object} caller - state.callRequests object
     * 
     * Flag the call as accepted in state
     * Call viewer with stream (already have theirs)
     */
    acceptCall = caller => {
        this.setState({
            ...this.state,
            callRequests: this.state.callRequests.map(c => {
                
                if (c.viewer !== caller.viewer) return c;
                else return {
                    ...c,
                    callAccepted: true
                }
            }),
            callersSelected: this.state.callersSelected.map(c => {
                if (c.viewer === caller.viewer) {
                    return {
                        ...c,
                        callAccepted: true
                    }
                } 
                else {
                    return c
                }
            })
        }, () => {
            this.state.peer.call(caller.peerID, this.props.streamerDevices, {
                metadata: {
                    avatar: this.props.avatar,
                    viewer: this.props.username,
                    displayName: this.props.displayName
                }
            });
            this.state.chatSocket.emit('callAccept', caller.viewer, this.state.callRequests.filter(c => c.callAccepted === true));
        });
    } 


    /**
     * 
     * @param {Object} caller state.callRequests object
     * Flag that call as no longer accepted
     * Signal to the caller via socket that the call has been dropped
     */
    dropCall = caller => this.setState({
        ...this.state,
        callRequests: this.state.callRequests.map(c => {
            if (c.viewer !== caller.viewer) return c;
            else return {
                ...c,
                callAccepted: false
            }
        }),
        callersSelected: this.state.callersSelected.map(c => {
            if (c.viewer === caller.viewer) return {
                ...c,
                callAccepted: false
            }
            else return c;
        })
    }, () => {
        this.state.chatSocket.emit('callDrop', caller.viewer);
    });


    /**
     * TODO: Make this its own component
     * 
     * Renders the calls in a row
     */
    renderActiveCalls = () => {

        return [
            {
                mediaStream: this.props.streamerDevices,
                avatar: this.props.avatar,
                viewer: this.props.username,
                name: this.props.displayName
            },
            ...this.state.callRequests.filter(c => c.callAccepted === true)
        ].map(call => {
            if (call.mediaStream.getVideoTracks && call.mediaStream.getVideoTracks().length){
                return (
                    <div key={call.viewer} title={call.viewer} className="position-relative">
                        <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.name}</p>
                        <video id={`active-${call.viewer}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}} autoPlay className="d-block active-calls" ></video>
                    </div>
                ) 
            } else if (call.mediaStream.getAudioTracks && call.mediaStream.getAudioTracks().length){
                return (
                    <div key={call.viewer} title={call.viewer} className="position-relative">
                        <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.name}</p>
                        <div style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}}>
                            {call.viewer !== this.props.username ? <audio className="active-calls" id={`active-${call.viewer}`} autoPlay></audio>: <></>}
                            <img className="d-block" src={`/avatars/${call.avatar}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}}></img>
                        </div>
                    </div>
                ); 
            } else return (
                <div key={call.viewer} title={call.viewer} className="position-relative">
                    <p className="position-absolute text-oa bg-oa-dk m-0 p-1" style={{zIndex: '20', bottom: '0', left: '5px'}}>{call.name}</p>
                    <img className="d-block" src={`/avatars/${call.avatar}`} style={{maxHeight: `${this.state.callPanelHeight - 2}px`, height: `${this.state.callPanelHeight - 2}px`, maxWidth: `${this.state.callPanelHeight - 2}px`}}></img>
                </div>
            ); 
        });
    }

    /**
     * Decrement the public chat cooldown by 1
     */
    publicChatCountdown = () => this.setState({
        ...this.state,
        publicChatTimeout: this.state.publicChatTimeout - 1
    }, () => {
        if (this.state.publicChatTimeout === 0) clearInterval(this.state.publicChatWaitInterval);
    })

    /**
     * 
     * @param {Event Object} e  - Standard JavaScript event object
     * 
     * Send public chat, go on 3 second timeout
     */
    sendPublicChat = e => {
        if (e && e !== undefined) e.stopPropagation();
        if (!this.state.sendingPublicChat && document.querySelector('#textarea-public-chat').value !== '' && this.state.publicChatTimeout === 0 && this.state.publicChatCharCount < 601){
            this.setState({
                ...this.state,
                sendingPublicChat: true
            }, () => {
                try {
                    this.state.chatSocket.emit('publicChat', document.querySelector('#textarea-public-chat').value, this.props.displayName, this.props.avatar, this.props.username);
                    this.setState({
                        ...this.state,
                        sendingPublicChat: false,
                        charCounts: {
                            ...this.state.charCounts,
                            public: 0
                        },
                        publicChatTimeout: 3,
                        publicChatWaitInterval: setInterval(this.publicChatCountdown, 1000)
                    }, () => setTimeout(() => this.setState({
                        ...this.state,
                        charCounts: {
                            ...this.state.charCounts,
                            public: 0
                        }
                    }, () =>  document.querySelector('#textarea-public-chat').value = '')), 200);
                } catch(err){
                    console.log(err);
                    this.setState({
                        ...this.state,
                        sendingPublicChat: false
                    });
                }
            });
        }
    }

    /**
     * 
     * @param {Object} emoji - Dismoji emoji object
     * 
     * Insert emoji into proper text box 
     * Increment character count in state
     * Close emoji picker
     */
    pickEmoji = (emoji) => {
        let input;
        switch(this.props.emojiPickerShown){
            case 1:
                input = document.getElementById('textarea-public-chat');
                input.value = input.value + emoji;
                this.setState({
                    ...this.state,
                    charCounts: {
                        ...this.state.charCounts,
                        public: this.state.charCounts.public + 2
                    }
                }, () => {
                    this.props.set_emoji_picker(-1);
                    document.getElementById('textarea-public-chat').focus();
                });
                break;
            case 2:
                input = document.getElementById('textarea-caller-chat');
                input.value = input.value + emoji;
                this.setState({
                    ...this.state,
                    charCounts: {
                        ...this.state.charCounts,
                        private_1: this.state.charCounts.private_1 + 2
                    }
                }, () => {
                    this.props.set_emoji_picker(-1);
                    document.getElementById('textarea-caller-chat').focus();
                });
                break;
            default:
                input = document.getElementById(`textarea-caller-chat-${this.props.emojiPickerShown - 1}`);
                if (input){
                    input.value = input.value + emoji;
                    this.setState({
                        ...this.state,
                        charCounts: {
                            ...this.state.charCounts,
                            [`private_${this.props.emojiPickerShown - 1}`]: this.state.charCounts.private_1 + 2
                        }
                    }, () => {
                        this.props.set_emoji_picker(-1);
                        document.getElementById(`textarea-caller-chat-${this.props.emojiPickerShown - 1}`).focus();
                    });
                }
        }
    }

    /**
     * 
     * @param {String} string Most recent message from potential caller
     * @returns First 100 characters of string only
     */
    callRequestString = string => {
        if (string.length <= 100) return string;
        else return string.substring(0, 100) + '...';
    }

    /**
     * 
     * @param {Object} viewer state.callRequests object
     * 
     * Add caller to state.callersSelected array
     * Position depends on whether there are already caller(s) selected, and whether callScreenMode is on
     */
    selectCaller = viewer => {

        if ((this.state.callersSelected.filter(caller => caller.viewer === viewer).length === 0 && this.state.callScreenMode) || !this.state.callScreenMode){
            this.setState({
                ...this.state,
                callersSelected: this.state.callScreenMode ? this.state.callersSelected.length < 5 ? [
                    ...this.state.callersSelected,
                    {
                        ...this.state.callRequests.find(r => r.viewer === viewer),
                        timestamp: new Date()
                    }
                ] : this.state.callersSelected.map(c => {
                        if (c.viewer === this.state.callersSelected.sort((a, b) => a.timestamp-b.timestamp)[this.state.callersSelected.length - 1].viewer) return {
                            ...this.state.callRequests.find(r => r.viewer === viewer),
                            timestamp: new Date() 
                        }
                        else return c
                    })
                 : [
                    {
                        ...this.state.callRequests.find(r => r.viewer === viewer),
                        timestamp: new Date()
                    }
                ],
                callRequests: this.state.callRequests.map(r => {
                    if (r.viewer === viewer) return {
                        ...r,
                        unreadMessages: false
                    }
                    else return r;
                })
            }, () => {
                this.state.chatSocket.emit('connection-request', this.state.peer._id, viewer);
            });
        }
    } 

    /**
     * TODO: Make this its own component
     * 
     * @returns A list of viewers who have requested to call in
     */
    renderCallRequests = () => {
        if (this.state.callRequests.length){
            return (
                <ul className="list-group">
                    {this.state.callRequests.sort((a, b) => a.name.localeCompare(b.name)).map(call => (
                        <li title={call.viewer} onClick={() => this.selectCaller(call.viewer)} style={{cursor: 'pointer'}} key={call.viewer} className={`list-group-item list-group-item-action ${call.callAccepted ? 'bg-success' : ''}`}>
                            <p className="fw-bold">{call.unreadMessages ?<i className="text-info fas fa-circle me-2"></i>: <></>}{call.name}</p>
                            <p className="emoji-chats">{this.callRequestString(call.text)}</p>
                        </li>
                    ))}
                </ul>
            );
        } else {
            return <p className="mt-4 text-center">You have no calls</p>
        }
    }

    /**
     * TODO: Make this its own component
     * 
     * @returns A list of public chat messages
     */
    renderPublicChatMessages = () => this.state.publicChatMessages.map(message => (
        <div className="border-top border-bottom border-light w-100">
            <div className="d-flex align-items-start my-1 w-100">
                <img className="chat-avatars me-2" src={`/thumbnails/${message.avatar}`} alt={`${message.user} avatar`}></img>
                <p style={{margin: 0}} className="text-break emoji-chats"><span title={message.username} className={`fw-bold ${message.username === this.props.username ? 'text-success' : ''}`}>{message.user}:</span> {message.message}</p>
            </div>
        </div>
    ));

    /**
     * 
     * @param {Event Object} e - Standard Javascript event object
     * 
     * Handles chat input changes, sets changes into state
     */
    chatChangeHandler = e => {
        if (e.target.value.split('\n').length > 1) document.getElementById(e.target.id).value = document.getElementById(e.target.id).value.split('\n')[0];
        else this.setState({
            ...this.state,
            charCounts: {
                ...this.state.charCounts,
                [e.target.name]: String(e.target.value).length
            }
        });
    } 

    /**
     * 
     * @param {String} e - ID of chatbox
     * 
     * Sends a chat message from any of the caller chat boxes
     * Emits the message via socket
     * Resets character counts 
     */
    sendPrivateChat = e => {
        let index = Number(e.split('_')[1]) - 1;
        let compIndex = Number(e.split('_')[1]);
        let caller = this.state.callersSelected[index];
        switch(index){
            case 0:
                if (document.querySelector('#textarea-caller-chat').value !== '' && this.state.charCounts.private_1 < 601){
                    try {
                        this.state.chatSocket.emit('streamer-chat-streamer', document.querySelector('#textarea-caller-chat').value, caller.viewer);
                        this.setState({
                            ...this.state,
                            charCounts: {
                                ...this.state.charCounts,
                                private_1: 0
                            }
                        }, () => setTimeout(() => this.setState({
                            ...this.state,
                            charCounts: {
                                ...this.state.charCounts,
                                private_1: 0
                            }
                        }, () => {
                            document.querySelector('#textarea-caller-chat').value = '';
                        } )), 200);
                    } catch(err){
                        console.log(err);
                    }
                }
                break;
            default:
                if (document.getElementById(`textarea-caller-chat-${compIndex}`).value !== '' && this.state.charCounts[e] < 601){
                    try {
                        this.state.chatSocket.emit('streamer-chat-streamer', document.getElementById(`textarea-caller-chat-${compIndex}`).value, caller.viewer);
                        this.setState({
                            ...this.state,
                            charCounts: {
                                ...this.state.charCounts,
                                [e]: 0
                            }
                        }, () => setTimeout(() => this.setState({
                            ...this.state,
                            charCounts: {
                                ...this.state.charCounts,
                                [e]: 0
                            }
                        }, () => {
                            document.getElementById(`textarea-caller-chat-${compIndex}`).value = '';
                        } )), 200);
                    } catch(err){
                        console.log(err);
                    }
                }
                break;
        }
    }

    /**
     * TODO: Change chats to forms for native compatibility
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * 
     * Submits chats if the enter key is pressed
     */
    keyPress = e => {
        if (e.key === 'Enter'){
            if (document.getElementById(e.target.id).value && document.getElementById(e.target.id).value !== '\n'){
                if (e.target.name === 'public') this.sendPublicChat();
                else {
                    if (e !== undefined) e.stopPropagation();
                    this.sendPrivateChat(e.target.name);
                } 
            }
        } 
    }

    /**
     * Fired when the user clicks the Settings button
     * Sets the settings modal into state, and shows it
     */
    editStream = () => {
        this.setState({
            ...this.state,
            streamDetailsModal: new bootstrap.Modal(document.querySelector('#streamDetailsModal'))
        }, () => this.state.streamDetailsModal.show());
    }

    /**
     * Fired when a user changes the view on a mobile screen
     * 
     * @param {Event Object} e - Standard JavaScript event object
     * 
     * Saves the chat container height if applicable, and changes the view
     * Updates heights and scrolltops of chat containers if applicable, with 200 ms buffer due to framer-motion
     */
    mobilePillSelect = e => {
        let chatContainer = document.getElementById('border-public-chat-mobile');
        if (chatContainer && chatContainer.clientHeight) this.setState({
            ...this.state,
            publicChatHeightMobile: chatContainer.clientHeight,
            menuSelectionMobile: e.target.name
        });
        else setTimeout(() => {
            let chatContainerPublic = document.getElementById('container-public-chat-mobile');
            if (chatContainerPublic){
                chatContainerPublic.style.scrollBehavior = 'auto';
                chatContainerPublic.style.height = `${this.state.publicChatHeightMobile}px`;
                chatContainerPublic.scrollTop = chatContainerPublic.scrollHeight + 5;
                setTimeout(() => chatContainerPublic.style.scrollBehavior = 'smooth', 100);
            }
            this.setState({
                ...this.state,
                menuSelectionMobile: e.target.name
            });
        }, 200);
    }

    /**
     * Fired when the user toggles Call Screen Mode
     * Saves public/private chat heights depending on the current setting
     * Once mode is changed, adjust other public/private chat heights depending on new setting
     * 200 ms timeout to account for framer-motion
     */
    toggleCallScreenMode = () => this.setState({
        ...this.state,
        callScreenMode: !this.state.callScreenMode,
        publicChatHeight: document.getElementById('border-public-chat') ? document.getElementById('border-public-chat').clientHeight : this.state.publicChatHeight,
        callerChatHeight: document.getElementById('border-caller-chat-2') ? document.getElementById('border-caller-chat-2').clientHeight : this.state.callerChatHeight,
        publicChatHeightMobile: document.getElementById('border-public-chat-mobile') ? document.getElementById('border-public-chat-mobile').clientHeight : this.state.publicChatHeightMobile
    }, () => {
        if (this.state.callScreenMode) {
            setTimeout(() => {
                [].slice.call(document.getElementsByClassName('chat-containers-extra')).forEach(e => {
                    e.style.scrollBehavior = 'auto';
                    e.style.height = `${this.state.callerChatHeight}px`;
                    e.scrollTop = e.scrollHeight + 5;
                    setTimeout(() => e.style.scrollBehavior = 'smooth', 100);
                });
            }, 100);
        } else {
            setTimeout(() => {
                let chatContainer = document.getElementById('container-public-chat');
                let chatContainerPublic = document.getElementById('container-public-chat-mobile');
                if (chatContainer){
                    chatContainer.style.scrollBehavior = 'auto';
                    chatContainer.style.height = `${this.state.publicChatHeight}px`;
                    chatContainer.scrollTop = chatContainer.scrollHeight + 5;
                    setTimeout(() => chatContainer.style.scrollBehavior = 'smooth', 100);
                }
                if (chatContainerPublic){
                    chatContainerPublic.style.scrollBehavior = 'auto';
                    chatContainerPublic.style.height = `${this.state.publicChatHeightMobile}px`;
                    chatContainerPublic.scrollTop = chatContainerPublic.scrollHeight + 5;
                    setTimeout(() => chatContainerPublic.style.scrollBehavior = 'smooth', 100);
                }
            }, 100);
        } 
    });

    /**
     * TODO: Move the entire chat area to its own component
     * 
     * @param {String} side - "left" | "right"
     * @returns Chat boxes 2-5 when Call Screener Mode is on
     */
    renderCallChats = side => {
        if (side === 'left'){
            if (this.state.callersSelected.length > 1) return <>
                <CallerChat_Extra scrollHandler={this.scrollHandler} chatIndex={2} emojiPickerIndex={3} padding="ps-1 pt-1" caller={this.state.callersSelected[1]} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[1]).viewer} chatSocket={this.state.chatSocket}/>
                {this.state.callersSelected.length > 3 ? 
                <CallerChat_Extra scrollHandler={this.scrollHandler} chatIndex={3} emojiPickerIndex={4} padding="ps-1 pt-1 pb-1" caller={this.state.callersSelected[3]} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[3]).viewer} chatSocket={this.state.chatSocket}/> : <></>}
            </>
            else return <></>
        } else {
            if (this.state.callersSelected.length > 2)return <>
                <CallerChat_Extra scrollHandler={this.scrollHandler} chatIndex={4} emojiPickerIndex={5} padding="ps-1 pt-1 pe-1" caller={this.state.callersSelected[2]} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[2]).viewer} chatSocket={this.state.chatSocket}/>
                {this.state.callersSelected.length > 4 ? 
                <CallerChat_Extra scrollHandler={this.scrollHandler} chatIndex={5} emojiPickerIndex={6} padding="p-1" caller={this.state.callersSelected[4]} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[4]).viewer} chatSocket={this.state.chatSocket}/> : <></>}
            </>
            else return <></>
        }
    }

    /**
     * Fired when the user clicks Create Poll
     * Sets the New Poll Bootstrap modal into state, then shows it
     * 
     */
    createPoll = () => this.setState({
        ...this.state,
        startPollModal: new bootstrap.Modal(document.getElementById('startPollModal'))
    }, () => this.state.startPollModal.show());

    /**
     * Fired when the user clicks View Poll
     * Sets the View Poll Bootstrap modal into state, then shows it
     * 
     */
    viewPoll = () => this.setState({
        ...this.state,
        viewPollKey: !this.state.viewPollKey
    }, () => this.setState({
        ...this.state,
        viewPollModal: new bootstrap.Modal(document.getElementById('viewPollModal'))
    }, () => this.state.viewPollModal.show())); 

    /**
     * Closes all emoji pickers
     * Triggered by several events
     */
    closeAllEmojis = () => {
        this.props.set_emoji_picker(-1);
        if (this.state.copyPopover.hide) this.state.copyPopover.hide();
        if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
        if (this.state.copyPopover.hide || this.state.copyTooltip.hide) this.setState({
            ...this.state,
            copyTooltip: '',
            copyPopover: ''
        });
    } 

    /**
     * 
     * @param {Event Object} e - Standard JavaScript event
     * Set the emoji selection into state
     * The actual emoji picker ui will open up from the componentDidUpdate function
     */
    openEmojiPicker = e => {
        e.stopPropagation();
        const index = Number(e.target.id.split('-')[1]);
        if (index !== this.props.emojiPickerShown) this.props.set_emoji_picker(index);
        else this.props.set_emoji_picker(-1);
    }

    /**
     * 
     * @param {Event Object} e  - Standard JavaScript event object
     * 
     * This runs concurrently with the emoji picker function, and stops other default events from triggering
     */
    clickEmojiContainer = e => {
        e.stopPropagation();
    }

    /**
     * Fired when the user selects a new caller from the dropdown on a mobile screen
     * 
     * @param {Event Object} e - Standard Javascript event object
     * 
     * 
     */
    mobileCallerChange = e => {
        if (e.target.value !== '') this.selectCaller(e.target.value);
        else this.setState({
            ...this.state,
            callersSelected: []
        });
    }

    /**
     * Fired when a user scrolls in any of the chat boxes. 
     * 
     * @param {Event Object} e - Standard Javascript event
     * 
     * If the scroll bar is not touching the bottom, set scrollTop to false (Won't automatically scroll to bottom next time a chat arrives)
     */
    scrollHandler = e => {
        switch(e.currentTarget.id){
            case 'container-public-chat':
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops.public) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public: true
                    }
                });
                else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops.public) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public: false
                    }
                });
                break;
            case 'container-public-chat-mobile':
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops.public_mobile) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public_mobile: true
                    }
                });
                else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops.public_mobile) this.setState({
                    ...this.state,
                    scrollTops: {
                        ...this.state.scrollTops,
                        public_mobile: false
                    }
                });
                break;
            case 'container-caller-chat':
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops.private_1){
                    this.setState({
                        ...this.state,
                        scrollTops: {
                            ...this.state.scrollTops,
                            private_1: true
                        }
                    });
                }
                else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops.private_1){
                    this.setState({
                        ...this.state,
                        scrollTops: {
                            ...this.state.scrollTops,
                            private_1: false
                        }
                    });
                } 
                break;
            default:
                try {
                    let index = Number(e.currentTarget.id.split('-')[3] - 1);
                    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 <= e.currentTarget.clientHeight && !this.state.scrollTops[`private_${index}`]){
                        this.setState({
                            ...this.state,
                            scrollTops: {
                                ...this.state.scrollTops,
                                [`private_${index}`]: true
                            }
                        });
                    }
                    else if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 10 > e.currentTarget.clientHeight && this.state.scrollTops[`private_${index}`]){
                        this.setState({
                            ...this.state,
                            scrollTops: {
                                ...this.state.scrollTops,
                                [`private_${index}`]: false
                            }
                        });
                    } 
                } catch(err){
                    console.log('error', err);
                }       
        }
    }

    /**
     * Fired when the user clicks on the call screen while in a call
     * Sets the big stream modal into state, then shows it
     * 
     */
    streamModal = () => this.setState({
        ...this.state,
        streamBigModal: new bootstrap.Modal(document.getElementById('streamBigModal'))
    }, () => this.state.streamBigModal.show());


    /**
     * Hides the big stream modal if it is shown. Triggered by several things.
     */
    hideStreamModal = () => {
        if (this.state.streamBigModal && this.state.streamBigModal.hide) this.state.streamBigModal.hide();
    } 

    /**
     * 
     * Fired when the user clicks "Copy Link"
     * 
     * @param {Event Object} e - Standard Javascript event
     * 
     * Removes any previous popovers or tooltips
     * Opens new popover, sets innerHTML to a link to the stream and a button to copy the link
     * 
     */
    setPopoverContent = e => {
        e.stopPropagation();
        if (this.state.copyPopover.hide){
            if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
            this.state.copyPopover.hide();
            [].slice.call(document.getElementsByClassName('popover')).forEach(p => p.remove());
            this.setState({
                ...this.state,
                copyPopover: '',
                copyTooltip: ''
            })
        } else {
            if (this.state.copyTooltip.hide) this.state.copyTooltip.hide();
            [].slice.call(document.getElementsByClassName('popover')).forEach(p => p.remove());
            this.setState({
                ...this.state,
                copyPopover: new bootstrap.Popover(document.getElementById('button-popover'), {
                    html: true,
                    content: `<p>https://nanaphone.net/${this.props.username}</p>`,
                    placement: window.screen.availWidth > 768 ? 'top' : 'left'
                })
            }, () => {
                this.state.copyPopover.show();
                setTimeout(() => {
                    [].slice.call(document.getElementsByClassName('popover-body')).forEach(e => {
                        e.innerHTML = `
                            <div class="d-flex align-items-center">
                                <p class="m-0">https://nanaphone.net/${this.props.username}</p>
                                <button id="button-tooltip" data-bs-toggle="tooltip" data-bs-placement="right" title="Copy" type="button" class="btn btn-sm btn-outline-primary ms-2"><i class="far fa-copy"></i></button>
                            </div>
                        `;
                        document.getElementById('button-tooltip').addEventListener('click', this.copyLink);
                    });
                    this.setState({
                        ...this.state,
                        copyTooltip: new bootstrap.Tooltip(document.getElementById('button-tooltip'), {
                            placement: 'bottom'
                        })
                    }, () => [].slice.call(document.getElementsByClassName('popover')).forEach(p => {
                        p.style.minWidth = 'max-content';
                        p.style.width = 'max-content';
                        p.style.maxWidth = 'max-content';
                    }));
                }, 100);
            }); 
        } 
    } 

    /**
     * Fired when the user clicks the button to copy a link to the stream
     * 
     * @param {Event Object} p - Standard JavaScript event object
     * 
     * Copies the stream link to the clipboard, changes the text of the tooltip to say "Copied"
     */
    copyLink = p => {
        p.stopPropagation();
        let e = document.getElementById('button-tooltip');
        e.setAttribute('data-bs-original-title', 'Copied');
        navigator.clipboard.writeText(`https://nanaphone.net/${this.props.username}`);
        this.state.copyTooltip.hide();
        this.setState({
            ...this.state,
            copyTooltip: new bootstrap.Tooltip(e, {
                placement: 'bottom'
            })
        }, () => this.state.copyTooltip.show());
    }

    render(){
        return (
            <motion.div onClick={this.closeAllEmojis} className="container-fluid d-flex flex-column h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <audio src="/sounds/tone2.mp3" id="call-notification">

                </audio>
                <StartPollModal viewPoll={this.viewPoll} startPollModal={this.state.startPollModal}/>
                <ViewPollModal streamer={this.props.username} key={this.state.viewPollKey} viewPollModal={this.state.viewPollModal} />
                {this.props.activeStream ?
                <SettingsModal streamDetailsModal={this.state.streamDetailsModal}/> : <></>}
                <StreamBigModal hideStreamModal={this.hideStreamModal} myStream={this.props.streamerDevices} streamBigModal={this.state.streamBigModal} streams={[
                    {
                        mediaStream: this.props.streamerDevices,
                        avatar: this.props.avatar,
                        viewer: this.props.username
                    },
                    ...this.state.callRequests.filter(c => c.callAccepted === true)
                ]}/>
                <div style={{position: 'absolute', bottom: '105px'}} className="container" id="notification-container">{this.state.disconnectAlerts.map(a => a.alertNode)}</div>
                {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ? 
                <div className="row">
                    <div className="col-3">
                        <p className="mt-2" style={{marginBottom: 0}}>{this.state.viewCount} {this.state.viewCount === 1 ? 'Viewer' : 'Viewers'}</p>
                        <div className="form-check form-switch mt-2">
                            <input onChange={this.toggleCallScreenMode} className="form-check-input" type="checkbox" id="callScreenMode"></input>
                            <label className="form-check-label text-nowrap" for="callScreenMode">View Public Chat</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <h1 className="text-center mt-3 display-5">{this.props.activeStream ? this.props.activeStream.title : ''}</h1>
                    </div>
                    <div className="col-3">
                        <div className="mt-2 d-flex align-items-start justify-content-end">
                            <button id="button-popover" onClick={this.setPopoverContent} type="button" class="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top">
                            <i className="fas fa-share-alt me-2"></i>
                            Share Link
                            </button>
                            <button className="btn btn-dark d-block ms-2" onClick={this.editStream}><i className="fas fa-cog me-2"></i>Settings</button>
                        </div>
                    </div>
                    <hr style={{marginTop: 0, marginBottom: '0.5rem'}}></hr>
                </div> :
                <>
                    <h1 className="text-center mt-3 display-5">{this.props.activeStream ? this.props.activeStream.title : ''}</h1>
                    <div className="d-flex justify-content-between align-items-start">
                        <p className="mt-2" style={{marginBottom: 0}}>{this.state.viewCount} {this.state.viewCount === 1 ? 'Viewer' : 'Viewers'}</p>
                        <div className="d-flex align-items-center">
                            <button id="button-popover" onClick={this.setPopoverContent} type="button" class="btn btn-secondary me-2" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="left">
                            <i className="fas fa-share-alt"></i>
                            </button>
                            <button className="btn btn-dark d-block ms-auto" onClick={this.editStream}><i className="fas fa-cog"></i></button>
                        </div>
                        
                    </div>
                    <hr className="my-2"></hr>
                </>}
                
                {typeof window !== 'undefined' && window.screen && window.screen.availWidth > 768 ? 
                <div className="row fg-1">
                    <div style={{height: '98%'}} className="col-3 d-flex flex-column">
                        <div className="card d-flex flex-column h-100">
                            <div className="card-header"><h5 className="m-0">Call Requests</h5></div>
                            <div className="card-body p-1 fg-1">
                                <div id="call-requests-border" className="h-100">
                                    <div style={{overflowX: 'hidden', overflowY: 'auto', scrollBehavior: 'smooth', overflowX: 'auto', overflowY: 'hidden'}} id="call-requests-container">
                                        {this.renderCallRequests()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: '98%'}} className="col-3 d-flex flex-column">
                        {this.state.callRequests.filter(c => c.callAccepted === true).length ? 
                        <div onClick={this.streamModal} className="border border-dark w-100 d-flex" style={{height: `${this.state.callPanelHeight}px`, cursor: 'pointer'}} id="call-container">
                            {this.renderActiveCalls()}
                        </div>:
                        <>
                            {this.state.videoStream ?
                            <iframe id="video-frame" className="yt-streamer" style={{height: `${this.state.streamHeight === 0 ? '25%' : `${this.state.streamHeight}px`}`}} src={this.props.activeStream ? this.props.activeStream.url : ''} title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : <div style={{height: `${this.state.callPanelHeight}px`, backgroundImage: `url("/avatars/${this.props.activeStream.thumbnail === 'monkey-dark.png' ?this.props.avatar:this.props.activeStream.thumbnail}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}} id="div-streamer-image"></div>}
                        </>
                        }
                        {this.state.callersSelected.length ?
                        <CallerChat scrollHandler={this.scrollHandler} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[0].viewer)} chatSocket={this.state.chatSocket} callerSelected={this.state.callersSelected[0]}  />
                        : <></>}
                    </div>
                    {this.state.callScreenMode ? 
                    <div style={{height: '98%'}} className="col-6">
                        <div style={{padding: 0}} className="border border-dark h-100 container-fluid">
                            <div style={{margin: 0}} className="row h-100">
                                {this.state.callersSelected.length > 1 ?
                                <>
                                    <div style={{padding: 0}} className="col-6 h-100">
                                        {this.renderCallChats('left')}
                                    </div>
                                    <div style={{padding: 0}} className="col-6 h-100">
                                        {this.renderCallChats('right')}
                                    </div>
                                </> :
                                <h4 className="mt-5 text-center">Other Live Callers</h4>}
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div style={{height: '98%'}} className="col-4">
                            <div className="card h-100 d-flex flex-column">
                                <div className="card-header"><h5 className="m-0">Public Chat</h5></div>
                                <div className={`card-body fg-1 d-flex flex-column ${typeof window !== 'undefined' && window.screen && window.screen.availWidth <= 1400 ? 'p-1' : ''}`}>
                                    <div id="border-public-chat" className="chat-container-parents mb-2 border border-light px-2 fg-1">
                                        <div className="chat-containers" onTouchMove={this.scrollHandler} onWheel={this.scrollHandler} style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id="container-public-chat">
                                            {this.renderPublicChatMessages()}
                                        </div>
                                    </div>
                                    <div style={{padding: 0}} className="container-fluid align-self-end">
                                        <div className="row mx-0">
                                            <div className="col-1">
                                                <i style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} id="emoji-1" className="mt-1 fas fa-smile fa-2x"></i>
                                                {this.props.emojiPickerShown === 1 ?
                                                <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                            </div>
                                            <div className="col-9">
                                                <textarea onKeyPress={this.keyPress} name="public" onChange={this.chatChangeHandler} id="textarea-public-chat" placeholder="Message Body" className="form-control"></textarea>
                                            </div>
                                            <div style={{padding: 0}} className="col-2">
                                                <>
                                                    {this.state.publicChatTimeout ? 
                                                    <button disabled className={`btn btn-primary ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1440 ? 'btn-sm' : ''}`}>Wait ({this.state.publicChatTimeout})</button>:
                                                    <>
                                                        {this.state.charCounts.public === 0 || this.state.charCounts.public > 600 ?
                                                        <button disabled className={`btn btn-primary ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1440 ? 'btn-sm' : ''}`}><i className={`fas fa-paper-plane me-1`}></i>Send</button>:                                                            
                                                        <button onClick={this.sendPublicChat} className={`btn btn-primary ${typeof window !== 'undefined' && window.screen && window.screen.availWidth < 1440 ? 'btn-sm' : ''}`}><i className={`fas fa-paper-plane me-1`}></i>Send</button>}
                                                    
                                                    </>
                                                    }
                                                </>
                                                <p className={`mt-1 mb-0 ${this.state.charCounts.public > 600 ? 'text-danger' : ''}`}>{this.state.charCounts.public}/600</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: '98%'}} className="col-2">
                            <div className="card d-flex flex-column h-100">
                                <div className="card-header"><h5 className="text-center">Actions</h5></div>
                                <div style={{overflowX: 'hidden', overflowY: 'auto', scrollBehavior: 'smooth'}} className="card-body fg-1">
                                    <ul className="list-group">
                                        {this.props.pollData && this.props.pollData.options ?
                                        <li onClick={this.viewPoll} className="list-group-item list-group-item-action fw-bold" style={{cursor:'pointer'}}><i className="fas fa-poll me-2"></i>View Poll</li>:
                                        <li onClick={this.createPoll} className="list-group-item list-group-item-action fw-bold" style={{cursor:'pointer'}}><i className="fas fa-poll me-2"></i>Start Poll</li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>}
                    
                </div> :
                <>
                    <div className="row">
                        <div className="col-6 h-100 d-flex flex-column">
                            {this.state.callRequests.filter(c => c.callAccepted === true).length ? 
                            <div onClick={this.streamModal} className="border border-dark d-flex" style={{height: `${this.state.callPanelHeight}px`, overflowX: 'auto', overflowY: 'hidden'}} id="call-container">
                                {this.renderActiveCalls()}
                            </div>:
                            <>
                                {this.state.videoStream ? 
                                <iframe id="video-frame" className="yt-streamer h-100" src={this.props.activeStream ? this.props.activeStream.url : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                : <div style={{height: `${this.state.callPanelHeight}px`, backgroundImage: `url("/avatars/${this.props.activeStream.thumbnail === 'monkey-dark.png' ?this.props.avatar:this.props.activeStream.thumbnail}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}} id="div-streamer-image"></div>}
                            </>
                            }
                        </div>
                        <div className="col-6">
                            <div className="d-flex align-items-start">
                                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button name="chat" onClick={this.mobilePillSelect} className="nav-link active" id="v-pills-chat-tab" data-bs-toggle="pill" data-bs-target="#v-pills-chat" type="button" role="tab" aria-controls="v-pills-chat" aria-selected="false">Public Chat</button>
                                    <button name="requests" onClick={this.mobilePillSelect} className="nav-link" id="v-pills-requests-tab" data-bs-toggle="pill" data-bs-target="#v-pills-requests" type="button" role="tab" aria-controls="v-pills-requests" aria-selected="true">Call Requests</button>
                                    <button name="actions" onClick={this.mobilePillSelect} className="nav-link" id="v-pills-actions-tab" data-bs-toggle="pill" data-bs-target="#v-pills-actions" type="button" role="tab" aria-controls="v-pills-actions" aria-selected="false">Actions</button>
                                </div>
                            </div>
                            {this.state.callRequests.length && this.state.menuSelectionMobile === 'requests' ?
                            <select defaultValue={this.state.callersSelected.length ? this.state.callersSelected[0].viewer : ''} onChange={this.mobileCallerChange} className="form-select mt-1">
                                <option key="" value="">Select Caller</option>
                                {this.state.callRequests.map(request => <option key={request.viewer} value={request.viewer}>{request.name}</option>)}
                            </select> : <></>}
                        </div>
                    </div>
                    <div style={{minHeight: '50vh'}} className={`row fg-1`}>
                        <div className="col-12 h-100">
                            <div className="py-2 h-100">
                                <div className="tab-content h-100" id="v-pills-tabContent">
                                    <div className="tab-pane h-100 fade" id="v-pills-requests" role="tabpanel" aria-labelledby="v-pills-requests-tab">
                                        <div className="card h-100">
                                            <div className="card-body p-1 fg-1 d-flex flex-column">
                                            {this.state.callersSelected.length ?
                                                <CallerChat_mobile scrollHandler={this.scrollHandler} charCounts={this.state.charCounts} pickEmoji={this.pickEmoji} sendPrivateChat={this.sendPrivateChat} keyPress={this.keyPress} chatChangeHandler={this.chatChangeHandler} acceptCall={this.acceptCall} dropCall={this.dropCall} forceCallerChatBottom={this.state.forceCallerChatBottom} key={JSON.stringify(this.state.callersSelected[0].viewer)} chatSocket={this.state.chatSocket} callerSelected={this.state.callersSelected[0]}  />: 
                                                <>
                                                    {this.state.callRequests.length ?
                                                    <h6 className="text-center mt-5">Please select a caller</h6>:
                                                    <h6 className="text-center mt-5">There are no call requests</h6>}
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade h-100 show active" id="v-pills-chat" role="tabpanel" aria-labelledby="v-pills-chat-tab">
                                        <div className="card h-100">
                                            <div className="card-body fg-1 px-1 d-flex flex-column">
                                                <div id="border-public-chat-mobile" className="chat-container-parents mb-2 border border-light px-2 fg-1">
                                                    <div className="chat-containers" onTouchMove={this.scrollHandler} onWheel={this.scrollHandler} style={{overflowY: 'auto', width: '100%', scrollBehavior: 'smooth'}} id="container-public-chat-mobile">
                                                        {this.renderPublicChatMessages()}
                                                    </div>
                                                </div>
                                                <div style={{padding: 0}} className="container-fluid align-self-end">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <i style={{cursor: 'pointer'}} onClick={this.openEmojiPicker} id="emoji-1" className="mt-1 ms-1 fas fa-smile fa-2x"></i>
                                                            {this.props.emojiPickerShown === 1 ?
                                                            <div onClick={this.clickEmojiContainer} id="emojis"></div> : <></>}
                                                        </div>
                                                        <div className="col-10">
                                                            <input onKeyPress={this.keyPress} name="public" onChange={this.chatChangeHandler} id="textarea-public-chat" placeholder="Message Body" className="form-control"></input>
                                                        </div>
                                                        <div className="col-12">
                                                            <div class="d-grid gap-2 mt-2">
                                                                {this.state.sendingPublicChat ?
                                                                <button className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm me-2"></span>Sending</button>:
                                                                <>
                                                                    {this.state.publicChatTimeout ? 
                                                                        <button disabled className="btn btn-primary">Wait ({this.state.publicChatTimeout}s)</button>:
                                                                        <>
                                                                            {this.state.charCounts.public === 0 || this.state.charCounts.public > 600 ?
                                                                            <button disabled className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.charCounts.public}/600)</button>:                                                            
                                                                            <button onClick={this.sendPublicChat} className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Send ({this.state.charCounts.public}/600)</button>}
                                                                        
                                                                        </>
                                                                    }
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade h-100" id="v-pills-actions" role="tabpanel" aria-labelledby="v-pills-actions-tab">
                                        <div className="card h-100">
                                            <div style={{overflowX: 'hidden', overflowY: 'auto', scrollBehavior: 'smooth'}} className="card-body">
                                                <h6 className="text-nowrap text-center">Actions</h6>
                                                <hr></hr>
                                                <ul className="list-group">
                                                    {this.props.pollData && this.props.pollData.options ?
                                                    <li onClick={this.viewPoll} className="list-group-item list-group-item-action fw-bold" style={{cursor:'pointer'}}><i className="fas fa-poll me-2"></i>View Poll</li>:
                                                    <li onClick={this.createPoll} className="list-group-item list-group-item-action fw-bold" style={{cursor:'pointer'}}><i className="fas fa-poll me-2"></i>Start Poll</li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_caller_stream, set_streamer_devices, set_poll, set_emoji_picker })(StreamView);