import React from 'react';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { set_user } from '../redux/actions';
import { connect } from 'react-redux';

class Resets extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the password reset is being sent to or processed by the server
             */
            working: false
        }
    }

    /**
     * TODO: Replace with actual <form>
     * @param {Event Object} e - Javascript event object
     * 
     * Submits the form when the user presses enter
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
     * 
     * @param {String} word A member of a schema, usually a username 
     * @returns Boolean indicating whether that word contains only numbers/letters/dashes
     */
    checkAllowedCharacters = word => {
        const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-".split('');
        word.split('').forEach(letter => {
            if (allowedCharacters.indexOf(letter) !== -1) return false;
        })
        return true;
    }

    /**
     * Fired when the user clicks the Submit button
     * 
     * Validates the inputs
     * Sends to the server
     * Redirects the user to the "Success" page
     */
    submit = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => {
            try {
                const password1 = document.getElementById('input-password1').value;
                const password2 = document.getElementById('input-password2').value;
                if (password1 !== password2) throw 'Passwords must match';
                if (password1.length < 4) throw "Your password is too short. (Minimum 4 characters)";
                if (password1.length > 256) throw "Your password is too long (Maximum 256 characters)";
                if (!this.checkAllowedCharacters(password1)) throw "Illegal characters in password (Allowed: a-z, -, _";
                axios.post('/auth/reset-submit', {
                    password1: password1,
                    password2: password2,
                    uuid: this.props.match.params.id
                }).then(res => {
                    if (res.data.success){
                        this.props.set_user(res.data.userInfo);
                        this.props.history.push('/');
                        alert('Password updated successfully');
                    }
                    else this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(res.data.error));
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later.')
                }))
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => alert(err));
            }
        });
    }

    render(){
        return (
            <motion.div className="min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="buf-4"></div>
                <div className="container">
                    <h1 className="text-center mt-5 text-raleway">Reset Password</h1>
                    <div className="mx-auto mt-2" style={{width: '400px', maxWidth: '95%'}}>
                        <label className="mt-3">New Password</label>
                        <input type="password" onKeyPress={this.pressEnter} placeholder="Min: 4 chars Max: 256 chars" id="input-password1" className="form-control mt-1"></input>
                        <label className="mt-3">Re-Enter Password</label>
                        <input type="password" onKeyPress={this.pressEnter} placeholder="Passwords must match" id="input-password2" className="form-control mt-1"></input>
                        <div className="d-grid gap-2 my-3">
                            {this.state.working ?
                            <button disabled className="btn btn-primary" type="button"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Submitting</button> :
                            <button onClick={this.submit} className="btn btn-primary" type="button"><i className="fas fa-paper-plane me-2"></i>Submit</button>}
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default withRouter(connect(mapStateToProps, { set_user })(Resets));