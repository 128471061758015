import React from 'react';
import { connect } from 'react-redux';
import { toggle_video_preview } from '../../redux/actions'; 

class VideoPreview extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    /**
     * Fired when the user clicks the Close button or the X
     * Closes the modal
     */
    closeModal = () => {
        this.props.toggle_video_preview();
        this.props.videoPreviewModal.hide();
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props value
     * 
     * Set the <video> srcObject to the user's stream upon showing the modal
     */

    componentDidUpdate(prevProps){
        if (this.props.videoPreviewShown && this.props.videoPreviewShown !== prevProps.videoPreviewShown){
            const webCam = document.querySelector('#camera');
            webCam.srcObject = this.props.callerSelected.mediaStream;
            webCam.play();
        }
    }


    render(){
        return (
            <div className="modal fade" id={`videoPreviewModal-${this.props.index}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="videoPreviewModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.callerSelected.name}'s Camera</h5>
                        <button type="button" onClick={this.closeModal} className="btn-close" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {this.props.videoPreviewShown ? 
                        <div dangerouslySetInnerHTML={{
                            __html: `
                                <video class="d-block w-100 mx-auto" style="padding: 0" id="camera" muted autoplay playsinline></video>
                            `
                        }}></div>
                          : <h5 className="text-center mt-3">Video Unavailable</h5>}
                    </div>
                    <div className="modal-footer">
                        <button onClick={this.closeModal} type="button" className="btn btn-secondary">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {toggle_video_preview})(VideoPreview);