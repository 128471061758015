import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import axios from 'axios';
import { set_user, purge_user } from '../redux/actions';
import { Link } from 'react-router-dom';
import TempAccountModal from '../components/TempAccountModal';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

let bootstrap;

class Login extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the user is in the process of logging in
             * tempAccountModal: false | Bootstrap Modal instance with the temporary account creation screen
             */
            working: false,
            tempAccountModal: false
        }
    }

    /**
     * Set bootstrap variable from window
     * If user is already logged in and not a temp user, redirect to dashboard
     */
    componentDidMount(){
        bootstrap = window.bootstrap;
        console.log(this.props);
        if (this.props.username && !this.props.temp) this.props.history.push('/dashboard');
    }


    /**
     * TODO: Replace with actual <form>
     * @param {Event Object} e - Javascript event object
     * 
     * Submits the form when the user presses enter
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
   * 
   * @returns Captcha key generated from the executeRecaptcha function passed from the provider
   */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.googleReCaptchaProps && this.props.googleReCaptchaProps.executeRecaptcha) this.props.googleReCaptchaProps.executeRecaptcha().then(resolve).catch(err => this.setState({
            ...this.state,
            working: false
        }, () => {
            console.log('error', err);
            return reject();
        }));
        else setTimeout(async () => {
            try {
                const captchaKey = await this.getRecaptcha();
                resolve(captchaKey);
            } catch(err){
                console.log(err);
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    alert('Captcha error. Please try again later.');
                    reject();
                });
            }
        }, 1000);
    });

    /**
     * Fired when the user clicks the Login button
     * 
     * Validate Captcha
     * Send login object to server
     * Set user data into application state
     * Route to dashboard
     */
    submit = () => {
        if (!this.state.working){
            this.setState({
                ...this.state,
                working: true
            }, async () => {
                const captchaKey = await this.getRecaptcha();
                axios.post('/auth/login', {
                    username: document.querySelector('#input-username').value,
                    password: document.querySelector('#input-password').value,
                    captchaKey: captchaKey
                }).then(res => {
                    if (res.data.success){
                        this.props.set_user(res.data.userInfo);
                        this.props.history.push(this.props.loginDestination);
                    } else {
                        this.setState({
                            ...this.state,
                            working: false
                        }, () => alert(res.data.errorMessage));
                    };
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        working: false
                    }, () => alert('An error occurred. Please try again later'));
                });
            });
        }
    }

    /**
     * Fired when the user clicks "Take Calls as Guest"
     * Opens the Temp Account modal
     */
    tempAccount = () => this.setState({
        ...this.state,
        tempAccountModal: new bootstrap.Modal(document.getElementById('tempAccountModal'))
    }, () => this.state.tempAccountModal.show());

    render(){
        return (
            <motion.div className="min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <TempAccountModal googleReCaptchaProps={this.props.googleReCaptchaProps} history={this.props.history} tempAccountModal={this.state.tempAccountModal} />
                <div className="buf-4"></div>
                <h1 className="text-center text-raleway">Login</h1>
                <button onClick={this.tempAccount} className="btn btn-dark d-block mx-auto my-4"><i className="fas fa-user me-2"></i>Take Calls as Guest</button>
                <div className="mx-auto mt-2" style={{width: '400px', maxWidth: '95%'}}>
                    <label>Username</label>
                    <input onKeyPress={this.pressEnter} id="input-username" className="form-control mt-1"></input>
                    <label className="mt-3">Password</label>
                    <input onKeyPress={this.pressEnter} type="password" id="input-password" className="form-control mt-1"></input>
                    <div className="d-grid gap-2">
                        {this.state.working ?
                        <button disabled className="btn btn-primary mt-3" type="button"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Logging in</button> :
                        <button onClick={this.submit} className="btn btn-primary mt-3" type="button"><i className="fas fa-sign-in-alt me-2"></i>Login</button>}
                        <button onClick={() => this.props.history.push('/create-account')} className="btn btn-secondary mt-2" type="button"><i className="fas fa-user-plus me-2"></i>Create Account</button>
                    </div>
                    <Link to="/reset-password" style={{width: 'max-content'}} className="text-center d-block mx-auto mt-3">Forgot Password?</Link>
                </div>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_user, purge_user })(withGoogleReCaptcha(Login));