import React from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class PasswordResets extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the password reset request is being sent to or processed by the server
             * 
             */
            working: false
        }
    }

    /**
     * TODO: Replace with actual <form>
     * @param {Event Object} e - Javascript event object
     * 
     * Submits the form when the user presses enter
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
     * 
     * @returns A Google reCaptcha v3 challlenge token
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.googleReCaptchaProps && this.props.googleReCaptchaProps.executeRecaptcha) this.props.googleReCaptchaProps.executeRecaptcha().then(resolve).catch(err => {
            console.log('error', err);
            return reject();
        });
        else setTimeout(async () => {
            try {
                const captchaKey = await this.getRecaptcha();
                resolve(captchaKey);
            } catch(err){
                console.log(err);
                alert('Captcha error. Please try again later.');
                reject();
            }
        }, 1000);
    });

    /**
     * Fired when the user clicks the Login button
     * 
     * Validate Captcha
     * Send reset object to server
     * Route to the "Check Email" page
     */
    submit = async () => {
        try {
            const captchaKey = await this.getRecaptcha();
            if (!this.state.working) this.setState({
                ...this.state,
                working: true
            }, () => axios.post('/auth/reset-password', {
                username: document.getElementById('input-username').value,
                email: document.getElementById('input-email').value,
                captchaKey: captchaKey
            }).then(res => {
                if (res.data.success) this.props.history.push('/check-email');
                else this.setState({
                    ...this.state,
                    working: false
                }, () => alert(res.data.error));
            }).catch(err => this.setState({
                ...this.state,
                working: false
            }, () => {
                console.log(err);
                alert('An error occurred. Please try again later.')
            })));
        } catch(err){
            console.log(err);
            alert('An error occurred. Please refresh the page and try again.')
        }
    }

    render(){
        return (
            <motion.div className="min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="buf-4"></div>
                <div className="container">
                    <h1 className="text-center mt-5 text-raleway">Reset Password</h1>
                    <div className="mx-auto mt-2" style={{width: '400px', maxWidth: '95%'}}>
                        <label className="mt-3">Username</label>
                        <input onKeyPress={this.pressEnter} placeholder="Your username" id="input-username" className="form-control mt-1"></input>
                        <label className="mt-3">Email Address</label>
                        <input onKeyPress={this.pressEnter} placeholder="The email you provided" id="input-email" className="form-control mt-1"></input>
                        <div className="d-grid gap-2 my-3">
                            {this.state.working ?
                            <button disabled className="btn btn-primary" type="button"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Submitting</button> :
                            <button onClick={this.submit} className="btn btn-primary" type="button"><i className="fas fa-paper-plane me-2"></i>Submit</button>}
                            <button onClick={() => this.props.history.push('/login')} className="btn btn-dark mt-2" type="button"><i className="fas fa-chevron-left me-2"></i>Back</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default withGoogleReCaptcha(PasswordResets);