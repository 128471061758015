import React from 'react';

/**
 * Bootstrap toast indicating that the user has been disconnected by the streamer
 */

class DisconnectAlert extends React.Component{
    constructor(){
        super();
    }

    render(){
        return (
            <div style={{zIndex: 1500, left: '25px', minWidth: 'max-content'}} id={`toast_${this.props.streamer}`} className={`toast align-items-center position-fixed`} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-body container">
                        <div className="row flex-row flex-nowrap">
                            <div style={{width: 'max-content'}} className="col-10 d-flex">
                                <img src="/images/monkey-dis.png" className="img-toast d-block me-2" alt="le sad monkey"></img>
                                <h5 className="text-break">You were disconnected by {this.props.streamer}</h5>
                            </div>
                            <div className="col-2"> <button type="button" className="btn-close me-2 m-auto align-self-start" data-bs-dismiss="toast" aria-label="Close"></button></div>
                        </div>                       
                    </div>
                    
            </div>
        );
    }
}

export default DisconnectAlert;