import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { set_user, set_streamer_devices, rotate_camera, toggle_caller_tone } from '../../redux/actions';

class SettingsModal extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * submitting: Boolean indicating whether updated settings are in the process of being submitted to the server and processed
             * microphoneEnabled: Boolean indicating whether the user has enabled their microphone
             * cameraEnabled: Boolean indicating whether the user has enabled their camera
             * cameraHeight: Number - The height of the camera <video> element in pixels
             * cameraWidth: Number - The width of the camera <video> element in pixels
             * thumbnailName: String - Shown above the stream thumbnail
             * thumbnailFile: false | JavaScript File object containing newly selected thumbnail file
             * thumbnail: String - Path to stream thumbnail
             * refresh: Boolean - Does nothing
             */
            submitting: false,
            microphoneEnabled: props.streamerDevices.getAudioTracks && props.streamerDevices.getAudioTracks().length,
            cameraEnabled: false,
            cameraHeight: 450,
            cameraWidth: 450,
            thumbnailName: 'Click to Select',
            thumbnailFile: false,
            thumbnail: (props.activeStream) ? `/thumbnails/${props.activeStream.thumbnail}` : `/thumbnails/monkey-dark.png`,
            refresh: false
        }
        
    }

    // This fixes a ui bug on some devices where the camera feed does not play properly
    componentDidMount(){
        document.getElementById('streamDetailsModal').addEventListener('shown.bs.modal', () => this.setState({
            ...this.state,
            refresh: !this.state.refresh
        }));
    }

    /**
     * 
     * @param {Object} prevProps - Previous this.props object
     * 
     * If height of any video containers changes, set those changes into state and resize container accordingly (TODO: Add window resize event to do this)
     * If camera index changes, reset media stream
     * If there is a <video> with no stream, set its source to the media stream
     */
    componentDidUpdate(prevProps){
        if (document.getElementById('streamer-avatar') && document.getElementById('streamer-avatar').clientHeight && document.getElementById('streamer-avatar').clientHeight !== this.state.cameraHeight) this.setState({
            ...this.state,
            cameraHeight: document.getElementById('streamer-avatar').clientHeight,
            cameraWidth: document.getElementById('streamer-avatar').clientWidth,
            cameraEnabled: this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length
        });
        if (prevProps.cameraIndex !== this.props.cameraIndex) this.getMediaDevices();
        const webCam = document.getElementById('streamer-video');
        if (webCam){
            if (webCam.srcObject){

            } else {
                webCam.srcObject = this.props.streamerDevices;
                webCam.play();
            }
        }
    }

    /**
     * Fired when the user selects the microphone icon
     * Toggles the microphone, then resets the media devices
     * 
     */
    toggleMicrophone = () => this.setState({
        ...this.state,
        microphoneEnabled: !this.state.microphoneEnabled
    }, () => this.getMediaDevices());

    /**
     * Fired when the user selects the camera icon
     * Toggles the camera, then resets the media devices
     */
    toggleCamera = () => this.setState({
        ...this.state,
        cameraEnabled: !this.state.cameraEnabled
    }, () => this.getMediaDevices());

    /**
     * Grabs a combination of the user's camera/microphone depending on what the user's specifications
     * Sets the resulting stream into application state
     * 
     */
    getMediaDevices = () => {
        if (navigator.mediaDevices){
            navigator.mediaDevices.getUserMedia({
                audio: this.state.microphoneEnabled,
                video: this.state.cameraEnabled ? (this.props.videoDevices.length > 1 ? {
                    deviceId: this.props.videoDevices[this.props.cameraIndex]
                } : true) : false
            }).then(async mediaStream => {
                let devices = await navigator.mediaDevices.enumerateDevices();
                this.props.set_streamer_devices(mediaStream, devices.filter(d => d.kind === 'videoinput').map(v => v.deviceId));
            }).catch(err => {
                console.log(err);
                this.props.set_streamer_devices([]);
                alert('Please give permission to use your input devices');
            });
        } else {
            this.props.history.push('/');
            alert("Unable to access camera and microphone. Please try again.");
        }
        
    }

    /**
     * Fired when the user clicks the thumbnail to select a new one
     * Creates a virtual file input
     * Adds a click event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     */
    selectThumbnail = () => {
        document.activeElement.blur();
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        input.onchange = e => {
            let file = e.target.files[0];
            if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp'].indexOf(file.type) !== -1){
                if (file.size < 15000001){
                    console.log(file)
                    this.setState({
                        ...this.state,
                        thumbnailName: e.target.files[0].name,
                        thumbnailFile: e.target.files[0],
                        thumbnail: URL.createObjectURL(e.target.files[0])
                    });
                } else {
                    alert('Your file is too big (Max: 15MB)');
                }
            } else {
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
            input.remove();
        }
        document.body.appendChild(input);
        input.click();
    }

    /**
     * Fired when the user clicks the Submit button
     * Validates the inputs, then sends to the server
     * If successful, apply changes into application and hide the modal
     */
    submit = () => {
        if (!this.state.submitting){
            this.setState({
                ...this.state,
                submitting: true
            }, () => {
                try {
                    const title = document.querySelector('#input-stream-title').value;
                    const url = document.querySelector('#input-jewtube').value;
                    if (!title) throw 'Please enter a title';
                    if (title.length > 100) throw 'Please enter a shorter title (Max: 100 chars)';
                    const fd = new FormData();
                    if (this.state.thumbnailFile) fd.append('thumbnail', this.state.thumbnailFile, this.state.thumbnailName);
                    fd.append('title', title);
                    fd.append('url', url);
                    fd.append('private', !document.getElementById('privateCheck').checked);
                    axios.post('/stream/set-stream', fd).then(res => {
                        if (res.data.success){
                            this.setState({
                                ...this.state,
                                submitting: false
                            }, () => {
                                this.props.set_user(res.data.userInfo);
                                this.props.streamDetailsModal.hide();
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                submitting: false
                            }, () => alert(res.data.error));
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({
                            ...this.state,
                            submitting: false
                        }, () => alert('An error occurred. Please try again later.'));
                    });
                } catch(err){
                    console.log(err);
                    this.setState({
                        ...this.state,
                        submitting: false
                    }, () => alert(err));
                }
            });
        }
    }

    render(){
        return (
            <div className="modal fade" id="streamDetailsModal" tabindex="-1" aria-labelledby="streamDetails" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Settings</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body container">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="d-flex my-4">
                                    <div className="form-check">
                                        <input defaultChecked={!this.props.activeStream.private} className="form-check-input" type="checkbox" value="" id="privateCheck"></input>
                                        <label className="form-check-label" for="privateCheck">
                                            Public Call
                                        </label>
                                    </div>
                                </div>
                                <div className="inputs-normal mb-4">
                                    <h5>Title</h5>
                                    <div className="input-group input-group-lg mb-2">
                                        <input defaultValue={this.props.activeStream.title} id="input-stream-title" placeholder="Enter call title" type="text" className="form-control" aria-label="Stream title" aria-describedby="inputGroup-sizing-lg"></input>
                                    </div>
                                </div>
                                <h5>Thumbnail (Optional)</h5>
                                <p className="mb-1" onClick={this.selectThumbnail} style={{cursor: 'pointer'}}>{this.state.thumbnailName}</p>
                                <div style={{cursor: 'pointer'}} onClick={this.selectThumbnail} className="border border-dark p-3 d-flex justify-content-center align-items-center">
                                    <img className="d-block" style={{maxWidth: '50%', maxHeight: '50%'}} alt="blank avatar" src={this.state.thumbnail}></img>
                                </div>
                                <div className="inputs-normal mt-4">
                                    <h5>Stream URL (Youtube, Twitch)</h5>
                                    <div className="input-group input-group-lg mb-3">
                                        <input defaultValue={this.props.activeStream.url} id="input-jewtube" placeholder="Enter stream link, must be a YouTube link" type="text" className="form-control" aria-label="Jewtube-link" aria-describedby="inputGroup-sizing-lg"></input>
                                    </div>
                                </div>
                                {this.state.submitting ?
                                <button disabled className="btn btn-primary"><span className="spinner-border spinner-border-sm me-2"></span>Please wait</button>:
                                <button onClick={this.submit} className="btn btn-primary"><i className="fas fa-paper-plane me-2"></i>Update Stream Details</button>
                                }
                            </div>
                            <div className="col-12 col-md-8">
                                <div id="card-settings-modal" className="card">
                                    <div id="header-settings-modal" className="card-header d-flex justify-content-between">
                                        <h5>Mic {'&'} Video Settings</h5>
                                        <div className="show-lg">
                                            {this.props.streamerDevices.getAudioTracks && this.props.streamerDevices.getAudioTracks().length ? 
                                            <p className="text-success my-1 text-end">Microphone Good</p> :
                                            <p className="text-danger my-1 text-end">Microphone Not In Use</p>}
                                            {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                            <p className="text-success my-1 text-end">Camera Good</p> :
                                            <p className="text-danger my-1 text-end">Camera Not In Use</p>}
                                        </div>
                                        <div className="show-sm">
                                            {this.props.streamerDevices.getAudioTracks && this.props.streamerDevices.getAudioTracks().length ? 
                                            <p className="text-success my-1">Microphone Good</p> :
                                            <p className="text-danger my-1">Microphone Not In Use</p>}
                                            {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length ? 
                                            <p className="text-success my-1">Camera Good</p> :
                                            <p className="text-danger my-1">Camera Not In Use</p>}
                                        </div>
                                    </div>
                                    <div className="card-body container">
                                        <div className="row">
                                            <div className="show-sm">
                                                <div className="d-flex">
                                                    {this.state.microphoneEnabled ? 
                                                    <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-4"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-4"></i>}
                                                    {this.state.cameraEnabled ? 
                                                    <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-4 ms-3"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-4 ms-3"></i>}
                                                    {this.props.videoDevices && this.props.videoDevices.length > 1 && this.state.cameraEnabled ?
                                                    <div className="mx-3" onClick={this.props.rotate_camera} id="camera-rotate"></div> : 
                                                    <div className="mx-3" style={{opacity: 0, cursor: 'default'}} id="camera-rotate"></div>}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check">
                                                        <input onChange={this.props.toggle_caller_tone} className="form-check-input" type="checkbox" value="" id="toggleCallerTone" defaultChecked={this.props.newCallerTone}></input>
                                                        <label className="form-check-label" for="toggleCallerTone">
                                                            Call Tone
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{height: '50vh'}} className="col-12 col-md-10">
                                                {this.props.streamerDevices.getVideoTracks && this.props.streamerDevices.getVideoTracks().length && this.state.cameraEnabled ? 
                                                <div dangerouslySetInnerHTML={{
                                                    __html: `
                                                        <video class="d-block mx-auto" style="margin: 0; padding: 0; max-height:${this.state.cameraHeight}px; max-width:${this.state.cameraWidth}px;" id="streamer-video" autoplay muted playsinline></video>
                                                    `
                                                }}></div> :
                                                <img className="d-block mx-auto" id="streamer-avatar" style={{maxWidth: '100%', maxHeight: '100%'}} src={`/avatars/${this.props.avatar}`} alt="avatar"></img>}
                                                
                                            </div>
                                            <div className="show-lg">
                                                <div className="d-flex justify-content-end">
                                                    {this.state.microphoneEnabled ? 
                                                    <i style={{cursor: 'pointer'}} onClick={this.toggleMicrophone} className="bi bi-mic-fill d-block fs-4"></i> : <i onClick={this.toggleMicrophone} style={{cursor: 'pointer'}} className="bi bi-mic-mute-fill d-block fs-4"></i>}
                                                    {this.state.cameraEnabled ? 
                                                    <i style={{cursor: 'pointer'}} onClick={this.toggleCamera} className="bi bi-camera-video-fill d-block fs-4 ms-3"></i> : <i onClick={this.toggleCamera} style={{cursor: 'pointer'}} className="bi bi-camera-video-off-fill d-block fs-4 ms-3"></i>}
                                                    {this.props.videoDevices && this.props.videoDevices.length > 1 && this.state.cameraEnabled ?
                                                    <div className="mx-2" onClick={this.props.rotate_camera} id="camera-rotate"></div> : 
                                                    <div className="mx-2" style={{opacity: 0, cursor: 'default'}} id="camera-rotate"></div>}
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check">
                                                            <input onChange={this.props.toggle_caller_tone} className="form-check-input" type="checkbox" value="" id="newCallerTone" defaultChecked={this.props.newCallerTone}></input>
                                                            <label className="form-check-label" for="newCallerTone">
                                                                Call Tone
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_user, set_streamer_devices, rotate_camera, toggle_caller_tone})(SettingsModal);