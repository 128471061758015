import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { set_stream } from '../redux/actions';
import axios from 'axios';

class Browse extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * loaded: Boolean indicating whether the list of active streams has been loaded
             * activeStreams: Array of active streams
             */
            loaded: false,
            activeStreams: []
        }
    }

    // Load once the application mounts
    componentDidMount(){
        this.load();
    }

    /**
     * Load the streams
     * If it fails, try again after 1.5 seconds
     * 
     */
    load = () => axios.get('/browse/active-streams').then(res => {
        this.setState({
            ...this.state,
            loaded: true,
            activeStreams: res.data.activeStreamers ? res.data.activeStreamers : []
        });
    }).catch(err => {
        console.log('load error', err);
        setTimeout(this.load, 1500);
    });

    /**
     * 
     * @param {String} user - Username of streamer selected
     * Load the streamer's data, set into application state
     * Navigate to streamer's page
     */
    selectStream = user => {
        this.setState({
            ...this.state,
            loaded: false
        }, () => axios.get(`/browse/check-active/${user}`).then(res => {
            if (res.data.active){
                this.props.set_stream(user);
                this.props.history.push(`/${user}`);
            } else this.setState({
                ...this.state,
                loaded: true,
                activeStreams: res.data.activeStreamers ? res.data.activeStreamers : []
            }, () => alert('That streamer is no longer live'));
        }).catch(err => {
            console.log('Load streamer error', err);
            setTimeout(() => this.selectStream(user), 1000);
        }));
    }

    /**
     * 
     * @returns a list of streams
     * 
     * This is bad and will be replaced with a regular list of streams when I get time
     */
    renderActiveStreams = () => {
        let rows = [];
        const fullRows = Math.floor(this.state.activeStreams.length / 4);
        const remainder = this.state.activeStreams.length % 4;
        for (let i = 0; i < fullRows; i++){
            let start = 4 * i;
            let thisRow = [];
            for (let j = start; j < start + 4; j++){
                let stream = this.state.activeStreams[j];
                thisRow.push(
                    <div title={stream.user} className="col-md-3 col-6 browse-rows">
                        <div onClick={() => this.selectStream(stream.user)} key={stream.user} className="card h-100 px-2 browse-cards">
                            <div className="fg-1 mt-2" style={{backgroundImage: `url('/avatars/${stream.thumbnail === "monkey-dark.png" ? stream.avatar : stream.thumbnail}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain'}}>
                            </div>
                            <hr></hr>
                            <h5 className="text-center">{stream.title}</h5>
                            <p className="text-center">{stream.displayName}</p>
                        </div>
                    </div>
                );
            }
            rows.push(
                <div className={`${i > 0 ? 'mt-2' : ''} row `}>{thisRow}</div>
            );
        }
        if (remainder === 1){
            let stream = this.state.activeStreams[4 * fullRows];
            rows.push(
                <div className={`row mb-4 justify-content-center ${fullRows > 0 ? 'mt-2' : ''}`}>
                    <div style={{height: '20rem'}} className="col-md-3 col-6 browse-rows">
                        <div onClick={() => this.selectStream(stream.user)} key={stream.user} className="card h-100 p-2 browse-cards">
                            <div className="fg-1 mt-2" style={{backgroundImage: `url('/avatars/${stream.thumbnail === "monkey-dark.png" ? stream.avatar : stream.thumbnail}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain'}}>
                            </div>
                            <hr></hr>
                            <h5 className="text-center">{stream.title}</h5>
                            <p className="text-center">{stream.displayName}</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            let thisRow = [];
            for (let i = 4 * fullRows; i < this.state.activeStreams.length; i++){
                let stream = this.state.activeStreams[i];
                thisRow.push(
                    <div title={stream.user} className="col-md-3 col-6 browse-rows">
                        <div onClick={() => this.selectStream(stream.user)} key={stream.user} className="card h-100 px-2 browse-cards">
                            <div className="fg-1 mt-2" style={{backgroundImage: `url('/avatars/${stream.thumbnail === "monkey-dark.png" ? stream.avatar : stream.thumbnail}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain'}}>
                            </div>
                            <hr></hr>
                            <h5 className="text-center">{stream.title}</h5>
                            <p className="text-center">{stream.displayName}</p>
                        </div>
                    </div>
                );
            }
            rows.push(
                <div className={`${fullRows > 0 ? 'mt-2' : ''} row `}>{thisRow}</div>
            );
        }
        return rows;   
    } 
    

    /**
     * Fired when the user clicks the Refresh button
     * Reloads new data
     */
    refresh = () => this.setState({
        ...this.state,
        loaded: false
    }, () => this.load());

    render(){
        return (
            <motion.div className="min-h-100" exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-3"></div>
                        <div className="col-6"><h1 className="text-center display-5 text-raleway">Public Calls</h1></div>
                        <div className="col-3 d-flex justify-content-end align-items-start ">
                            {this.state.loaded ?
                            <button onClick={this.refresh} className="btn btn-primary show-lg"><i className="fas fa-redo me-2"></i>Refresh</button> :
                            <button disabled className="btn btn-primary show-lg"><span className="spinner-border spinner-border-sm me-2"></span>Loading</button>}
                            {this.state.loaded ?
                            <button onClick={this.refresh} className="btn btn-primary show-sm"><i className="fas fa-redo"></i></button> :
                            <button disabled className="btn btn-primary show-sm"><span className="spinner-border spinner-border-sm"></span></button>}
                        </div>
                    </div>
                    <hr></hr>
                </div>
                <div className="container-fluid">
                    {this.state.loaded ?
                    <>
                        {this.state.activeStreams.length ? 
                        <>
                            {this.renderActiveStreams()}
                        </> :
                        <h5 className="text-center mt-4">No public calls found. Please come back later or refresh the page</h5>}
                    </>:
                    <div class="loadingio-spinner-ellipsis-t29bpo9v9p d-block mx-auto mt-5"><div class="ldio-xtxv5ahe67">
                    <div></div><div></div><div></div><div></div><div></div>
                    </div></div>}
                </div>
            </motion.div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_stream})(Browse);