import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reducer from './redux/reducer';
import { legacy_createStore as createStore } from 'redux';

// Registers service worker. Necessary for the app to be installable


const store = createStore(reducer);

if (typeof window !== 'undefined') ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </Provider>
)