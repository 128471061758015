import React from 'react';
import { set_user } from '../../redux/actions';
import axios from 'axios';
import { connect } from 'react-redux';

class Streams extends React.Component{
    constructor(){
        super();
        this.state = {
            
        }
    }

    
    // WIP
    render(){
        return (
            <div className="container h-100 d-flex flex-column">
                <h1 className="text-center display-4">Streaming Options</h1>
                

                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {set_user})(Streams);